import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { Hidden } from "@/components/Mappers/ExportMapper/ExportMapperList";
import { t } from "@/translations";
import { pipe, prop } from "lodash/fp";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

const from = ({ releaseDate, ...values }) => ({
    ...values,
    releaseDate: moment(releaseDate).format("YYYY-MM-DDTHH:mm:ss"),
});
const to = ({ releaseDate, ...values } = {}) => ({
    releaseDate: releaseDate ? moment(releaseDate) : releaseDate,
    ...values,
});
const VERSION_VALIDATION_PATTERN =
    /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/gm;
const releaseNumberValidator = Forms.validators.failOnFirst([
    Forms.pmValidators.isRequired,
    Forms.pmValidators.createPatternValidator(
        VERSION_VALIDATION_PATTERN,
        'Version has a wrong format. Please, refer to "https://semver.org/" for more info',
    ),
]);

const getPlaceholder = prop =>
    t("general.field.placeholder", {
        fieldName: t(`release-version.${prop}`).toLowerCase(),
    });

const ReleaseVersionsModalInner = ({
    onOk,
    onCancel,
    initialValues,
    title,
    confirmText,
}) => {
    const { formId, handleSubmit } = Forms.useForm({
        initialValues: to(initialValues),
        onSubmit: pipe(prop("values"), from, onOk),
    });

    return (
        <UnityLayout>
            <UnityLayout.Header
                size={3}
                title={title || t("release-version.create.modal.title")}
            />
            <UnityLayout.Content padding={[false, true]}>
                <Forms.Form formId={formId} onSubmit={handleSubmit}>
                    <Hidden name="id" />
                    <Forms.Fields.DatePicker
                        required
                        name="releaseDate"
                        label={t("release-version.releaseDate.label")}
                        validator={Forms.pmValidators.isRequired}
                        format="YYYY-MM-DD"
                    />
                    <Forms.Fields.Input
                        required
                        name="releaseNumber"
                        label={t("release-version.releaseNumber.label")}
                        validator={releaseNumberValidator}
                        placeholder={getPlaceholder("releaseNumber")}
                    />
                    <Forms.Fields.Input
                        required
                        name="serverBuildNumber"
                        label={t("release-version.serverBuildNumber.label")}
                        validator={Forms.pmValidators.isRequired}
                        placeholder={getPlaceholder("serverBuildNumber")}
                    />
                    <Forms.Fields.Input
                        required
                        name="webBuildNumber"
                        label={t("release-version.webBuildNumber.label")}
                        validator={Forms.pmValidators.isRequired}
                        placeholder={getPlaceholder("webBuildNumber")}
                    />
                    <Forms.Fields.Input
                        required
                        name="notes"
                        label={t("release-version.notes.label")}
                        validator={Forms.pmValidators.isRequired}
                        placeholder={getPlaceholder("notes")}
                    />
                    <Forms.Fields.Checkbox
                        required
                        name="requiresMajorUpgrade"
                        label={t("release-version.requiresMajorUpgrade.label")}
                        initialValue={false}
                    />
                </Forms.Form>
            </UnityLayout.Content>
            <UnityLayout.Footer
                actionButtons={
                    <ButtonMenu
                        buttons={[
                            {
                                label: confirmText || t("general.save"),
                                type: "primary",
                                htmlType: "submit",
                                formId,
                            },
                            {
                                label: t("general.cancel"),
                                onClick: onCancel,
                                type: "text",
                            },
                        ]}
                    />
                }
            />
        </UnityLayout>
    );
};

export const ReleaseVersionsModalComponent = props => (
    <Modal visible={props.visible} onClose={props.onCancel}>
        {props.visible && <ReleaseVersionsModalInner {...props} />}
    </Modal>
);
ReleaseVersionsModalComponent.propTypes = {
    visible: PropTypes.bool.isRequired,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    title: PropTypes.string,
    confirmText: PropTypes.string,
};
ReleaseVersionsModalInner.propTypes = ReleaseVersionsModalComponent.propTypes;
