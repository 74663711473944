export const preferencesTypes = {
    ACCOUNT_DEPLOYED_PACKAGES_TABLE: "accountDeployedPackagesTable",
    ACCOUNTS_LIST_TABLE: "AccountsListTable",
    ADMIN_ASSET_ROLES_LIST_TABLE: "AdminAssetRolesListTable",
    ADMIN_BUSINESS_ROLES_LIST_TABLE: "AdminBusinessRolesListTable",
    ADMIN_GROUPS_LIST_TABLE: "AdminGroupsListTable",
    ADMIN_LOGGERS_LIST_TABLE: "AdminLoggersListTable",
    ADMIN_USERS_LIST_TABLE: "AdminUsersListTable",
    ALERT_RULES_LIST_TABLE: "AlertRulesListTable",
    ALERTS_LIST_TABLE_GLOBAL: "AlertsListTableGlobal",
    ALERTS_LIST_TABLE: "AlertsListTable",
    ASSET_ROLES_LIST_TABLE: "AssetRolesListTable",
    ATLASSIAN_GROUPS_TABLE: "AtlassianGroupsTable",
    BUSINESS_ROLES_LIST_TABLE: "BusinessRolesListTable",
    CALCULATION_ENGINES_LIST_TABLE: "calculation_engines_list_table",
    CERTIFICATES_LIST_TABLE: "CertificatesListTable",
    CLASSES_LIST_TABLE: "ClassesListTable",
    CLUSTER_ADMIN_TABLE: "ClusterAdminTable",
    CLUSTER_LIST_TABLE: "ClusterListTable",
    CLUSTER_UPGRADE_OVERVIEW_ACCOUNT: "ClusterUpgradeOverviewAccount",
    CONNECTIONS_LIST_TABLE: "ConnectionsListTable",
    CONTACTS_MANAGEMENT: "ContactsManagement",
    CUSTOMER_PARTITIONS_LIST_TABLE: "CustomerPartitionsListTable",
    DATA_DOWNLOAD_FILES_LIST_TABLE: "DataDownloadFilesListTable",
    DATA_DOWNLOADS_LIST_TABLE: "DataDownloadsListTable",
    DATA_UPLOADS_LIST_TABLE: "DataUploadsListTable",
    DEPLOYED_PACKAGES_LIST_TABLE: "DeployedPackagesListTable",
    ENG_SUPPORT_USERS_TABLE: "EngSupportUsersTable",
    EVENT_LISTENER_WF_LIST_TABLE: "EventListenerWfListTable",
    EVENT_SCHEDULER_LIST_TABLE: "EventSchedulerListTable",
    MASS_LISTENER_LIST_TABLE: "MassListenerListTable",
    FAILED_DEPLOYMENTS_LIST_TABLE: "FailedDeploymentsListTable",
    FILTER_LIST_TABLE: "FilterListTable",
    IM_INSTANCES_LIMIT_TABLE: "IMInstancesLimitTable",
    INSTANCE_DEPLOYED_PACKAGES_TABLE: "instanceDeployedPackagesTable",
    INSTANCE_FAILED_EVENTS_LIST_TABLE: "instanceFailedEventsListTable",
    INSTANCE_MERGE_REQUESTS_TABLE: "instanceMergeRequestsTable",
    INSTANCE_REPO_CERTIFICATES_LIST_TABLE: "InstanceRepoCertificatesListTable",
    INSTANCE_REPO_CLASSES_TABLE: "InstanceRepoClassesTable",
    INSTANCE_REPO_CONNECTIONS_TABLE: "InstanceRepoConnectionsTable",
    INSTANCE_REPO_FILTERS_TABLE: "InstanceRepoFiltersTable",
    INSTANCE_REPO_INTEGRATIONS_ROUTES: "InstanceRepoIntegrationsRoutes",
    INSTANCE_REPO_MAPPERS_LIST_TABLE: "InstanceRepoMappersList",
    INSTANCES_LIST_TABLE: "InstancesListTable",
    INTEGRATION_APPLICATION_PROPERTIES: "IntegrationApplicationProperties",
    INTEGRATION_ARCHIVES_LIST_TABLE: "IntegrationArchivesListTable",
    INTEGRATION_MANAGER_VERSIONS_LIST_TABLE:
        "IntegrationManagerVersionsListTable",
    INTEGRATION_OVERVIEW_INTEGRATIONS: "IntegrationOverviewIntegrations",
    INTEGRATIONS_LIST_TABLE: "IntegrationsListTable",
    INTEGRATIONS_RESOURCES_LIST_TABLE: "IntegrationsResourcesListTable",
    ISV_CONNECTIONS_LIST_TABLE: "IsvConnectionsListTable",
    ISV_MAPPERS_LIST_TABLE: "IsvMappersListTable",
    MAPPERS_LIST_TABLE: "MappersListTable",
    NODES_LIST_TABLE: "NodesListTable",
    NOTIFICATION_RULES_LIST_TABLE: "NotificationRulesListTable",
    NOTIFICATIONS_LIST_TABLE: "NotificationListTable",
    OFFERS_LIST_TABLE: "OffersListTable",
    PARTITION_AVAILABLE_FILES_LIST_TABLE:
        "PARTITION_AVAILABLE_FILES_LIST_TABLE",
    PARTITION_CONNECTIONS_LIST_TABLE: "PartitionConnectionsListTable",
    PARTITION_DEPLOYED_PACKAGES_TABLE: "partitionDeployedPackagesTable",
    PARTITION_EVENT_ADMIN_LIST_TABLE: "PartitionEventAdminListTable",
    PARTITION_JOB_TRACKING_LIST_TABLE: "PartitionJobTrackingListTable",
    PARTITIONS_LIMIT_TABLE: "PartitionsLimitTable",
    PARTITIONS_LIST_TABLE: "PartitionsListTable",
    PRODUCT_ENHANCEMENT_MY_REQUESTS_TABLE: "ProductEnhancementMyRequestsTable",
    PRODUCT_ENHANCEMENT_OVERVIEW_TABLE: "ProductEnhancementOverviewTable",
    PROJECT_INTEGRATIONS_LIST_TABLE: "ProjectIntegrationsListTable",
    REPORTS_ELK_LIST_TABLE: "ReportsElkListTable",
    REPORTS_LIST_TABLE: "ReportsListTable",
    RUNNING_EVENT_LISTENER_WF_LIST_TABLE: "RunningEventListenerWfListTable",
    SERVERS_LIST_TABLE: "ServersListTable",
    SFTP_LIST_TABLE: "SFTPListTable",
    SFTP_SERVER_USERS_TABLE: "SFTPServerUsersTable",
    TASKS_LIST: "TasksList",
    TEMPLATES_MANAGEMENT_LIST_TABLE: "TemplatesManagementListTable",
    TEMPLATES_PACKAGE_STEPS_MANAGEMENT: "TemplatePackageStepsManagement",
    UPDATES_LIST_TABLE: "UpdatesListTable",
    UPGRADE_SLOTS_TABLE: "UpgradeSlotsTable",
    UPGRADES_TABLE: "UpgradesTable",
    USER_GROUPS_LIST_TABLE: "UserGroupsListTable",
    USERS_LIST_TABLE: "UsersListTable",
    WORK_FLOW_APPROVALS_TABLE: "WorkflowApprovalsTable",
    WORKFLOW_LIST_TABLE: "WorkflowListTable",
};
