import { DeployedPackagesPage } from "@/components/DeployedPackages/DeployedPackages.page";
import { MarketplacePage } from "@/components/Marketplace/Marketplace.page";
import { MarketplaceDetailPage } from "@/components/Marketplace/MarketplaceDetail.page";
import { PackagePage } from "@/components/Marketplace/pages/Package.page";
import { createLocation } from "@/modules/router";
import { UnauthorizedContent } from "@/security/authorization";
import {
    hasAnyPermissionsFunc,
    TEMPLATE_MANAGEMENT_PERMISSION_FUNC,
} from "@/security/permission.utils";
import { deployPackageStateEnum } from "../../apps/marketplaceApp/components/DeployPackage/deployPackageState.enum";
import { PACKAGE_DEFINITION_QUERY } from "../../apps/marketplaceApp/components/PackageDefinition/PackageDefinition.component";
import { FailedDeploymentsPage } from "../../apps/marketplaceApp/pages/FailedDeploymentsPage/FailedDeploymentsPage.component";
import { TemplatesManagementUploadPageContainer } from "../../apps/marketplaceApp/pages/TemplatesManagementPage/deploy/TemplatesManagementUploadPage.container";
import { TemplatesManagementDetailPageContainer } from "../../apps/marketplaceApp/pages/TemplatesManagementPage/detail/TemplatesManagementDetailPage.container";
import { TemplateEditPage } from "../../apps/marketplaceApp/pages/TemplatesManagementPage/TemplateEdit.page";
import { TemplatesManagementPageContainer } from "../../apps/marketplaceApp/pages/TemplatesManagementPage/TemplatesManagementPage.container";
import { marketplaceAppLocation } from "../../locations";
import { OfferCreatePage } from "../../views/Offers/OfferCreate.page";
import { OfferEditPage } from "../../views/Offers/OfferEdit.page";
import { OffersTablePage } from "../../views/Offers/OffersTable.page";

const TEMPLATE_PERMISSIONS = [
    "marketplace.templates_management.publish",
    "marketplace.templates_management.deploy",
    "deployment.templates_log.read",
];

export const OFFERS_PERMISSIONS = ["offers.edit"];

const TEMPLATE_DEPLOY_PUBLISH_PERMISSIONS = [
    "marketplace.templates_management.deploy",
    "marketplace.templates_management.publish",
    "marketplace.templates_management.deploy_non_approved",
    "marketplace.templates_management.upload_snapshot",
];

const marketplaceLocation = createLocation(
    marketplaceAppLocation,
    "marketplace",
    "/marketplace?tag&filter&page",
    {
        component: MarketplacePage,
        permissionFunc: hasAnyPermissionsFunc,
        unauthorizedContent: UnauthorizedContent,
    },
);

const marketplaceDetailLocation = createLocation(
    marketplaceLocation,
    "integrationTemplateDetail",
    "/:packageName",
    {
        component: MarketplaceDetailPage,
        permission: hasAnyPermissionsFunc,
        unauthorizedContent: UnauthorizedContent,
    },
);

const templatesManagementLocation = createLocation(
    marketplaceAppLocation,
    "templatesManagement",
    "/templates-management",
    {
        component: TemplatesManagementPageContainer,
        permissionFunc: TEMPLATE_MANAGEMENT_PERMISSION_FUNC,
        defaultParams: {
            accountId: -1,
        },
    },
);

const templateManagementUploadLocation = createLocation(
    templatesManagementLocation,
    "templateManagementUpload",
    "/upload",
    {
        component: TemplatesManagementUploadPageContainer,
        permission: TEMPLATE_DEPLOY_PUBLISH_PERMISSIONS,
        unauthorizedContent: UnauthorizedContent,
    },
);

const templateManagementEditLocation = createLocation(
    templatesManagementLocation,
    "templateManagementEditLocation",
    "/:uniqueName/edit",
    {
        component: TemplateEditPage,
        permissionFunc: TEMPLATE_MANAGEMENT_PERMISSION_FUNC,
        unauthorizedContent: UnauthorizedContent,
    },
);

const templateManagementDetailLocation = createLocation(
    templatesManagementLocation,
    "templateManagementDetail",
    "/:uniqueName/detail",
    {
        component: TemplatesManagementDetailPageContainer,
    },
);

const deployedTemplatesLocation = createLocation(
    templatesManagementLocation,
    "deployedTemplates",
    "/deployed",
    {
        component: DeployedPackagesPage,
        permission: TEMPLATE_PERMISSIONS,
        unauthorizedContent: UnauthorizedContent,
    },
);

const failedDeploymentsLocation = createLocation(
    templatesManagementLocation,
    "failedDeployments",
    "/failed-deployments",
    {
        component: FailedDeploymentsPage,
        permission: TEMPLATE_PERMISSIONS,
        unauthorizedContent: UnauthorizedContent,
    },
);

const offersManagementLocation = createLocation(
    marketplaceAppLocation,
    "offers",
    "/offers",
    {
        component: OffersTablePage,
        permission: OFFERS_PERMISSIONS,
        unauthorizedContent: UnauthorizedContent,
        defaultParams: {
            accountId: -1,
        },
    },
);

const offersEditLocation = createLocation(
    offersManagementLocation,
    "offerEdit",
    "/:offerId/edit",
    {
        component: OfferEditPage,
        permission: OFFERS_PERMISSIONS,
        unauthorizedContent: UnauthorizedContent,
    },
);

const offersCreateLocation = createLocation(
    offersManagementLocation,
    "offerCreate",
    "/create",
    {
        component: OfferCreatePage,
        permission: OFFERS_PERMISSIONS,
        unauthorizedContent: UnauthorizedContent,
    },
);
const marketplacePartitionLocation = createLocation(
    marketplaceLocation,
    "marketplacePartitionLocation",
    "/partition/:partitionId",
    {
        defaultParams: {
            partitionId: -1,
        },
        forwardTo: "marketplaceApp.marketplace", // should not be accessed directly, location to present instanceId in breadcrumb
    },
);

const marketplaceInstanceLocation = createLocation(
    marketplaceLocation,
    "marketplaceInstanceLocation",
    "/instance/:instanceId",
    {
        defaultParams: {
            instanceId: -1,
        },
        forwardTo: "marketplaceApp.marketplace", // should not be accessed directly, location to present instanceId in breadcrumb
    },
);

const packagePartitionLocation = createLocation(
    marketplacePartitionLocation,
    "packagePartition",
    `/package/:packageName/${PACKAGE_DEFINITION_QUERY}`,
    {
        component: PackagePage,
        permissionFunc: hasAnyPermissionsFunc,
        unauthorizedContent: UnauthorizedContent,
        defaultParams: {
            state: deployPackageStateEnum.init,
            step: "",
        },
    },
);

const packageInstanceLocation = createLocation(
    marketplaceInstanceLocation,
    "packageInstance",
    `/package/:packageName/${PACKAGE_DEFINITION_QUERY}`,
    {
        component: PackagePage,
        permissionFunc: hasAnyPermissionsFunc,
        unauthorizedContent: UnauthorizedContent,
        defaultParams: {
            state: deployPackageStateEnum.init,
            step: "",
        },
    },
);

export const marketplaceAppLocationsMap = {
    offersManagementLocation,
    offersEditLocation,
    offersCreateLocation,
    marketplaceLocation,
    marketplaceDetailLocation,
    templatesManagementLocation,
    templateManagementUploadLocation,
    templateManagementEditLocation,
    templateManagementDetailLocation,
    deployedTemplatesLocation,
    failedDeploymentsLocation,
    marketplacePartitionLocation,
    marketplaceInstanceLocation,
    packagePartitionLocation,
    packageInstanceLocation,
};
