import {
    useMutationLoadableWithNotification,
    useQueryLoadable,
    waitForValue,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { map, noop, tap } from "lodash/fp";
import { useDic } from "../Dic/useDic.hook";
import { useCurrentHandler } from "../hooks/useCurrentHandler.hook";

export const useIaaSDefaultsQuery = () => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/admin/iaas-management/default-values`)
                .then(getData),
        [],
    );
};

export const useIaaSOverridesQuery = () => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/admin/iaas-management/overrides`)
                .then(getData),
        [],
    );
};

export const useIaaSOverrideQuery = ({ id }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/admin/iaas-management/overrides/${id}`)
                .then(getData),
        [id],
    );
};

export const useToggleOverrideMutation = ({ afterSave = noop } = {}) => {
    const { axiosService } = useDic();
    const afterSaveCurrent = useCurrentHandler(afterSave);

    return useMutationLoadableWithNotification(
        async ({ id, enabled }) =>
            axiosService
                .patch(`/api/admin/iaas-management/overrides/${id}`, {
                    enabled,
                })
                .then(getData)
                .then(tap(afterSaveCurrent)),
        [],
    );
};

export const useUpsertOverrideMutation = ({ afterSave = noop } = {}) => {
    const { axiosService } = useDic();
    const afterSaveCurrent = useCurrentHandler(afterSave);

    return useMutationLoadableWithNotification(
        async data =>
            axiosService
                .post(`/api/admin/iaas-management/overrides`, data)
                .then(getData)
                .then(tap(afterSaveCurrent)),
        [],
    );
};

export const useDeleteOverrideMutation = ({ afterSave = noop } = {}) => {
    const { axiosService } = useDic();
    const afterSaveCurrent = useCurrentHandler(afterSave);

    return useMutationLoadableWithNotification(
        async ({ id }) =>
            axiosService
                .delete(`/api/admin/iaas-management/overrides/${id}`)
                .then(getData)
                .then(tap(afterSaveCurrent)),
        [],
    );
};

export const useIaaSIMVersionsQuery = () => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/admin/iaas-management/im-versions`)
                .then(getData)
                .then(
                    map(version => ({
                        value: version,
                        label: version,
                    })),
                )
                .catch(() => [
                    { label: "13.2.0", value: "13.2.0" },
                    { label: "13.1.0", value: "13.1.0" },
                ]),
        [],
    );
};

export const useIaaSEntitiesQuery = ({
    targetType,
    parentEntityId,
    canFetch,
}) => {
    const { axiosService } = useDic();
    const queryStr = parentEntityId ? `?parentEntityId=${parentEntityId}` : "";

    return useQueryLoadable(
        async () =>
            !canFetch
                ? waitForValue()
                : axiosService
                      .get(
                          `/api/admin/iaas-management/${targetType}/entities${queryStr}`,
                      )
                      .then(getData)
                      .then(map(({ id, label }) => ({ value: id, label })))
                      .catch(() => [
                          { value: 1, label: targetType + " 1" },
                          { value: 2, label: targetType + " 2" },
                          { value: 3, label: targetType + " 3" },
                      ]),
        [canFetch, targetType, queryStr],
    );
};
