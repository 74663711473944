import { Button, Forms, ModalOrNothing, P } from "@/components/DesignSystem";
import {
    FreetextPfxEntityAdvancedFilter,
    LoadablePfxEntityAdvancedFilter,
} from "@/components/DesignSystem/PfxEntityAdvancedFilter/PfxEntityAdvancedFilter";
import { WITH_DEPLOY } from "@/components/IntegrationRoutes/form/RouteEditForm";
import {
    EntitySelectFields,
    SOURCE_TYPE,
} from "@/components/Mappers/ExportMapper/EntitySelectFields";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { LoadableRenderer, hasError, isLoading } from "@/modules/loadable";
import { mapperMappingSourceType } from "@/services/mapperMappingSourceType.enum";
import { t } from "@/translations";
import { identity } from "lodash/fp";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { CONNECTION_TYPE_PRICEFX_CLIENT } from "../../../services/connections.service";
import ConditionalErrorAlert from "../../Error/ConditionalErrorAlert";
import { getLoadableSelectProps } from "../../Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import {
    useConnectionsOptionsQuery,
    useFieldKeysQuery,
    useFilterConfigurationsQuery,
    useInitialValuesQuery,
    useSaveFilterMutation,
} from "./loadables";
import {
    useDefinedFieldKeysValidator,
    useFilterConfigurationValidator,
    useFilterNameValidator,
} from "./validators";

const EMPTY_DEFINITION_TEMPLATE = {
    _constructor: "AdvancedCriteria",
    operator: "and",
    criteria: [],
};

const removeNameCriteria = definition => {
    const nameCriteria = definition.criteria.find(
        criteria => criteria.fieldName === "name",
    );
    const filteredDefinition = {
        ...definition,
        criteria: definition.criteria.filter(
            criteria => criteria.fieldName !== "name",
        ),
    };
    return { nameCriteria, filteredDefinition };
};

const restoreDefinitionWithName = (definition, removedCriteria) => {
    return definition
        ? {
              ...definition,
              criteria: [removedCriteria, ...(definition.criteria || [])],
          }
        : {
              ...EMPTY_DEFINITION_TEMPLATE,
              criteria: [removedCriteria],
          };
};

export const FilterForm = ({
    confirmDeployed,
    filterId,
    instanceId,
    onSave,
    onBack,
    isModalVariant = false,
}) => {
    const isNewFilter = !filterId || filterId === "new";
    const saveFilterMutation = useSaveFilterMutation({
        filterId,
        instanceId,
        isNewFilter,
        afterSuccess: onSave,
        confirmDeployed,
    });

    const pending = isLoading(saveFilterMutation);

    const { formId, submit, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values, args: [withDeploy] }) =>
            saveFilterMutation.mutate({ values, withDeploy }),
    });

    const connectionsOptionsQuery = useConnectionsOptionsQuery({ instanceId });
    const initialValuesQuery = useInitialValuesQuery({ filterId, isNewFilter });
    const initialValues = initialValuesQuery.loadable.valueMaybe() ?? {};

    useSetValidatedInitialValues(
        {
            initialValues,
            setValues,
            setTouched,
        },
        [initialValuesQuery.loadable],
    );
    const connectionId = Forms.useFieldValue({ formId, name: "connectionId" });
    const definition = Forms.useFieldValue({ formId, name: "definition" });
    const { entityType, entityName, requiredSelected } =
        EntitySelectFields.useEntitySelectFields({ formId });
    const connection = (
        connectionsOptionsQuery.loadable.valueMaybe() ?? []
    ).find(({ value }) => value === connectionId);
    const isPfxConnection = connection?.type === CONNECTION_TYPE_PRICEFX_CLIENT;
    const isNonPfxConnection = !isPfxConnection && connection;
    const fieldKeysQuery = useFieldKeysQuery({
        objectType: mapperMappingSourceType.connections,
        objectId: connectionId,
        entityType,
        entityName,
        canFetch: connectionId && requiredSelected,
    });
    const maybeFieldKeys = fieldKeysQuery.loadable.valueMaybe();

    const [removedCriteriaStash, setRemovedCriteriaStash] = useState(null);

    useEffect(() => {
        if (maybeFieldKeys) {
            const definitionParsed = definition && JSON.parse(definition);
            const isNameCriteriaInDefinition = definitionParsed?.criteria?.some(
                criteria => criteria.fieldName === "name",
            );
            const isNameInFieldKeys = maybeFieldKeys.some(
                fieldKey => fieldKey.name === "name",
            );

            if (!isNameInFieldKeys && isNameCriteriaInDefinition) {
                const { nameCriteria, filteredDefinition } =
                    removeNameCriteria(definitionParsed);
                setRemovedCriteriaStash(nameCriteria),
                    setValues({
                        definition: JSON.stringify(filteredDefinition),
                    });
            } else if (
                removedCriteriaStash &&
                isNameInFieldKeys &&
                !isNameCriteriaInDefinition
            ) {
                setValues({
                    definition: JSON.stringify(
                        restoreDefinitionWithName(
                            definitionParsed,
                            removedCriteriaStash,
                        ),
                    ),
                });
                setRemovedCriteriaStash(null);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maybeFieldKeys]);

    const filterConfigurationsQuery = useFilterConfigurationsQuery({
        instanceId,
        canFetch: true,
    });
    const submitDisabled =
        (isPfxConnection && !maybeFieldKeys) ||
        (isNonPfxConnection &&
            !filterConfigurationsQuery.loadable.valueMaybe()) ||
        pending;
    const nameValidator = useFilterNameValidator({
        instanceId,
        currentName: initialValues?.name,
    });

    const filterConfigurationValidator = useFilterConfigurationValidator({
        maybeOptions: filterConfigurationsQuery.loadable.valueMaybe(),
    });
    const definedFieldKeysValidator = useDefinedFieldKeysValidator({
        maybeFieldKeys,
    });
    const definitionProps = isPfxConnection
        ? {
              as: LoadablePfxEntityAdvancedFilter,
              fieldKeysLoadable: fieldKeysQuery.loadable,
              validator: Forms.validators.failOnFirst([
                  Forms.pmValidators.isRequired,
                  definedFieldKeysValidator,
              ]),
              label:
                  useFieldKeysQuery.isIdle(fieldKeysQuery) ||
                  hasError(fieldKeysQuery)
                      ? ""
                      : t("filter-form.definition"),
          }
        : isNonPfxConnection
        ? {
              as: FreetextPfxEntityAdvancedFilter,
              validator: Forms.pmValidators.isRequired,
              label: t("filter-form.definition"),
          }
        : // No connection selected
          { as: "div", label: "" };

    const actionButtons = [
        {
            type: "primary",
            htmlType: "button",
            formId,
            onClick: () => submit(WITH_DEPLOY),
            label: t("general.deploy"),
            disabled: submitDisabled,
        },
        !isModalVariant && {
            formId,
            onClick: () => submit(!WITH_DEPLOY),
            label: t("general.save"),
            disabled: submitDisabled,
        },

        {
            htmlType: "button",
            type: "text",
            onClick: onBack,
            label: t("general.cancel"),
        },
    ].filter(Boolean);

    const inputWidth = isModalVariant ? "max" : "default";

    return (
        <ModalOrNothing
            isEnabled={isModalVariant}
            actionButtons={actionButtons}
        >
            <LoadableRenderer
                loadable={connectionsOptionsQuery.loadable}
                hasValue={connections => (
                    <>
                        <P>{t("filter-form.description")}</P>
                        <Forms.Form formId={formId}>
                            <Forms.Fields.Input
                                name="name"
                                label={t("filter-form.name")}
                                required
                                validator={nameValidator}
                                inputWidth={inputWidth}
                            />
                            <Forms.Fields.Select
                                name="connectionId"
                                label={t("filter-form.connection")}
                                required
                                validator={Forms.pmValidators.isRequired}
                                options={connections}
                                allowClear={false}
                                inputWidth={inputWidth}
                            />
                            {isPfxConnection && (
                                <EntitySelectFields
                                    // projectId={projectId}
                                    sourceType={SOURCE_TYPE.CONNECTIONS}
                                    sourceId={connectionId}
                                    formId={formId}
                                    setTouched={setTouched}
                                    setValues={setValues}
                                    inputWidth={inputWidth}
                                />
                            )}
                            {isNonPfxConnection && (
                                <Forms.Fields.Select
                                    name="transformerRef"
                                    label={"Filter Configuration"}
                                    required
                                    validator={
                                        filterConfigurationsQuery.loadable.valueMaybe() &&
                                        filterConfigurationValidator
                                    }
                                    showSearch
                                    placeholder={"Select"}
                                    {...getLoadableSelectProps(
                                        filterConfigurationsQuery.loadable,
                                    )}
                                    inputWidth={inputWidth}
                                />
                            )}
                            <Forms.Field
                                from={identity}
                                name="definition"
                                required
                                {...definitionProps}
                            />

                            {!isModalVariant &&
                                actionButtons.map(buttonProps => (
                                    <Button
                                        key={buttonProps.label}
                                        {...buttonProps}
                                    />
                                ))}
                        </Forms.Form>
                    </>
                )}
                hasError={connectionsError => (
                    <ConditionalErrorAlert
                        error={
                            connectionsError
                                ? t(
                                      "integration-manager-instance.error.unavailable",
                                  )
                                : "Failed to fetch filter"
                        }
                    />
                )}
            />
        </ModalOrNothing>
    );
};

FilterForm.propTypes = {
    filterId: PropTypes.number.isRequired,
    instanceId: PropTypes.number.isRequired,
    onSave: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onNext: PropTypes.func,
    isModalVariant: PropTypes.bool,
    confirmDeployed: PropTypes.bool,
};
