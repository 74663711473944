import {
    hasError,
    isLoading,
    LoadableRenderer,
    responseErrorMessage,
} from "@/modules/loadable";
import { t } from "@/translations";
import React from "react";
import Widget01 from "../../views/Widgets/Widget01";
import { H2, Text } from "../DesignSystem";
import WidgetGrid from "../Integrations/Dashboard/Tabs/WidgetGrid";

export const IaaSDefaults = ({ defaultsQuery }) => {
    const maybeValues = defaultsQuery.loadable.valueMaybe();
    const loading = isLoading(defaultsQuery.loadable);

    if (hasError(defaultsQuery.loadable)) {
        return (
            <LoadableRenderer
                loadable={defaultsQuery.loadable}
                hasError={error => (
                    <Text type="error">{responseErrorMessage(error)}</Text>
                )}
            />
        );
    }

    return (
        <WidgetGrid>
            <Widget01
                variant="shorter"
                loading={loading}
                items={[
                    {
                        render: () => <H2>{maybeValues?.imVersion}</H2>,
                    },
                ]}
                mainText={t("iaas.overview.defaults.kind.im")}
                color="primary"
            />
            <Widget01
                variant="shorter"
                loading={loading}
                items={[
                    {
                        render: () => (
                            <H2>{`${maybeValues?.minMemorySize} / ${maybeValues?.maxMemorySize}`}</H2>
                        ),
                    },
                ]}
                mainText={t("iaas.overview.defaults.kind.memory")}
                color="primary"
            />
            <Widget01
                variant="shorter"
                loading={loading}
                items={[
                    {
                        render: () => (
                            <H2>{`${maybeValues?.minCpu} / ${maybeValues?.maxCpu}`}</H2>
                        ),
                    },
                ]}
                mainText={t("iaas.overview.defaults.kind.cpu")}
                color="primary"
            />
            <Widget01
                variant="shorter"
                loading={loading}
                items={[
                    {
                        render: () => (
                            <H2>
                                {maybeValues?.reportingMonitoringPeriodMillis}{" "}
                                ms
                            </H2>
                        ),
                    },
                ]}
                mainText={t("iaas.overview.defaults.kind.period")}
                color="primary"
            />
            <Widget01
                variant="shorter"
                loading={loading}
                items={[
                    {
                        render: () => (
                            <H2>{maybeValues?.monitoringTimeoutMillis} ms</H2>
                        ),
                    },
                ]}
                mainText={t("iaas.overview.defaults.kind.timeout")}
                color="primary"
            />
            <Widget01
                variant="shorter"
                loading={loading}
                items={[
                    {
                        render: () => <H2>{`${maybeValues?.debug}`}</H2>,
                    },
                ]}
                mainText={t("iaas.overview.defaults.kind.debug")}
                color="primary"
            />
            <Widget01
                variant="shorter"
                loading={loading}
                items={[
                    {
                        render: () => <H2>{`${maybeValues?.customer}`}</H2>,
                    },
                ]}
                mainText={t("iaas.overview.defaults.kind.customer")}
                color="primary"
            />
        </WidgetGrid>
    );
};
