import { useDic } from "@/components/Dic/useDic.hook";
import { useQueryLoadable } from "@/modules/loadable";
import { get, map, pipe } from "lodash/fp";

export const accountToOption = ({ id, serialNumber }) => ({
    value: id,
    label: serialNumber,
});
export const usePartitionOptionsResource = ({ projectId, canFetch = true }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            canFetch
                ? axiosService
                      .get(`/api/projects/${projectId}/partition-assets`)
                      .then(pipe(get("data"), map(accountToOption)))
                : Promise.resolve(undefined),
        [canFetch, axiosService, projectId],
    );
};

usePartitionOptionsResource.isIdle = loadable =>
    loadable.state === "hasValue" && loadable.contents === undefined;
