import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import { UnityIcon } from "@/components/DesignSystem";
import {
    ActionButton,
    CONFIRM_TYPE,
} from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import {
    HEADER_HEIGHT_WITH_BREADCRUMB,
    fieldTypes,
} from "@/components/DesignSystem/Table/constants";
import { colors } from "@/components/DesignSystem/colors";
import {
    WFL_STATUS,
    useRestartLastWFStepMutation,
    useRunningEventWorkflowsQuery,
    useTerminateWFMutation,
} from "@/components/EventWorkflows/loadables";
import { noRenderForParent } from "@/components/Integrations/InstancesList.columns";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { getEventWFTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { isLoading } from "@/modules/loadable";
import { EO_EDIT_PERMISSION } from "@/security/permission.utils";
import { t } from "@/translations";
import { ReactComponent as Circle } from "@pricefx/unity-components/src/icons/unicons/circle.svg";
import PropTypes from "prop-types";
import React, { useCallback, useMemo } from "react";
import { useDic } from "../Dic/useDic.hook";
import { useBreadcrumbButtons } from "../hooks/useBreadcrumbButtons.hook";

export const createColumns = () => [
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.running.column.name"),
        name: "name",
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.running.column.description"),
        name: "description",
        render: noRenderForParent(),
    },
    {
        type: fieldTypes.OPTION,
        options: [
            { label: WFL_STATUS.EXECUTED, value: WFL_STATUS.EXECUTED },
            { label: WFL_STATUS.ACTIVE, value: WFL_STATUS.ACTIVE },
            { label: WFL_STATUS.QUEUED, value: WFL_STATUS.QUEUED },
        ],
        label: t("event-wf.running.column.status"),
        name: "_wfl_status",
        render: noRenderForParent(_wfl_status => (
            <>
                <UnityIcon
                    component={Circle}
                    style={{
                        color:
                            _wfl_status === WFL_STATUS.EXECUTED
                                ? colors.success.primary
                                : colors.warning.primary,
                    }}
                />
                {_wfl_status}
            </>
        )),
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-wf.running.column.lastRun"),
        name: "lastRun",
        render: noRenderForParent(date => (
            <FormattedDateTime>{date}</FormattedDateTime>
        )),
    },
    {
        type: fieldTypes.DATETIME,
        label: t("event-wf.running.column.createdAt"),
        name: "createdAt",
        render: noRenderForParent(date => (
            <FormattedDateTime>{date}</FormattedDateTime>
        )),
    },
];

const preferencesType = preferencesTypes.RUNNING_EVENT_LISTENER_WF_LIST_TABLE;

export const RunningWorkflows = ({
    accountId,
    visible,
    reloadToken,
    reloadAll,
    canEdit,
    onShowRunHistory,
}) => {
    const {
        locationRouterService,
        accountAppLocations: { eventWorkflowNewLocation },
    } = useDic();
    const listQuery = useRunningEventWorkflowsQuery({
        accountId,
        reloadToken,
    });
    const terminateWfMutation = useTerminateWFMutation({
        accountId,
        afterSuccess: reloadAll,
    });
    const restartLastWFStepMutation = useRestartLastWFStepMutation({
        accountId,
        afterSuccess: reloadAll,
    });
    const dataSource = listQuery.loadable.valueMaybe() || [];
    const loading = isLoading(listQuery);

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={
                    record._isExpandable
                        ? [
                              {
                                  title: t("general.show-run-history"),
                                  onClick: onShowRunHistory,
                                  track: {
                                      name: getTrackName("ShowRunHistory"),
                                  },
                              },
                              {
                                  visible: canEdit,
                                  title: t(
                                      "event-wf.running.action.restart-last-step.title",
                                  ),
                                  confirm: {
                                      type: CONFIRM_TYPE.CONFIRM,
                                      message: t(
                                          "event-wf.running.action.restart-last-step.message",
                                      ),
                                      cancelText: t("general.cancel"),
                                      onConfirm:
                                          restartLastWFStepMutation.mutate,
                                  },
                                  track: {
                                      name: getTrackName("RestartLastStep"),
                                  },
                              },
                              {
                                  visible: canEdit,
                                  title: t(
                                      "event-wf.running.action.stop.title",
                                  ),
                                  confirm: {
                                      type: CONFIRM_TYPE.CONFIRM,
                                      message: t(
                                          "event-wf.running.action.stop.message",
                                      ),
                                      okText: t("general.stop"),
                                      cancelText: t("general.cancel"),
                                      onConfirm: terminateWfMutation.mutate,
                                  },
                                  color: "red",
                                  track: { name: getTrackName("Stop") },
                              },
                          ]
                        : []
                }
            />
        ),
        [
            canEdit,
            onShowRunHistory,
            restartLastWFStepMutation.mutate,
            terminateWfMutation.mutate,
        ],
    );
    const columns = useMemo(() => createColumns(), []);

    useBreadcrumbButtons(
        () => [
            {
                permissions: [EO_EDIT_PERMISSION],
                label: "location.accountApp.eventOrchestration.eventWorkflows.create",
                onClick: () =>
                    locationRouterService.navigate(eventWorkflowNewLocation),
                track: { name: getTrackName("New") },
            },
        ],
        [eventWorkflowNewLocation, locationRouterService],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={columns}
            loading={loading}
            dataSource={dataSource}
            fixed
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
            rowKey="id"
            datasetSlicing="local"
            preferencesType={preferencesType}
            visible={visible}
            // exportUrl={`/api/accounts/${accountId}/event-workflow-runs/export`}
        />
    );
};

RunningWorkflows.propTypes = {
    accountId: PropTypes.number.isRequired,
    visible: PropTypes.bool.isRequired,
    canEdit: PropTypes.bool,
    reloadToken: PropTypes.string,
    reloadAll: PropTypes.func.isRequired,
};
