import { AlertCellWithPopover } from "@/components/AlertCellWithPopover/AlertCellWithPopover";
import FormattedDateTime from "@/components/DateTime/FormattedDateTime";
import {
    Button,
    Forms,
    Gap,
    H4,
    Link,
    Switch,
    Table,
} from "@/components/DesignSystem";
import { ReadOnlyProvider } from "@/components/DesignSystem/Forms/ReadOnlyContext";
import { orEmpty } from "@/components/DesignSystem/Forms/validators";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import {
    addLocalFilteredAlerts,
    countAlerts,
} from "@/components/EventSchedulers/EventSchedulers.page";
import { DestinationFields } from "@/components/EventWorkflows/components/DestinationFields";
import { ListenerStepForm } from "@/components/EventWorkflows/ListenerStepForm/ListenerStepForm";
import { LISTENER_STEP_KEY } from "@/components/EventWorkflows/ListenerStepForm/listenerStepFormSteps";
import { SourceFields } from "@/components/EventWorkflows/ListenerStepForm/SourceEventModal";
import {
    useEOTEntitiesOptionsQuery,
    useEOTypesOptionsQuery,
    useWorkflowApprovalRunning,
} from "@/components/EventWorkflows/loadables";
import { useWfNameExistsValidation } from "@/components/EventWorkflows/validators";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import { TableButton } from "@/components/TableButton";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { Badge } from "@pricefx/unity-components";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import { identity } from "lodash";
import {
    concat,
    constant,
    flatMap,
    get,
    isEqual,
    map,
    omit,
    pick,
    pipe,
    prop,
    reduce,
    set,
    tap,
    uniqWith,
    update,
} from "lodash/fp";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRoute } from "react-router5";
import uuid from "uuid/v4";

const PopoverContent = ({ action, children }) => (
    <>
        {children}
        {action && (
            <>
                <Gap />
                <Link
                    onClick={e => {
                        e.stopPropagation();
                        action.onClick?.();
                    }}
                >
                    {action.label}
                </Link>
            </>
        )}
    </>
);

const createListenersColumns = ({
    onEdit,
    onChange,
    onEditTab,
    onDelete,
    readOnly,
}) => [
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.listener.column.name.label"),
        name: "name",
        render: (text, record, rowIndex) => (
            <Button
                size="small"
                type="text"
                label={text}
                disabled={!onEdit}
                onClick={e => {
                    e.stopPropagation();
                    return onEdit?.(record, { isFirst: rowIndex === 0 });
                }}
            />
        ),
    },
    {
        type: fieldTypes.BOOLEAN,
        label: t("event-wf.listener.column.enabled.label"),
        name: "enabled",
        render: (enabled, record) => {
            return (
                <Switch
                    size="small"
                    value={enabled}
                    onChange={enabled => onChange({ _id: record._id, enabled })}
                    disabled={readOnly}
                />
            );
        },
    },
    {
        type: fieldTypes.BOOLEAN,
        label: t(
            "event-wf.listener.column.validatePreviousStepAsyncTaskId.label",
        ),
        name: "validatePreviousStepAsyncTaskId",
        render: (validatePreviousStepAsyncTaskId, record, index) => {
            const switchEl = (
                <Switch
                    size="small"
                    value={validatePreviousStepAsyncTaskId}
                    disabled={readOnly || index === 0}
                    onChange={(validatePreviousStepAsyncTaskId, e) =>
                        onChange({
                            _id: record._id,
                            validatePreviousStepAsyncTaskId,
                        })
                    }
                />
            );

            return (
                <AlertCellWithPopover
                    type="warning"
                    popoverVisible={!!record._orderChangedWarning}
                    popoverTitle={t("general.order-changed")}
                    popoverContent={
                        <PopoverContent
                            action={{
                                label: "Check order",
                                onClick: () =>
                                    onEditTab?.({
                                        record,
                                        tabKey: LISTENER_STEP_KEY.SOURCE,
                                        isFirst: index === 0,
                                    }),
                            }}
                        >
                            {t("general.order-changed.desc")}
                        </PopoverContent>
                    }
                    contentBefore={
                        <AlertCellWithPopover
                            type="warning"
                            popoverVisible={!!record._cannotWaitVisible}
                            popoverTitle={"Cannot wait for previous step"}
                            popoverContent={
                                <PopoverContent>
                                    Cannot wait for previous step as all
                                    previous steps are disabled
                                </PopoverContent>
                            }
                            contentBefore={switchEl}
                        />
                    }
                />
            );
        },
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.listener.column.description.label"),
        name: "description",
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.listener.column.lastRun.label"),
        name: "lastRun",
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.listener.column.createdAt.label"),
        name: "createdAt",
        render: utcStr => <FormattedDateTime utcStr={utcStr} />,
    },
    {
        type: fieldTypes.TEXT,
        label: t("event-wf.listener.column.action.label"),
        name: "_action",
        render: (_, record) => (
            <TableButton
                label={t("general.delete")}
                disabled={readOnly}
                onClick={() => onDelete(record)}
            />
        ),
    },
    {
        type: fieldTypes.TEXT,
        label: t("general.notes"),
        name: "notes",
        render: (_notes, record, rowIndex) => (
            <>
                {record.unableToRun && (
                    <AlertCellWithPopover
                        popoverTitle={"Invalid Links"}
                        popoverContent={
                            <>
                                {
                                    "This Listener contains some links which were deleted. We recommend you validate it and insert proper ones."
                                }
                                <Gap />
                                <Link
                                    onClick={e => {
                                        e.stopPropagation();
                                        onEdit?.(record, {
                                            isFirst: rowIndex === 0,
                                        });
                                    }}
                                >
                                    Validate
                                </Link>
                            </>
                        }
                        content={t(
                            "event-wf.workflows.alert.unable-to-run.label",
                        )}
                    />
                )}
            </>
        ),
    },
];

const updateListeners = (pListener, listeners) => {
    const index = listeners.findIndex(({ _id }) => _id === pListener._id);
    if (index > -1) {
        const newListeners = [...listeners];
        const oldListener = listeners[index];
        newListeners[index] = {
            ...oldListener,
            ...pListener,
            _orderChangedWarning:
                oldListener._orderChangedWarning &&
                !pListener.validatePreviousStepAsyncTaskId, // hide warning when validatePreviousStepAsyncTaskId is switched to true
        };
        // console.log("updateListeners", { index, pListener, oldListener, newListener: newListeners[index], newListeners, });
        return newListeners;
    } else {
        return [...listeners, { _id: uuid(), ...pListener }];
    }
};

const disableFirstListenerWait = listeners => {
    if (listeners.length === 0) return listeners;
    const [firstListener, ...restListeners] = listeners;
    //Every time the listener is moved to the first position, the validatePreviousStepAsyncTaskId should be false
    return [
        {
            ...firstListener,
            validatePreviousStepAsyncTaskId: false,
            _orderChangedWarning:
                firstListener._orderChangedWarning ||
                firstListener.validatePreviousStepAsyncTaskId,
        },
        ...restListeners,
    ];
};

export const WORKFLOW_TYPE = {
    SINGLE: "SINGLE",
    MULTI: "MULTI",
};
const WORKFLOW_TYPES_OPTIONS = [
    {
        label: "Single-source",
        value: WORKFLOW_TYPE.SINGLE,
    },
    {
        label: "Multi-source",
        value: WORKFLOW_TYPE.MULTI,
    },
];

const Nothing = () => null;
export const FieldError = ({ name, value, setValues, validator }) => {
    useEffect(() => {
        setValues({ [name]: value });
    }, [name, setValues, value]);

    return (
        <div style={{ marginBottom: "-0.8rem" }}>
            <Forms.Field
                name={name}
                as={Nothing}
                label={null}
                from={identity}
                validator={validator}
            />
        </div>
    );
};

const getUniqueSources = pipe(
    flatMap(listener => [
        ...get(["stepTrigger", "sourceEvents"], listener),
        ...get(["stepTrigger", "skipEvents"], listener),
    ]),
    map(
        pick([
            SourceFields.fieldNames.sourceType,
            SourceFields.fieldNames.sourceId,
        ]),
    ),
    uniqWith(isEqual),
);

const destinationFields = [
    DestinationFields.fieldNames.destinationType,
    DestinationFields.fieldNames.destinationId,
];
const getUniqueDestinations = pipe(
    map(pick(destinationFields)),
    uniqWith(isEqual),
);

const updateListenerSource = ({ name, value }) =>
    update(["stepTrigger", "sourceEvents"], map(set(name, value)));
const updateListenerSkipEvents = ({ name, value }) =>
    update(["stepTrigger", "skipEvents"], map(set(name, value)));

const sourceToDestinationName = {
    [SourceFields.fieldNames.sourceType]:
        DestinationFields.fieldNames.destinationType,
    [SourceFields.fieldNames.sourceId]:
        DestinationFields.fieldNames.destinationId,
};
const updateListenerDestination = ({ name, value }) =>
    set(sourceToDestinationName[name], value);

const toDestinationTuple = (destination = {}) => [
    destination[DestinationFields.fieldNames.destinationType],
    destination[DestinationFields.fieldNames.destinationId],
];
const toSourceTuple = (source = {}) => [
    source[SourceFields.fieldNames.sourceType],
    source[SourceFields.fieldNames.sourceId],
];

const log = arg => tap(console.log.bind(console, arg));

export const EventWFForm = ({
    accountId,
    title,
    initialValues: _initialValues = {
        workflow: { enabled: true, singleSource: {} },
        steps: [],
    },
    onSubmit,
    onCancel,
    readOnly: _readOnly,
}) => {
    const { wfListenerId } = useAccountAppParams();
    const initialValues = useMemoByDeepEquality(_initialValues);
    const isNew = !initialValues.workflow?.id;
    const [listeners, setListeners] = useState(
        initialValues.steps.map(step => ({ _id: uuid(), ...step })),
    );
    const initialListenerMaybe = useMemo(
        () =>
            !isNew &&
            wfListenerId &&
            listeners.find(({ id }) => id === Number(wfListenerId)),
        [isNew, wfListenerId, listeners],
    );
    const { route, router } = useRoute();
    const setActiveKeyParam = useCurrentHandler(param => {
        if (!isNew)
            router.navigate(route.name, {
                ...router.getState().params,
                wfListenerId: param,
            });
    });
    const listenerView = useDetailDrawerState(initialListenerMaybe);
    useEffect(() => {
        if (!isNew && listenerView.record)
            setActiveKeyParam(listenerView.record.id);
        else setActiveKeyParam("");
    }, [setActiveKeyParam, listenerView.record?.id]);
    const setRecord = useCallback(
        initialValues =>
            listenerView.show(initialValues, ({ isFirst }) => ({ isFirst })),
        [listenerView.show],
    );
    const { formId, handleSubmit, setValues, getBag } = Forms.useForm({
        initialValues: initialValues.workflow,
        onSubmit: ({
            values: {
                _listeners,
                workflowType,
                sourceType,
                sourceId,
                ...values
            },
        }) =>
            onSubmit({
                workflow: {
                    ...values,
                    workflowType,
                    ...(workflowType === WORKFLOW_TYPE.SINGLE
                        ? {
                              singleSource: {
                                  sourceType,
                                  sourceId,
                              },
                          }
                        : {}),
                },
                steps: listeners
                    .map(omit(["_id"]))
                    .map((l, i) => ({ ...l, stepOrder: i })),
            }),
    });
    const workflowTypeValue = Forms.useFieldValue({
        formId,
        name: "workflowType",
    });
    const initialSource = initialValues.workflow?.singleSource; // || uniqueSources?.[0]; new -> multi -> add listener -> single?
    const initialWorkflowType = initialValues.workflow?.singleSource
        ? WORKFLOW_TYPE.SINGLE
        : WORKFLOW_TYPE.MULTI;

    const isSingleSource = workflowTypeValue === WORKFLOW_TYPE.SINGLE;
    const singleSource = {
        sourceType: SourceFields.useSourceType({ formId }),
        sourceId: SourceFields.useSourceId({ formId }),
    };

    const eoTypesQuery = useEOTypesOptionsQuery({ accountId });
    const eotSourceEntitiesQuery = useEOTEntitiesOptionsQuery({
        accountId,
        eoType: singleSource.sourceType,
        canFetch: !!singleSource.sourceType,
    });
    const onSingleSourceChange = useCallback(({ meta, name, value }) => {
        setListeners(
            map(
                pipe(
                    updateListenerSource({ name, value }),
                    updateListenerSkipEvents({ name, value }),
                    updateListenerDestination({ name, value }),
                ),
            ),
        );
    }, []);

    const listenerFormDisabled =
        isSingleSource && (!singleSource.sourceType || !singleSource.sourceId);
    const typeChangeDisabled = useMemo(() => {
        const uniqueSources = getUniqueSources(listeners);
        const uniqueDestinations = getUniqueDestinations(listeners);
        const uniqueTuples = pipe(
            concat(uniqueSources.map(toSourceTuple)),
            concat(uniqueDestinations.map(toDestinationTuple)),
            uniqWith(isEqual),
        )([]);
        return uniqueTuples.length > 1;
    }, [listeners]);

    const onAdd = useCallback(() => {
        listenerView.show({}, { isFirst: listeners.length === 0 });
    }, [listenerView, listeners]);
    const onDelete = useCallback(
        record =>
            setListeners(listeners =>
                disableFirstListenerWait(
                    listeners.filter(listener => listener._id !== record._id),
                ),
            ),
        [],
    );
    const onChange = useCurrentHandler(partialListener => {
        setListeners(listeners => updateListeners(partialListener, listeners));
    });
    const onEditTab = useCurrentHandler(({ record, tabKey, isFirst }) => {
        listenerView.show(record, { isFirst, tabKey });
    });
    const nameValue = Forms.useFieldValue({ formId, name: "name" });
    const { isRunning } = useWorkflowApprovalRunning(
        isNew
            ? { workflowName: nameValue }
            : { workflowId: initialValues.workflow?.id },
    );
    const readOnly = _readOnly || (isRunning && !isNew);

    const listenersColumns = useMemo(
        () =>
            createListenersColumns({
                onEdit: !listenerFormDisabled && listenerView.show,
                onEditTab,
                onChange,
                onDelete,
                readOnly,
            }),
        [
            listenerFormDisabled,
            listenerView.show,
            onEditTab,
            onChange,
            onDelete,
            readOnly,
        ],
    );
    const onListenerSubmit = useCallback(values => {
        setListeners(listeners => {
            return updateListeners({ enabled: true, ...values }, listeners);
        });
        // listenerView.hide();
    }, []);
    const onDrop = useCallback(({ oldIndex: from, newIndex: to }) => {
        setListeners(listeners => {
            const newListeners = [...listeners];
            const [moved] = newListeners.splice(from, 1);
            newListeners.splice(to, 0, moved);
            return disableFirstListenerWait(newListeners);
        });
    }, []);
    const skipValidation = useCallback(
        ({ name, sourceType, sourceId }) => {
            const incompleteParams =
                isSingleSource && (!sourceType || !sourceId);
            const sourceMatch =
                initialWorkflowType === workflowTypeValue &&
                sourceType === initialSource?.sourceType &&
                sourceId === initialSource?.sourceId;
            const isExistingEdit =
                !isNew && name === initialValues.workflow?.name && sourceMatch;

            return incompleteParams || isExistingEdit;
        },
        [
            initialSource?.sourceId,
            initialSource?.sourceType,
            initialValues.workflow?.name,
            initialWorkflowType,
            isNew,
            isSingleSource,
            workflowTypeValue,
        ],
    );
    const wfNameExistsValidation = useWfNameExistsValidation({
        accountId,
        skipValidation,
        isSingleSource: workflowTypeValue === WORKFLOW_TYPE.SINGLE,
        sourceType: singleSource.sourceType,
        sourceId: singleSource.sourceId,
    });
    const dsWithAlerts = pipe(
        reduce(
            ({ dataSourceAcc, _canWaitForPrev }, item) => ({
                dataSourceAcc: dataSourceAcc.concat({
                    ...item,
                    _cannotWaitVisible:
                        item.validatePreviousStepAsyncTaskId &&
                        !_canWaitForPrev,
                }),
                _canWaitForPrev: _canWaitForPrev || item.enabled,
            }),
            { dataSourceAcc: [], _canWaitForPrev: false },
        ),
        prop("dataSourceAcc"),
        addLocalFilteredAlerts([
            {
                prop: "validatePreviousStepAsyncTaskId",
                getVisible: prop("_orderChangedWarning"),
                getType: constant("YELLOW"),
                getTooltip: constant(
                    `${t("general.order-changed")} - ${t(
                        "general.order-changed.desc",
                    )}`,
                ),
            },
            {
                prop: "validatePreviousStepAsyncTaskId",
                getVisible: prop("_cannotWaitVisible"),
                getType: constant("YELLOW"),
                getTooltip: constant(
                    "Cannot wait for previous step as all previous steps are disabled",
                ),
            },
            {
                prop: "notes",
                getVisible: prop("unableToRun"),
                getType: constant("RED"),
                getTooltip: constant(t("general.unable-to-run")),
            },
        ]),
    )(listeners);
    const { rowsWithErrorCount, rowsWithWarningCount } =
        countAlerts(dsWithAlerts);

    return (
        <ReadOnlyProvider readOnly={readOnly}>
            <div hidden={listenerView.record}>
                <Forms.Form formId={formId} onSubmit={handleSubmit}>
                    <Forms.FieldGroup>
                        <H4>{t("event-wf.form.general")}</H4>
                        <Forms.Fields.Switch
                            name="enabled"
                            label={t("event-wf.form.enabled.label")}
                            layout="horizontal"
                            noMaxWidth
                            textOn={t("general.enabled")}
                            textOff={t("general.disabled")}
                        />
                        <Forms.Fields.Input
                            name="name"
                            label={t("event-wf.form.name.label")}
                            validator={Forms.validators.failOnFirst([
                                Forms.pmValidators.isRequired,
                                Forms.pmValidators.createMinLengthValidation(1),
                                Forms.pmValidators.createMaxLengthValidation(
                                    255,
                                ),
                                wfNameExistsValidation,
                            ])}
                            required
                        />
                        <Forms.Fields.Input
                            name="description"
                            label={t(
                                "event-wf.listener-form.description.label",
                            )}
                            validator={Forms.validators.failOnFirst([
                                orEmpty(
                                    Forms.pmValidators.createMaxLengthValidation(
                                        255,
                                    ),
                                ),
                            ])}
                        />
                        <Forms.Fields.InputNumber
                            name="executionOrder"
                            label={t("event-wf.form.executionOrder.label")}
                            validator={orEmpty(Forms.pmValidators.min(1))}
                        />
                        <Forms.Fields.InputNumber
                            name="timeoutInMinutes"
                            label={t("event-wf.form.timeoutInMinutes.label")}
                            placeholder={t(
                                "event-wf.form.timeoutInMinutes.placeholder",
                            )}
                            validator={Forms.validators.failOnFirst([
                                // Forms.pmValidators.isRequired,
                                orEmpty(Forms.pmValidators.min(1)),
                            ])}
                            // required
                            addonAfter={"min"}
                            tooltip={t(
                                "event-wf.form.timeoutInMinutes.tooltip",
                            )}
                        />
                        <H4>{t("event-wf.form.types")}</H4>
                        <Forms.Fields.Radio
                            required
                            name="workflowType"
                            label={t("event-wf.form.workflowType.label")}
                            options={WORKFLOW_TYPES_OPTIONS}
                            validator={Forms.pmValidators.isRequired}
                            tooltip={t("event-wf.form.workflowType.tooltip")}
                            disabled={typeChangeDisabled}
                            initialValue={initialWorkflowType}
                        />
                        {isSingleSource && (
                            <SourceFields
                                eoTypesQuery={eoTypesQuery}
                                eotSourceEntitiesQuery={eotSourceEntitiesQuery}
                                onChange={onSingleSourceChange}
                                initialValues={initialSource}
                            />
                        )}
                    </Forms.FieldGroup>
                    <Forms.FieldGroup width="max" inputWidth="max">
                        <H4>
                            {t("event-wf.form.listeners")}{" "}
                            {!!rowsWithWarningCount && (
                                <Badge
                                    type="warning"
                                    label={t(
                                        "event-wf.form.listeners.warnings",
                                        { count: rowsWithWarningCount },
                                    )}
                                />
                            )}
                            {!!rowsWithErrorCount && (
                                <Badge
                                    type="error"
                                    label={t("event-wf.form.listeners.errors", {
                                        count: rowsWithErrorCount,
                                    })}
                                />
                            )}
                        </H4>
                        <Table
                            rowKey="name"
                            columns={listenersColumns}
                            dataSource={dsWithAlerts}
                            pagination={false}
                            hasColumnAutofit
                            hasColumnResizing
                            rowDragDrop={readOnly ? undefined : { onDrop }}
                        />
                        <FieldError
                            name="_listeners"
                            value={listeners}
                            setValues={setValues}
                            validator={Forms.validators.failOnFirst([
                                Forms.pmValidators.isRequired,
                                Forms.pmValidators.createMinLengthValidation(
                                    1,
                                    { map: identity },
                                ),
                            ])}
                        />
                        {/* <Gap size="small" /> */}
                        <Button
                            data-test="add-listener-button"
                            icon={Plus}
                            label={t("event-wf.form.add-listener")}
                            onClick={onAdd}
                            disabled={listenerFormDisabled}
                            visible={!readOnly}
                        />
                    </Forms.FieldGroup>
                    <Button
                        visible={!readOnly}
                        formId={formId}
                        data-test={isNew ? "create-button" : "save-button"}
                        htmlType="submit"
                        type="primary"
                        label={isNew ? t("general.create") : t("general.save")}
                    />
                    <Button
                        data-test="cancel-button"
                        htmlType="button"
                        type="text"
                        onClick={onCancel}
                        label={
                            readOnly ? t("general.back") : t("general.cancel")
                        }
                        disabled={false}
                    />
                </Forms.Form>
            </div>
            {!!listenerView.record && (
                <ListenerStepForm
                    accountId={accountId}
                    initialValues={listenerView.record}
                    setInitialValues={setRecord}
                    listeners={listeners}
                    isFirstListener={listenerView.value?.isFirst}
                    showOrderWarning={listenerView.record?._orderChangedWarning}
                    isSingleSource={isSingleSource}
                    singleSource={singleSource}
                    eoTypesQuery={eoTypesQuery}
                    onSubmit={onListenerSubmit}
                    onCancel={listenerView.hide}
                    defaultActiveTab={listenerView.value?.tabKey}
                    readOnly={readOnly}
                />
            )}
        </ReadOnlyProvider>
    );
};

EventWFForm.propTypes = {
    initialValues: PropTypes.object,
    title: PropTypes.string.isRequired,
    accountId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
