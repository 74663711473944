import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";
import { useCreateMassListenerMutation } from "./loadables";
import { MassListenerForm } from "./MassListenerForm";

export const NewMassListenerPage = () => {
    const { accountId } = useAccountAppParams();
    useSetPageTitle(
        t("location.accountApp.eventOrchestration.massListeners.create"),
    );
    const {
        locationRouterService,
        accountAppLocations: { massListenersLocation },
    } = useDic();
    const onCancel = useCallback(
        () => locationRouterService.navigate(massListenersLocation),
        [massListenersLocation, locationRouterService],
    );
    useSetBackButtonAction(onCancel);
    const createMutation = useCreateMassListenerMutation({
        accountId,
        afterSuccess: onCancel,
    });

    return (
        <MassListenerForm
            accountId={accountId}
            initialValues={undefined}
            onSubmit={createMutation.mutate}
            onCancel={onCancel}
        />
    );
};
