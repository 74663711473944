import { t } from "@/translations";
import { Forms } from "../DesignSystem";
import { orEmpty } from "../DesignSystem/Forms/validators";
import React from "react";

export const PayloadBodyFields = ({
    name,
    label = t("event-wf.listener-form.payload-content"),
    checkboxName,
    checkboxTooltip = t("payload-fields.use-original-payload.tooltip"),
    isJSONPayload,
    ...rest
}) => {
    const useEventBodyAsPayload = Forms.useFieldValue({
        formId: Forms.useFormId(),
        name: checkboxName,
    });
    const payloadValidator = isJSONPayload
        ? orEmpty(Forms.pmValidators.isValidJSON)
        : undefined;

    return (
        <>
            {!!checkboxName && (
                <Forms.Fields.Checkbox
                    name={checkboxName}
                    label="Use the original event payload as the body"
                    tooltip={checkboxTooltip}
                    initialValue={false}
                />
            )}
            <Forms.Fields.TextArea
                name={name}
                label={label}
                width="max"
                disabled={!!checkboxName && useEventBodyAsPayload}
                rows="5"
                initialValue=""
                validator={payloadValidator}
                {...rest}
            />
        </>
    );
};
