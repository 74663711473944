import { getIaaSAdminTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import React from "react";
import { ButtonMenu, Forms, Modal, UnityLayout } from "../DesignSystem";
import { IaaSIMVersionSelect } from "./IaaSIMVersionSelect";
import { useUpsertOverrideMutation } from "./loadables";
import { OverrideField, overridesDef } from "./OverrideForm";

const EditDefaultsForm = ({ initialValues, afterSave, onClose }) => {
    const mutation = useUpsertOverrideMutation({ afterSave });
    const { Form, formId, handleSubmit } = Forms.useForm({
        initialValues,
        onSubmit: ({ values }) => {
            return mutation.mutate(values);
        },
    });

    return (
        <>
            <UnityLayout.Content padding={[false, true]}>
                <Form formId={formId} onSubmit={handleSubmit}>
                    <Forms.Fields.Input hidden name="id" />
                    <Forms.Fields.Input hidden name="targetType" />
                    <IaaSIMVersionSelect />
                    <Forms.FieldGroup grid>
                        <Forms.FieldGroup.Col>
                            <OverrideField
                                name={overridesDef.minMemorySize.name}
                            />
                            <OverrideField name={overridesDef.minCpu.name} />
                        </Forms.FieldGroup.Col>
                        <Forms.FieldGroup.Col>
                            <OverrideField
                                name={overridesDef.maxMemorySize.name}
                            />
                            <OverrideField name={overridesDef.maxCpu.name} />
                        </Forms.FieldGroup.Col>
                    </Forms.FieldGroup>
                    <OverrideField
                        name={overridesDef.reportingMonitoringPeriodMillis.name}
                    />
                    <OverrideField
                        name={overridesDef.monitoringTimeoutMillis.name}
                    />
                    <OverrideField name={overridesDef.debug.name} />
                    <OverrideField name={overridesDef.customer.name} />
                </Form>
            </UnityLayout.Content>
            <UnityLayout.Footer
                actionButtons={
                    <ButtonMenu
                        buttons={[
                            {
                                formId,
                                label: t("general.save"),
                                type: "primary",
                                track: { name: getTrackName("save") },
                            },
                            {
                                label: t("general.cancel"),
                                type: "text",
                                onClick: onClose,
                                track: { name: getTrackName("Cancel") },
                            },
                        ]}
                    />
                }
            />
        </>
    );
};

export const EditDefaultsModal = ({
    visible = false,
    defaultsQuery,
    onClose,
    afterSave = onClose,
}) => {
    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header
                    size={3}
                    title={t("iaas.override.form.title")}
                />
                {visible && (
                    <LoadableRenderer
                        loadable={defaultsQuery.loadable}
                        hasValue={initialValues => (
                            <EditDefaultsForm
                                initialValues={initialValues}
                                afterSave={afterSave}
                                onClose={onClose}
                            />
                        )}
                    />
                )}
            </UnityLayout>
        </Modal>
    );
};
