import { useMemo } from "react";

export const useTableExampleDataToOptions = (tableExampleData, inputOptions) =>
    useMemo(
        () =>
            inputOptions ||
            tableExampleData.columns.map(column => ({
                label: column.name,
                value: column.name,
            })),
        [tableExampleData, inputOptions],
    );
