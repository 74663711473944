import { UnityIcon, UnityTooltip } from "@/components/DesignSystem";
import { RowSkeleton } from "@/components/DesignSystem/Skeleton/RowSkeleton.component";
import { useDic } from "@/components/Dic/useDic.hook";
import { MAPPER_DIRECTION } from "@/components/ISVMapping/steps/General.step";
import { InputType } from "@/components/Packages/PackageTableDefinitionPanel/constants";
import {
    isLoading,
    pendingPromise,
    useQueryLoadable,
} from "@/modules/loadable";
import { logger } from "@/modules/logger";
import { getData } from "@/services/utils";
import { debounceAsync } from "@/utils/promises/promise.utils";
import { ReactComponent as IconExclamationCircle } from "@pricefx/unity-components/dist/es/icons/unicons/exclamation-circle.svg";
import { pick } from "lodash";
import { get, pipe } from "lodash/fp";
import React, { useCallback } from "react";

const DEFAULT_COMPOSED_SEPARATOR = "_";

export const renderExampleValue = (mapperItem, mapperToExampleFile) => {
    if (Array.isArray(mapperItem.input)) {
        return mapperItem.input
            .map(mapperToExampleFile)
            .join(DEFAULT_COMPOSED_SEPARATOR);
    }
    return mapperToExampleFile(mapperItem.input);
};

const getExampleValueFromResult = mapperItem => mapperValue => {
    const result = get(["result", 0, mapperItem?.output])(mapperValue);
    if (typeof result !== "string") {
        return JSON.stringify(result);
    } else {
        return result;
    }
};

const previewDisabledReason = mapperItem => {
    if (!mapperItem.output?.[0]) return "missing_output";

    if ([InputType.HEADER].includes(mapperItem?.inputType))
        return `input_type_${mapperItem?.inputType}`;

    if (
        mapperItem.inputType === InputType.BODY &&
        !mapperItem?.converterExpression
    )
        return "body_without_converter";
};
export const isPreviewDisabled = pipe(previewDisabledReason, Boolean);

export const useCellPreviewQuery = ({
    mapperItem: passedMapperItem,
    rowData,
    parserConfig: passedParserConfig,
    isDisabled,
}) => {
    const mapperItem = pick(passedMapperItem, [
        "formulaMapping",
        "input",
        "inputType",
        "output",
        "converterExpression",
    ]);
    const parserConfig = pick(passedParserConfig, [
        "separator",
        "quoteChar",
        "escapeChar",
        "decimalSeparator",
        "dateFormat",
        "useVirtualHeader",
    ]);
    const { convertersService } = useDic();

    const debouncedRequest = useCallback(
        debounceAsync(500, ({ rowData, mapperItem, parserConfig }) =>
            convertersService
                .testDataLoadMapper({
                    rowData: [rowData],
                    mapperItems: [mapperItem],
                    parserConfig,
                })
                .then(getData),
        ),
        [],
    );

    return useQueryLoadable(
        async () =>
            isDisabled
                ? pendingPromise()
                : debouncedRequest({ rowData, mapperItem, parserConfig }),
        [
            mapperItem?.converterExpression,
            mapperItem?.input,
            mapperItem?.output,
            isDisabled,
        ],
    );
};

export const CellPreview = ({
    parserConfig,
    mapperItem,
    getExampleRow,
    direction,
}) => {
    const cellPreviewResource = useCellPreviewQuery({
        mapperItem,
        rowData: getExampleRow(),
        parserConfig,
        isDisabled:
            !!isPreviewDisabled(mapperItem) ||
            direction === MAPPER_DIRECTION.DOWNLOAD,
    }).loadable;

    logger.debug({
        logGroupKey: ["DATALOAD", "CellPreview", "rndr"],
        color: "orchid",
        data: {
            mapperItem,
            convertedValueLoadable: cellPreviewResource,
            disabledReason: previewDisabledReason(mapperItem),
        },
    });

    // We dont have data from partition, preview disabled
    if (direction === MAPPER_DIRECTION.DOWNLOAD) {
        return null;
    }

    if (previewDisabledReason(mapperItem)) {
        return null;
    }

    const maybeValue = cellPreviewResource.valueMaybe();
    const loading = isLoading(cellPreviewResource);
    const successValue =
        maybeValue?.success &&
        getExampleValueFromResult(mapperItem)(maybeValue);

    return (
        <div>
            {loading ? (
                <RowSkeleton />
            ) : maybeValue?.success ? (
                successValue
            ) : maybeValue?.errorRows > 0 ? (
                <>
                    <UnityIcon component={IconExclamationCircle} />{" "}
                    {maybeValue?.errors?.[0].reason}
                </>
            ) : (
                <UnityTooltip
                    tooltip={maybeValue?.errors
                        ?.map(error => error.reason)
                        .join("\n")}
                >
                    No Preview available
                </UnityTooltip>
            )}
        </div>
    );
};
