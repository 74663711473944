import { useExportMutation } from "@/components/DesignSystem/Table/hooks/useExportCsv.hook";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import React, { useCallback, useMemo, useRef } from "react";
import { UserAdminSettingsTableContainer } from "../UserAdminSettingsTable/UserAdminSettingsTable.container";
import { InviteUserModalComponent } from "./InviteUserModal.component";
import {
    useAccountUsersResource,
    useDeleteAccountUserResource,
    useInviteAccountUserResource,
} from "./loadables";

export const useGetExportAccessReportButton = ({
    exportUrl = `/api/projects/{projectId}/rights/users/export`,
    fileName = "access-report.csv",
    permissions,
}) => {
    const filtersRef = useRef();
    const { mutate: exportAccessReport } = useExportMutation({ fileName });
    const exportAccessReportButton = useMemo(
        () => ({
            label: "general.export-access-report",
            type: "secondary",
            permissions,
            onClick: () => {
                exportAccessReport({
                    exportUrl:
                        filtersRef.current.getExportUrlWithSort(exportUrl),
                    filters: filtersRef.current.filters || null,
                    noColumns: true,
                });
            },
        }),
        [exportAccessReport, exportUrl, permissions],
    );

    return {
        filtersRef,
        exportAccessReportButton,
    };
};

export const UserAdminSettings = () => {
    useFullWidthTableLayout();
    useHideBackButton();

    const { accountId } = useAccountAppParams();
    const inviteModal = useVisibility(false);

    const accountUsersResource = useAccountUsersResource({ accountId });
    const afterUsersMutated = useCallback(() => {
        inviteModal.hide();
        accountUsersResource.reload();
    }, [inviteModal, accountUsersResource]);
    const inviteAccountUserResource = useInviteAccountUserResource({
        accountId,
        afterSuccess: afterUsersMutated,
    });
    const deleteAccountUserResource = useDeleteAccountUserResource({
        accountId,
        afterSuccess: afterUsersMutated,
    });

    const { filtersRef, exportAccessReportButton } =
        useGetExportAccessReportButton({
            exportUrl: `/api/projects/${accountId}/rights/users/export`,
        });
    useBreadcrumbButtons(
        () => [
            {
                label: "account-admin-users-list.button.invite-user",
                type: "primary",
                onClick: inviteModal.show,
            },
            exportAccessReportButton,
        ],
        [exportAccessReportButton, inviteModal.show],
    );

    return (
        <>
            <LoadableRenderer
                loadable={accountUsersResource.loadable}
                hasValue={(
                    users, // TODO: rm, inner table is server-paginated
                ) => (
                    <UserAdminSettingsTableContainer
                        accountId={accountId}
                        users={users}
                        onUserDelete={deleteAccountUserResource.mutate}
                        reload={accountUsersResource.reload}
                        filtersRef={filtersRef}
                    />
                )}
            />
            <InviteUserModalComponent
                visible={inviteModal.visible}
                onCancel={inviteModal.hide}
                onOk={inviteAccountUserResource.mutate}
            />
        </>
    );
};

UserAdminSettings.propTypes = {};
