import React from "react";
import { useDic } from "../Dic/useDic.hook";
import { useCurrentHandler } from "../hooks/useCurrentHandler.hook";
import { useUpsertOverrideMutation } from "./loadables";
import { OverrideForm } from "./OverrideForm";

export const NewIaaSPage = () => {
    const { locationRouterService, administrationAppLocations } = useDic();
    const onCancel = useCurrentHandler(() =>
        locationRouterService.navigate(administrationAppLocations.IaaSLocation),
    );
    const mutation = useUpsertOverrideMutation({ afterSave: onCancel });

    return (
        <OverrideForm
            initialValues={undefined}
            onSubmit={mutation.mutate}
            onCancel={onCancel}
        />
    );
};
