import { Gap, H3, Link, Text } from "@/components/DesignSystem";
import {
    IM_UPDATE_RESULT,
    MINUTE,
    STEPS_ESTIMATIONS_SECONDS,
} from "@/components/Integrations/ImUpdate/constants";
import {
    isProcessDone,
    isProcessRunning,
    useStepErrorHandling,
} from "@/components/Integrations/ImUpdate/helpers";
import {
    getIMUpdateStep,
    updateStep,
} from "@/components/Integrations/ImUpdate/ImUpdate.component";
import { useIntegrationUpdateStepState } from "@/components/Integrations/ImUpdate/loadables";
import { useLeaveCallback } from "@/components/Integrations/ImUpdate/useLeaveCallback.hook";
import Progress from "@/components/Progress/Progress";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import styles from "../styles.less";

export const UpdateStep = ({
    instance,
    process,
    onStartNewUpdate,
    cleanUpdateProcess,
    onCancel,
    isLatestVersionMaybe,
}) => {
    const instanceId = instance.id;
    const updateResource = useIntegrationUpdateStepState({
        instanceId,
        step: "finish-update",
        canFetch:
            process?.type ===
                getIMUpdateStep(updateStep.FINISH_UPDATE).sseType &&
            isProcessDone(process),
    });

    const updateResult = updateResource.loadable.valueMaybe();
    useStepErrorHandling(updateResult);

    useLeaveCallback({
        triggerWhen:
            isProcessDone(process) &&
            updateResult?.status !== IM_UPDATE_RESULT.MERGE_ERROR,
        onLeave: cleanUpdateProcess,
    });

    return (
        <>
            {isProcessRunning(process) ? (
                <>
                    <Text>
                        {t("im-instance.update.update.in-progress", {
                            minutes: STEPS_ESTIMATIONS_SECONDS.UPDATE / MINUTE,
                        })}
                    </Text>
                    <Progress
                        className={styles.progress}
                        showInfo
                        percent={process?.progress}
                        message={t("im-instance.update.update.progress")}
                    />
                </>
            ) : isProcessDone(process) ? (
                <>
                    <H3>{t("im-instance.update.result")}</H3>
                    {updateResult?.status === IM_UPDATE_RESULT.MERGE_ERROR ? (
                        <Text>
                            {t("im-instance.update.result.merge-needed")}
                            <Link
                                href={updateResult?.mergeRequestUrl}
                                targetBlank
                            >
                                {updateResult?.mergeRequestUrl}
                            </Link>
                        </Text>
                    ) : updateResult?.status === IM_UPDATE_RESULT.OK ? (
                        <Text>
                            {t("im-instance.update.result.success", {
                                version: updateResult?.version,
                            })}
                        </Text>
                    ) : null}
                    {isLatestVersionMaybe ? null : (
                        <>
                            <Gap size="medium" />
                            <Text>
                                {t("im-instance.update.new-version-available", {
                                    link: (
                                        <Link onClick={onStartNewUpdate}>
                                            {t(
                                                "im-instance.update.new-version-available.link",
                                            )}
                                        </Link>
                                    ),
                                })}
                            </Text>
                        </>
                    )}
                </>
            ) : null}
        </>
    );
};

UpdateStep.propTypes = {
    process: PropTypes.object.isRequired,
    instance: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onStartNewUpdate: PropTypes.func.isRequired,
    cleanUpdateProcess: PropTypes.func.isRequired,
    isLatestVersionMaybe: PropTypes.bool,
};
