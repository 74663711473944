import React from "react";
import { Forms } from "../DesignSystem";
import { getLoadableSelectProps } from "../Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { useIaaSIMVersionsQuery } from "./loadables";
import { t } from "@/translations";

export const IaaSIMVersionSelect = props => {
    const query = useIaaSIMVersionsQuery();

    return (
        <Forms.Fields.Select
            required
            label={t("iaas.override.form.im-version.label")}
            name="imVersion"
            validator={Forms.pmValidators.isRequired}
            {...getLoadableSelectProps(query.loadable)}
            {...props}
        />
    );
};
