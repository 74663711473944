import { SwitchAsync } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import {
    HEADER_HEIGHT_WITH_BREADCRUMB,
    fieldTypes,
} from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import {
    EO_LOGS_ENTITY_TYPE,
    EO_LOGS_TABS,
} from "@/components/EventLogs/EventLogs.page";
import { useHasEditPermission } from "@/components/EventWorkflows/useHasEditPermission.hook";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { useRefreshAction } from "@/components/PageLayout/useRefreshAction.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useBreadcrumbButtons } from "@/components/hooks/useBreadcrumbButtons.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { preferencesTypes } from "@/constants/preferencesTypes.constants";
import { getMassListenerTrackName as getTrackName } from "@/mixpanel/buttonNames";
import { isLoading } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { EO_EDIT_PERMISSION } from "@/security/permission.utils";
import { t } from "@/translations";
import { flatten } from "flat";
import { map, pipe } from "lodash/fp";
import React, { useCallback, useContext, useMemo, useReducer } from "react";
import { ApprovalWorkflowsEnabledContext } from "../WorkFlow/ApprovalWorkflowsEnabledProvider";
import {
    useChangeMassListenerStatusMutation,
    useDeleteMassListenerMutation,
    useMassListenersQuery,
} from "./loadables";

export const createColumns = ({
    accountId,
    canEdit,
    approvalWorkflowEnabled,
    reloadAll,
} = {}) => [
    {
        type: fieldTypes.TEXT,
        label: t("mass-listeners.list.columns.name"),
        name: "name",
    },
    {
        type: fieldTypes.OPTION,
        options: [
            { label: "Active", value: true },
            { label: "Inactive", value: false },
        ],
        label: t("mass-listeners.list.columns.enabled"),
        name: "enabled",
        render: (enabled, record) => {
            return (
                <SwitchAsync
                    initialValue={enabled}
                    useMutation={useChangeMassListenerStatusMutation}
                    from={({ checked }) => ({
                        accountId,
                        id: record.id,
                        enabled: checked,
                    })}
                    hookParams={{
                        afterSuccess: approvalWorkflowEnabled
                            ? reloadAll
                            : undefined,
                    }}
                    isWorkflowApprovalMaybe={approvalWorkflowEnabled}
                    textOn={t("general.active")}
                    textOff={t("general.inactive")}
                    disabled={!canEdit || record.approvalWorkflowRunning}
                />
            );
        },
    },
    {
        type: fieldTypes.TEXT,
        label: t("mass-listeners.list.columns.eventSourceType"),
        name: "source.sourceType",
    },
    {
        type: fieldTypes.TEXT,
        label: t("mass-listeners.list.columns.eventSource"),
        name: "source.label",
    },
    {
        type: fieldTypes.TEXT,
        label: t("mass-listeners.list.columns.eventType"),
        name: "trigger.sourceEventType",
    },
    {
        type: fieldTypes.TEXT,
        label: t("mass-listeners.list.columns.actionType"),
        name: "target.targetType",
    },
    {
        type: fieldTypes.TEXT,
        label: t("mass-listeners.list.columns.action"),
        name: "target.targetId",
    },
];

const preferencesType = preferencesTypes.MASS_LISTENER_LIST_TABLE;

const EDIT_PERMISSIONS = [EO_EDIT_PERMISSION];

export const MassListenersPage = () => {
    const { accountId } = useAccountAppParams();
    const [reloadToken, reloadAll] = useReducer(s => ++s, 0);

    const approvalWorkflowEnabled = useContext(
        ApprovalWorkflowsEnabledContext,
    ).EVENT_ORCHESTRATION_APPROVAL;

    useFullWidthTableLayout();
    useRefreshAction(reloadAll);
    useHideBackButton();

    const {
        locationRouterService,
        accountAppLocations: {
            massListenerNewLocation,
            massListenerEditLocation,
            massListenerShowLocation, // TODO
            eventLogsLocation,
        },
    } = useDic();
    const canEdit = useHasEditPermission({
        permissions: EDIT_PERMISSIONS,
    });

    const columns = useMemo(
        () =>
            createColumns({
                accountId,
                canEdit,
                approvalWorkflowEnabled,
                reloadAll,
            }),
        [accountId, approvalWorkflowEnabled, canEdit],
    );

    useBreadcrumbButtons(
        () => [
            {
                permissions: EDIT_PERMISSIONS,
                label: "location.accountApp.eventOrchestration.massListeners.create",
                onClick: () =>
                    locationRouterService.navigate(massListenerNewLocation),
                track: { name: getTrackName("New") },
            },
        ],
        [massListenerNewLocation, locationRouterService],
    );

    const onEdit = useCallback(
        record =>
            locationRouterService.navigate(massListenerEditLocation, {
                listenerId: record?.id,
            }),
        [massListenerEditLocation, locationRouterService],
    );
    // const onShowDefinition = useCallback(
    //     record =>
    //         locationRouterService.navigate(massListenerShowLocation, {
    //             listenerId: record?.id,
    //         }),
    //     [massListenerShowLocation, locationRouterService],
    // );
    const onShowRunHistory = useCallback(
        ({ id }) =>
            locationRouterService.navigate(eventLogsLocation, {
                tab: EO_LOGS_TABS.RUN_HISTORY,
                entityType: EO_LOGS_ENTITY_TYPE.LISTENER,
                entityId: id,
            }),
        [eventLogsLocation, locationRouterService],
    );

    const listQuery = useMassListenersQuery({
        accountId,
        reloadToken,
    });
    const deleteMutation = useDeleteMassListenerMutation({
        accountId,
        afterSuccess: reloadAll,
    });
    const dataSource = useMemo(
        () =>
            pipe(map(record => flatten(record, { safe: true })))(
                listQuery.loadable.valueMaybe() || [],
            ),
        [listQuery.loadable],
    );
    const loading = isLoading(listQuery);

    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        visible: canEdit && !record.approvalWorkflowRunning,
                        title: t("general.tooltip.edit"),
                        onClick: onEdit,
                        track: { name: getTrackName("Edit") },
                    },
                    // TODO: next release
                    // {
                    //     title: t("general.show-definition"),
                    //     onClick: onShowDefinition,
                    //     track: { name: getTrackName("ShowDefinition") },
                    // },
                    {
                        title: t("general.show-run-history"),
                        onClick: onShowRunHistory,
                        track: { name: getTrackName("ShowRunHistory") },
                    },
                    {
                        visible: canEdit,
                        title: t("general.modal.delete.title", {
                            type: t("general.mass-listener"),
                        }),
                        confirm: {
                            message: `${t("general.modal.delete.message", {
                                name: record.name,
                                type: t("general.mass-listener"),
                            })}`,
                            onConfirm: record =>
                                deleteMutation.mutate({
                                    listenerId: record.id,
                                }),
                        },
                        color: "red",
                        track: { name: getTrackName("Delete") },
                    },
                ]}
            />
        ),
        [canEdit, deleteMutation, onEdit, onShowRunHistory],
    );

    return (
        <TableWithPreferencesManagement
            actionMenu={actionMenu}
            columns={columns}
            loading={loading}
            dataSource={dataSource}
            fixed
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB}
            rowKey="id"
            datasetSlicing="local"
            preferencesType={preferencesType}
            exportUrl={`/api/accounts/${accountId}/event-listeners/export`}
        />
    );
};
