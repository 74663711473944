import { Tag } from "@/components/DesignSystem";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { HEADER_HEIGHT_WITH_BREADCRUMB } from "@/components/DesignSystem/Table/constants";
import { isLoading } from "@/modules/loadable";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import { preferencesTypes } from "../../constants/preferencesTypes.constants";
import { TableWithPreferencesManagement } from "../TableWithPreferences/TableWithPreferencesManagement.container";

const preferencesType = preferencesTypes.SFTP_SERVER_USERS_TABLE;

const SYSTEM_USER_NAME = "customer";
const columns = [
    {
        label: t("sftp-servers.user-management.columns.username"),
        name: "username",
        render: (text, record) => (
            <>
                {text}
                {record.username === SYSTEM_USER_NAME && (
                    <Tag color="processing" size="small">
                        {t("sftp-servers.user-management.info.system-user")}
                    </Tag>
                )}
            </>
        ),
    },
    // TODO: created, api not ready yet
];

export const SFTPServerUsersList = ({
    createOrEdit,
    sftpServerUsersQuery,
    deleteSftpUserMutation,
    restHeight = 0,
    ...tableProps
}) => {
    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.edit"),
                        onClick: createOrEdit(record.username),
                    },
                    {
                        title: t("general.delete"),
                        color: "red",
                        confirm: {
                            title: t("sftp.add-user-modal.confirm.title"),
                            message: t("sftp.add-user-modal.confirm.message", {
                                username: record.username,
                            }),
                            okText: t("general.confirm"),
                            cancelText: t("general.cancel"),
                            onConfirm: deleteSftpUserMutation.mutate,
                        },
                        visible: record.canDelete,
                    },
                ]}
            />
        ),
        [createOrEdit, deleteSftpUserMutation.mutate],
    );

    return (
        <TableWithPreferencesManagement
            fixed
            datasetSlicing="local"
            restHeight={HEADER_HEIGHT_WITH_BREADCRUMB + restHeight}
            preferencesType={preferencesType}
            columns={columns}
            actionMenu={actionMenu}
            loading={isLoading(sftpServerUsersQuery)}
            dataSource={sftpServerUsersQuery.loadable.valueMaybe() ?? []}
            rowKey="username"
            {...tableProps}
        />
    );
};
SFTPServerUsersList.propTypes = {
    createOrEdit: PropTypes.func.isRequired,
    sftpServerUsersQuery: PropTypes.object.isRequired,
    deleteSftpUserMutation: PropTypes.object.isRequired,
    restHeight: PropTypes.number,
};
