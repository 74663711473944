import {
    ButtonMenu,
    ColumnsSelectModal,
    DatePicker,
    Label,
} from "@/components/DesignSystem";
import { useMemoByDeepEquality } from "@/components/hooks/useMemoByDeepEquality.hook";
import { QuerySearch } from "@/components/Integrations/Dashboard/Tabs/QuerySearch/QuerySearch.component";
import { useTableSettings } from "@/components/PageLayout/useSetMenuItems.hook";
import { elkQueryService } from "@/services/elkQuery.service";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useContext, useMemo } from "react";
import { PageLayoutContext } from "../PageLayout/PageLayoutContext";
import styles from "./SearchPanel.style.less";

const { RangePicker } = DatePicker;

const SearchPanel = ({
    accountId,
    entityId,
    entityType,

    availableColumns,
    selectedColumns,

    onColumnsChange,
    onDateRangeChange,
    onSearch,

    query,
    dateFrom,
    dateTo,

    visible = true,
    showEmbeddedActionButtons,
    menuItemsId = JSON.stringify({
        id: "_search-panel",
        availableColumns,
        selectedColumns,
    }),
}) => {
    const { actionButtons } = useContext(PageLayoutContext);
    const isValid = query => {
        return elkQueryService.isValid(accountId, entityId, entityType, query);
    };

    const memoizedAvailableColumns = useMemoByDeepEquality(availableColumns);
    const memoizedSelectedColumns = useMemoByDeepEquality(selectedColumns);

    const items = useMemo(
        () => [
            {
                anchor: t("table.preference-dropdown.fields-visibility.table"),
                component: ColumnsSelectModal,
                columns: memoizedAvailableColumns.map(col => ({
                    name: col,
                    visible: !!memoizedSelectedColumns[col],
                })),
                actions: {
                    setColumns: cols => {
                        onColumnsChange(
                            cols.reduce(
                                (acc, col) => ({
                                    ...acc,
                                    [col.name]: col.visible,
                                }),
                                {},
                            ),
                        );
                    },
                },
                "data-test": "table-fields",
            },
        ],
        [memoizedAvailableColumns, memoizedSelectedColumns],
    );
    useTableSettings({
        id: menuItemsId,
        visible,
        items,
    });

    return (
        <div className={styles.wrapper}>
            <QuerySearch
                accountId={accountId}
                entityId={entityId}
                entityType={entityType}
                initialQueryString={query}
                isValid={isValid}
                onSearch={onSearch}
            />

            <label htmlFor="searchPanel-dateRange">
                <Label label={t("search-panel.date-range.label")} />
                <RangePicker
                    id="searchPanel-dateRange"
                    showTime={{ format: "HH:mm" }}
                    format="DD/MM/YYYY HH:mm"
                    placeholder={[
                        t("search-panel.date-range.placeholder-from"),
                        t("search-panel.date-range.placeholder-to"),
                    ]}
                    defaultValue={[dateFrom, dateTo]}
                    onOk={onDateRangeChange}
                />
            </label>
            {showEmbeddedActionButtons && (
                <ButtonMenu
                    buttons={actionButtons}
                    buttonSize="default"
                    ellipsis={false}
                    _trackingDisabled // TODO: UC mutates buttons and adds sfdc_table_showTableSetting inside settings button
                />
            )}
        </div>
    );
};

SearchPanel.propTypes = {
    accountId: PropTypes.number.isRequired,
    entityId: PropTypes.number.isRequired,
    entityType: PropTypes.string.isRequired,
    availableColumns: PropTypes.array.isRequired,
    selectedColumns: PropTypes.object.isRequired,
    onColumnsChange: PropTypes.func.isRequired,
    onDateRangeChange: PropTypes.func.isRequired,
    showEmbeddedActionButtons: PropTypes.bool,
    menuItemsId: PropTypes.string,
    query: PropTypes.string,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    onSearch: PropTypes.func,
    visible: PropTypes.bool,
};

export default SearchPanel;
