import { t } from "@/translations";
import React from "react";
import FormattedDateTime from "./DateTime/FormattedDateTime";
import FormattedDuration from "./DateTime/FormattedDuration";
import { fieldTypes } from "./DesignSystem/Table/constants";

export const columnsBuilder = constantInfo => {
    const { type, columnDefinition } = constantInfo;

    return columnDefinition.map(column => ({
        label: t(type + "." + column.name),
        name: column.name,
        render: (text, record) => (
            <ColumnComponent record={record} col={column} />
        ),
        ...getColumnTypeProps(column),
    }));
};

const ColumnComponent = ({ record, col }) => {
    switch (col.type) {
        case "LocalDateTime":
            return <FormattedDateTime>{record[col.name]}</FormattedDateTime>;
        case "Duration":
            return <FormattedDuration>{record[col.name]}</FormattedDuration>;
        case "boolean": // current
        case "Boolean": // if somebody on BE will make it nullable, it will become this
            return `${record[col.name]}`;
        default:
            return record[col.name];
    }
};

const getColumnTypeProps = column => {
    if (Array.isArray(column.possibleValues)) {
        return {
            type: fieldTypes.OPTION,
            options: column.possibleValues.map(value => ({
                label: value,
                value,
            })),
        };
    }
    switch (column.type) {
        case "LocalDateTime":
            return { type: fieldTypes.DATETIME };
        case "boolean": // current
        case "Boolean": // if somebody on BE will make it nullable, it will become this
            return { type: fieldTypes.BOOLEAN };
        default:
            return { type: fieldTypes.TEXT };
    }
};
