import { map, noop, tap } from "lodash/fp";
import { useDic } from "../Dic/useDic.hook";
import { useWithWorkflowWithReasonMaybe } from "../WorkFlow/workflowNotification";
import { getSwitchChangeSuccessMsg } from "../DesignSystem/SwitchAsync/helpers";
import { useCurrentHandler } from "../hooks/useCurrentHandler.hook";
import { useMutationLoadable, useQueryLoadable } from "@/modules/loadable";
import { getData } from "@/services/utils";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { useAlertEffect } from "../PageLayout/useAlertEffect.hook";
import { t } from "@/translations";
import qs from "qs";
import { Forms } from "../DesignSystem";
import { useCallback } from "react";

export const useMassListenersQuery = ({ accountId, reloadToken }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/accounts/${accountId}/event-listeners`)
                .then(getData),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [reloadToken, accountId, axiosService],
    );
};

export const useDeleteMassListenerMutation = ({
    accountId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withWorkflowWithReasonMaybe = useWithWorkflowWithReasonMaybe();

    return useMutationLoadable(
        async ({ listenerId }) =>
            withWorkflowWithReasonMaybe(wfValues =>
                axiosService.post(
                    `/api/accounts/${accountId}/event-listeners/${listenerId}/delete`,
                    { ...wfValues },
                ),
            )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [
            accountId,
            afterSuccessCurrent,
            axiosService,
            withWorkflowWithReasonMaybe,
        ],
    );
};

export const useChangeMassListenerStatusMutation = ({
    afterSuccess = noop,
} = {}) => {
    const { axiosService } = useDic();
    const withWorkflowWithReasonMaybe = useWithWorkflowWithReasonMaybe({
        noWorkflowSuccess: getSwitchChangeSuccessMsg,
    });
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);

    return useMutationLoadable(
        async ({ id, enabled, accountId }) =>
            withWorkflowWithReasonMaybe(wfValues =>
                axiosService.post(
                    `/api/accounts/${accountId}/event-listeners/${id}/enable`,
                    { enabled, ...wfValues },
                ),
            ).then(afterSuccessCurrent),
        [afterSuccessCurrent, axiosService, withWorkflowWithReasonMaybe],
    );
};

export const useCreateMassListenerMutation = ({
    accountId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withWorkflowWithReasonMaybe = useWithWorkflowWithReasonMaybe({});

    return useMutationLoadable(
        async payload =>
            withWorkflowWithReasonMaybe(wfValues =>
                axiosService.post(
                    `/api/accounts/${accountId}/event-listeners`,
                    {
                        ...payload,
                        ...wfValues,
                    },
                ),
            )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [
            accountId,
            afterSuccessCurrent,
            axiosService,
            withWorkflowWithReasonMaybe,
        ],
    );
};

export const useMassListenerQuery = ({ accountId, listenerId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(`/api/accounts/${accountId}/event-listeners/${listenerId}`)
                .then(getData),
        [accountId, axiosService, listenerId],
    );
};

export const useUpdateMassListenerMutation = ({
    accountId,
    listenerId,
    afterSuccess = noop,
}) => {
    const { axiosService } = useDic();
    const afterSuccessCurrent = useCurrentHandler(afterSuccess);
    const withWorkflowWithReasonMaybe = useWithWorkflowWithReasonMaybe({});

    return useMutationLoadable(
        async payload =>
            withWorkflowWithReasonMaybe(wfValues =>
                axiosService.post(
                    `/api/accounts/${accountId}/event-listeners/${listenerId}`,
                    { ...payload, ...wfValues },
                ),
            )
                .then(getData)
                .then(tap(afterSuccessCurrent)),
        [
            accountId,
            afterSuccessCurrent,
            axiosService,
            listenerId,
            withWorkflowWithReasonMaybe,
        ],
    );
};

const useMassListenerApprovalWFRunningQuery = ({
    accountId,
    workflowName,
    workflowId,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/accounts/${accountId}/event-listeners/is-approval-wf-running?${qs.stringify(
                        { workflowName, workflowId },
                    )}`,
                )
                .then(getData),
        [accountId, axiosService, workflowId, workflowName],
    );
};

export const useMassListenerApprovalRunning = ({
    id,
    name,
    withAlert = true,
}) => {
    const { accountId } = useAccountAppParams();
    const query = useMassListenerApprovalWFRunningQuery({
        accountId,
        id,
        name,
    });
    const isRunning = query.loadable.valueMaybe();
    const alertVisible = withAlert && isRunning;

    const alertDef = {
        type: "info",
        message: t("general.workflow-approval-pending.title"),
        description: t("general.workflow-approval-pending.description", {
            entity: t("general.workflow"),
        }),
    };
    useAlertEffect(alertVisible, () => alertDef);

    return {
        isRunning,
        alert: isRunning ? alertDef : undefined,
    };
};

// OPTIONS:

export const useELTypeOptionsQuery = ({ accountId }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            axiosService
                .get(
                    `/api/accounts/${accountId}/event-listeners/event-origin-types`,
                )
                .then(getData)
                .then(map(type => ({ value: type, label: type }))),
        [accountId, axiosService],
    );
};

export const useELEventOptionsQuery = ({ accountId, type, canFetch }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.resolve()
                : axiosService
                      .get(
                          `/api/accounts/${accountId}/event-listeners/event-origin-types/${type}/events`,
                      )
                      .then(getData)
                      .then(map(type => ({ value: type, label: type }))),
        [accountId, axiosService, canFetch, type],
    );
};

export const useELEntitiesOptionsQuery = ({
    accountId,
    type,
    canFetch = accountId && type,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.resolve()
                : axiosService
                      .get(
                          `/api/accounts/${accountId}/event-listeners/event-origin-types/${type}/entities`,
                      )
                      .then(getData)
                      .then(
                          map(({ id, name }) => ({ value: id, label: name })),
                      ),
        [accountId, axiosService, canFetch, type],
    );
};

export const useELActionTypesOptionsQuery = ({ accountId, type, canFetch }) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.resolve()
                : axiosService
                      .get(
                          `/api/accounts/${accountId}/event-listeners/event-origin-types/${type}/action-types`,
                      )
                      .then(getData)
                      .then(map(type => ({ value: type, label: type }))),
        [accountId, axiosService, canFetch, type],
    );
};

export const useELActionEntitiesOptionsQuery = ({
    accountId,
    type, // PARTITION
    actionType, // LOGIC
    entityId, // {partitionId}
    listenerId, // optional?
    canFetch,
}) => {
    const { axiosService } = useDic();

    return useQueryLoadable(
        async () =>
            !canFetch
                ? Promise.resolve()
                : axiosService
                      .get(
                          `/api/accounts/${accountId}/event-listeners/event-origin-types/${type}/action-types/${actionType}/entities?${qs.stringify(
                              {
                                  targetId: entityId,
                                  listenerId,
                              },
                          )}`,
                      )
                      .then(getData)
                      .then(
                          map(({ id, name }) => ({
                              value: id,
                              label: name || id,
                          })),
                      ),
        [
            accountId,
            actionType,
            axiosService,
            canFetch,
            entityId,
            listenerId,
            type,
        ],
    );
};

export const useMassListenerNameExistsValidation = ({
    accountId,
    skipValidation,
}) => {
    const { axiosService } = useDic();

    return useCallback(
        async (name, getBag) => {
            if (skipValidation({ name })) return Forms.success();
            const params = qs.stringify({ name }, { addQueryPrefix: true });

            return axiosService
                .get(`/api/accounts/${accountId}/event-listeners${params}`)
                .then(getData)
                .then(list => {
                    if (list?.length) return Forms.error("Already exists");
                    else return Forms.success();
                });
        },
        [accountId, axiosService, skipValidation],
    );
};
