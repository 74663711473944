import { useRouteParams } from "@/modules/router";
import { object, number, string } from "yup";

const schema = object().shape({
    accountId: number(),
    userId: number(),
    groupId: string(),
    id: number(),
});

export function useAdministrationAppParams() {
    const params = useRouteParams();

    return schema.cast(params);
}
