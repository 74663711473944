import { t } from "@/translations";
import { ReactComponent as Plus } from "@pricefx/unity-components/src/icons/unicons/plus.svg";
import { ReactComponent as Trash } from "@pricefx/unity-components/src/icons/unicons/trash-alt.svg";
import {
    get,
    identity,
    isNil,
    keys,
    map,
    negate,
    omit,
    pick,
    pickBy,
    pipe,
    values,
} from "lodash/fp";
import React, { useCallback, useMemo, useState } from "react";
import FieldGridNotPadded from "../DataUploads/SftpManagement/FieldGridNotPadded.component";
import {
    Button,
    DropdownMenu,
    Forms,
    Gap,
    H4,
    Input,
    Switch as DesignSystemSwitch,
    Text,
} from "../DesignSystem";
import { FieldError } from "../EventWorkflows/EventWFForm";
import { getLoadableSelectProps } from "../Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { IaaSTargetType } from "./constants";
import { IaaSIMVersionSelect } from "./IaaSIMVersionSelect";
import { useIaaSDefaultsQuery, useIaaSEntitiesQuery } from "./loadables";

const SwitchWidthFix = props => (
    <Forms.Field as={DesignSystemSwitch} from={identity} {...props} />
);

export const overridesDef = {
    imVersion: {
        name: "imVersion",
        label: t("iaas.override.form.im-version.label"),
        Component: IaaSIMVersionSelect,
    },
    minMemorySize: {
        name: "minMemorySize",
        label: t("iaas.override.form.min-memory-size.label"),
        addonAfter: "Mi",
        Component: Input,
        valueAsAddonAfter: true,
    },
    maxMemorySize: {
        name: "maxMemorySize",
        label: t("iaas.override.form.max-memory-size.label"),
        addonAfter: "Mi",
        Component: Input,
        valueAsAddonAfter: true,
    },
    minCpu: {
        name: "minCpu",
        label: t("iaas.override.form.min-cpu.label"),
        addonAfter: "m",
        Component: Input,
        valueAsAddonAfter: true,
    },
    maxCpu: {
        name: "maxCpu",
        label: t("iaas.override.form.max-cpu.label"),
        addonAfter: "m",
        Component: Input,
        valueAsAddonAfter: true,
    },
    reportingMonitoringPeriodMillis: {
        name: "reportingMonitoringPeriodMillis",
        label: t("iaas.override.form.reporting-monitoring-period-millis.label"),
        Component: Forms.Fields.InputNumber,
        addonAfter: "ms",
    },
    monitoringTimeoutMillis: {
        name: "monitoringTimeoutMillis",
        label: t("iaas.override.form.monitoring-timeout-millis.label"),
        Component: Forms.Fields.InputNumber,
        addonAfter: "ms",
    },
    debug: {
        name: "debug",
        label: t("iaas.override.form.debug.label"),
        Component: SwitchWidthFix,
    },
    customer: {
        name: "customer",
        label: t("iaas.override.form.customer.label"),
    },
};

const parseDynamic = (value, defaultAddonAfter) => {
    if (!value) return [value, defaultAddonAfter];
    const match = `${value}`.match(/^(\d*)(\w*)/);
    if (!match) return [value, defaultAddonAfter];
    return [match[1], match[2]];
};
const parseStatic = (value = "", addonAfter) => {
    if (!value || typeof value !== "string") return [value, addonAfter];
    return [`${value}`.replace(/\D+/g, ""), addonAfter];
};
const unparse = (value, addonAfter) => `${value}${addonAfter}`;

// Assumes that parent Forms.Field has from and to - identity
const withValueAsAddonAfter =
    (FieldInner, { defaultAddonAfter } = {}) =>
    ({ value: valueProp, onChange: onChangeProp, ...props }) => {
        const [value, addonAfter] = parseStatic(valueProp, defaultAddonAfter);
        const onChange = useCallback(
            event => {
                const value = event.target.value;
                const valueWithUnit = unparse(value, addonAfter);

                onChangeProp(valueWithUnit);
            },
            [addonAfter, onChangeProp],
        );

        return (
            <FieldInner
                {...props}
                value={value}
                addonAfter={addonAfter}
                onChange={onChange}
            />
        );
    };

// TODO: use in EditDefaultsModal
export const OverrideField = ({ name, defaultVal, required, ...props }) => {
    const {
        valueAsAddonAfter,
        Component = valueAsAddonAfter ? Input : Forms.Fields.Input,
        addonAfter,
        ...fieldDefProps
    } = overridesDef[name];
    const supportedValueAsAddonAfterComponents = [Input];
    if (
        valueAsAddonAfter &&
        !supportedValueAsAddonAfterComponents.includes(Component)
    ) {
        console.log(
            "Unsupported component for valueAsAddonAfter",
            overridesDef[name],
        );
        throw new Error("Unsupported component for valueAsAddonAfter");
    }

    const { validators: passedValidators, ...commonProps } = {
        name,
        description: defaultVal,
        required,
        ...fieldDefProps,
        ...props,
    };
    const validator = useMemo(
        () =>
            Forms.validators.failOnFirst(
                [
                    required && Forms.pmValidators.isRequired,
                    ...(passedValidators || []),
                ].filter(Boolean),
            ),
        [passedValidators, required],
    );

    if (valueAsAddonAfter)
        return (
            <Forms.Field
                as={withValueAsAddonAfter(Component, {
                    defaultAddonAfter: addonAfter,
                })}
                {...commonProps}
                validator={validator}
                from={identity}
                to={identity}
            />
        );
    return (
        <Component
            {...commonProps}
            validator={validator}
            addonAfter={addonAfter}
        />
    );
};

const overrides = Object.keys(overridesDef);

const targetTypeOptions = pipe(
    omit([IaaSTargetType.PARTITION]),
    values,
    map(value => ({
        value,
        label: value
            .toLowerCase()
            .split("_")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "),
    })),
)(IaaSTargetType);

// const targetTypeOptions = [
//     { value: IaaSTargetType.ACCOUNT, label: IaaSTargetType.ACCOUNT },
// ];

const EntitySelect = ({ targetType, parentName, ...props }) => {
    const noParent = !parentName;
    const parentEntityId = Forms.useFieldValue({
        formId: Forms.useFormId(),
        name: noParent ? "_" : parentName,
    });
    const canFetch = !!targetType && (noParent || !!parentEntityId);
    const optionsQuery = useIaaSEntitiesQuery({
        targetType,
        parentEntityId: noParent ? undefined : parentEntityId,
        canFetch,
    });
    const maybeOptions = optionsQuery.loadable.valueMaybe();

    const missingDepsProps = canFetch
        ? {}
        : { disabled: true, placeholder: t("general.select"), loading: false };
    // if (!canFetch) return null;

    return (
        <Forms.Fields.Select
            required
            validator={Forms.validators.failOnFirst([
                Forms.pmValidators.isRequired,
                Forms.pmValidators.createAllowedValuesValidator(
                    maybeOptions?.map(get("value")) ?? [],
                    "Value not found in options",
                ),
            ])}
            {...getLoadableSelectProps(optionsQuery.loadable)}
            {...missingDepsProps}
            {...props}
        />
    );
};

// when names of fields are stable on type change,
// form keeps already selected values on type change
const targetIdByType = values => ({
    ...values,
    targetId: {
        [IaaSTargetType.ACCOUNT]: values.projectId,
        [IaaSTargetType.USER]: values.userId,
        [IaaSTargetType.PARTITION]: values.partitionId,
        [IaaSTargetType.DATA_UPLOAD]: values.dataUploadId,
        [IaaSTargetType.DATA_DOWNLOAD]: values.dataDownloadId,
    }[values.targetType],
});

const getDefault = ({ name, defaultsMaybe }) => {
    const def = overridesDef[name];
    if (!defaultsMaybe) return undefined;
    const defaultVal = defaultsMaybe[name];
    if (isNil(defaultVal)) return undefined;
    if (def?.addonAfter && !def?.valueAsAddonAfter)
        return `Default: ${defaultVal} ${def.addonAfter}`;
    return `Default: ${defaultVal}`;
};

export const OverrideForm = ({ initialValues, onSubmit, onCancel }) => {
    const defaultsMaybe = useIaaSDefaultsQuery().loadable.valueMaybe();
    const { Form, formId, setValues } = Forms.useForm({
        initialValues,
        onSubmit: ({ values }) => {
            return pipe(
                omit(["_overridesValidationHelperValue"]),
                targetIdByType,
                onSubmit,
            )(values);
        },
    });
    const isNew = !initialValues;
    const targetTypeValue = Forms.useFieldValue({ formId, name: "targetType" });
    const initialVisibleOverrides = useMemo(
        () =>
            pipe(
                pick(Object.keys(overridesDef)),
                pickBy(negate(isNil)),
                keys,
            )(initialValues || {}),
        [initialValues],
    );
    const [visibleOverrides, setVisibleOverrides] = useState(
        initialVisibleOverrides,
    );
    const addOverride = useCallback(
        name => setVisibleOverrides(prev => [...prev, name]),
        [],
    );
    const removeOverride = useCallback(
        name => setVisibleOverrides(prev => prev.filter(item => item !== name)),
        [],
    );
    const availableOverrides = useMemo(
        () =>
            overrides
                .filter(name => !visibleOverrides.includes(name))
                .map(name => ({
                    anchor: overridesDef[name].label,
                    onClick: () => addOverride(name),
                })),
        [addOverride, visibleOverrides],
    );

    return (
        <Form disableBanner>
            <Forms.FieldGroup>
                {!isNew && (
                    <Forms.Fields.Input hidden type="hidden" name="id" />
                )}
                <Forms.Fields.Switch
                    name="enabled"
                    label={""}
                    layout="horizontal"
                    size="small"
                    textOn={t("general.active")}
                    textOff={t("general.inactive")}
                    initialValue={true}
                />
                <H4>{t("iaas.override.form.target")}</H4>
                <Forms.Fields.Select
                    name="targetType"
                    label={t("iaas.override.form.target-type.label")}
                    required
                    validator={Forms.validators.failOnFirst([
                        Forms.pmValidators.isRequired,
                    ])}
                    options={targetTypeOptions}
                />
                {targetTypeValue === IaaSTargetType.USER && (
                    <EntitySelect
                        targetType={IaaSTargetType.USER}
                        name="userId"
                        label={t("iaas.override.form.user.label")}
                    />
                )}
                {!!targetTypeValue &&
                    targetTypeValue !== IaaSTargetType.USER && (
                        <EntitySelect
                            targetType={IaaSTargetType.ACCOUNT}
                            name="projectId"
                            label={t("iaas.override.form.account.label")}
                        />
                    )}
                {[
                    IaaSTargetType.PARTITION,
                    IaaSTargetType.DATA_UPLOAD,
                    IaaSTargetType.DATA_DOWNLOAD,
                ].includes(targetTypeValue) && (
                    <>
                        <EntitySelect
                            targetType={IaaSTargetType.PARTITION}
                            parentName="projectId"
                            name="partitionId"
                            label={t("iaas.override.form.partition.label")}
                        />
                        {targetTypeValue === IaaSTargetType.DATA_UPLOAD && (
                            <EntitySelect
                                targetType={IaaSTargetType.DATA_UPLOAD}
                                parentName="partitionId"
                                name="dataUploadId"
                                label={t(
                                    "iaas.override.form.data-upload.label",
                                )}
                            />
                        )}
                        {targetTypeValue === IaaSTargetType.DATA_DOWNLOAD && (
                            <EntitySelect
                                targetType={IaaSTargetType.DATA_DOWNLOAD}
                                parentName="partitionId"
                                name="dataDownloadId"
                                label={t(
                                    "iaas.override.form.data-download.label",
                                )}
                            />
                        )}
                    </>
                )}
            </Forms.FieldGroup>
            <Gap size="large" />
            <H4>{t("iaas.override.form.override-values")}</H4>
            <Text>{t("iaas.override.form.override-values.desc")}</Text>
            <Gap size="small" />
            <FieldError
                name="_overridesValidationHelperValue"
                value={visibleOverrides}
                setValues={setValues}
                validator={Forms.validators.failOnFirst([
                    // Forms.pmValidators.isRequired,
                    Forms.pmValidators.createMinLengthValidation(1, {
                        map: identity,
                        messageKey:
                            "iaas.override.form.override-values-length.error",
                    }),
                ])}
            />
            <Gap size="small" />
            {visibleOverrides.map(name => {
                // Waiting for UC
                // const defaultVal = getDefault({ name, defaultsMaybe, });

                return (
                    <React.Fragment key={name}>
                        <FieldGridNotPadded>
                            <Forms.FieldGrid
                                layout={[{ width: 360 }, { width: 32 }]}
                            >
                                <Forms.FieldGrid.Row>
                                    <OverrideField
                                        name={name}
                                        // defaultVal={defaultVal}
                                        required
                                    />
                                    <Forms.UIField name="actions">
                                        <Button
                                            // style={{ transform: !defaultVal ? "translateY(-1px)" : "translateY(21px)", }}
                                            type="secondary"
                                            icon={Trash}
                                            onClick={() => removeOverride(name)}
                                        />
                                    </Forms.UIField>
                                </Forms.FieldGrid.Row>
                            </Forms.FieldGrid>
                        </FieldGridNotPadded>
                        <Gap size="small" />
                    </React.Fragment>
                );
            })}
            {!availableOverrides?.length ? null : (
                <DropdownMenu
                    button={{
                        icon: Plus,
                        dropdown: true,
                        size: "small",
                        label: "Add option",
                    }}
                    overlay={{
                        contextMenu: true,
                        items: availableOverrides,
                        dataTest: "TablePreferencesMenuDropdown-overlay",
                    }}
                />
            )}
            <Gap size="large" />
            <Button
                formId={formId}
                data-test={isNew ? "create-button" : "save-button"}
                htmlType="submit"
                type="primary"
                label={isNew ? t("general.create") : t("general.save")}
            />
            <Button
                data-test="cancel-button"
                htmlType="button"
                type="text"
                onClick={onCancel}
                label={t("general.cancel")}
                disabled={false}
            />
        </Form>
    );
};
