import { useDic } from "@/components/Dic/useDic.hook";
import { defaultParserConfigValues } from "@/components/FileParsing/FileParsingFields";
import { mapVendorToVendorName } from "@/components/ISVMapping/helpers";
import EntitiesStep from "@/components/ISVMapping/steps/Entities.step";
import FieldsStep from "@/components/ISVMapping/steps/Fields.step";
import GeneralStep from "@/components/ISVMapping/steps/General.step";
import ParsingStep from "@/components/ISVMapping/steps/Parsing.step";
import SynchronizationStep from "@/components/ISVMapping/steps/Synchronization.step";
import UploadStep from "@/components/ISVMapping/steps/Upload.step";
import { useHeaderAlertProp } from "@/components/PageLayout/useSetAlert.hook";
import { StepForm } from "@/components/StepForm";
import { useRouteISVConnection } from "@/mixpanel/hooks/useRouteISVConnection.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import { head } from "lodash";
import React, { useCallback, useMemo, useState } from "react";

export const ISV_MAPPER_STEP_KEY = {
    GENERAL: "General",
    ENTITIES: "Entities",
    LOGIN: "Login",
    UPLOAD: "Upload",
    PARSING: "Parsing",
    FIELDS: "Fields",
    SYNCHRONIZATION: "Synchronization",
};

const ISVDataMappingForm = ({
    accountId,
    title,
    initialValues = {
        ...defaultParserConfigValues,
    },
    isEdit,
    onSave,
    onCancel,
}) => {
    const { isvConnectionId, isvMapperId, tab } = useAccountAppParams();
    const alerts = useHeaderAlertProp();

    const {
        locationRouterService,
        accountAppLocations: { isvDataMappingUploadLocation },
    } = useDic();
    const { isvConnectionLoadable } = useRouteISVConnection();
    const [allValues, setAllValues] = useState(initialValues);

    const handleSave = useCallback(
        ({ values }) =>
            onSave({
                vendor: isvConnectionLoadable.valueMaybe()?.vendor,
                // include transformed mapper state rows with form values
                mapper: allValues.mapper,
                ...values,
            }),
        [allValues, isvConnectionLoadable, onSave],
    );

    const [isValidMapper, setIsValidMapper] = useState(true);

    const uploadFileActionButton = useMemo(
        () => [
            {
                key: "upload-new-csv",
                onClick: () =>
                    locationRouterService.navigate(
                        isvDataMappingUploadLocation,
                        {
                            accountId,
                            isvConnectionId,
                            isvMapperId,
                        },
                    ),
                label: t("isv-connections.data-mapping.header.upload-new-csv"),
            },
        ],
        [
            accountId,
            isvConnectionId,
            isvDataMappingUploadLocation,
            isvMapperId,
            locationRouterService,
        ],
    );
    const [actionButtons, setActionButtons] = useState(
        isEdit && tab === ISV_MAPPER_STEP_KEY.FIELDS
            ? uploadFileActionButton
            : [],
    );

    const getStepButtons = useCallback(
        ({ stepperProps, formId, isTabVariant }) => [
            {
                visible: isTabVariant || (!isEdit && stepperProps.isLastStep),
                label: isEdit ? t("general.save") : t("general.create"),
                type: "primary",
                formId,
            },
            {
                visible:
                    !isEdit &&
                    !(
                        stepperProps.isLastStep ||
                        (stepperProps.currentStep.key ===
                            ISV_MAPPER_STEP_KEY.UPLOAD &&
                            !allValues?.uploadedFile?.id)
                    ),
                label: t("general.continue"),
                type: "primary",
                formId,
                disabled:
                    (stepperProps.currentStep.key ===
                        ISV_MAPPER_STEP_KEY.PARSING &&
                        !allValues?.fileInfo) ||
                    (stepperProps.currentStep.key === ISV_MAPPER_STEP_KEY.FIELDS
                        ? !isValidMapper
                        : undefined),
            },
            {
                visible: !isEdit && !stepperProps.isFirstStep,
                label: t("general.back"),
                type: "secondary",
                onClick: stepperProps.prevStep,
            },
            {
                visible: true,
                label: t("general.cancel"),
                type: "text",
                onClick: onCancel,
                "data-test": "cancel-button",
            },
        ],
        [
            allValues?.fileInfo,
            allValues?.uploadedFile?.id,
            isEdit,
            isValidMapper,
            onCancel,
        ],
    );

    const steps = isEdit
        ? [
              {
                  key: ISV_MAPPER_STEP_KEY.GENERAL,
                  title: t("isv-connections.data-mapping.steps.general"),
                  Component: GeneralStep,
                  stepProps: { initialValues, isEdit },
              },
              {
                  key: ISV_MAPPER_STEP_KEY.ENTITIES,
                  title: t("isv-connections.data-mapping.steps.entities"),
                  Component: EntitiesStep,
                  stepProps: { initialValues, isEdit },
              },
              {
                  key: ISV_MAPPER_STEP_KEY.FIELDS,
                  title: t("isv-connections.data-mapping.steps.fields"),
                  Component: FieldsStep,
                  stepProps: { setIsValidMapper, isvMapperId },
              },
              {
                  key: ISV_MAPPER_STEP_KEY.SYNCHRONIZATION,
                  title: t(
                      "isv-connections.data-mapping.steps.synchronization",
                  ),
                  Component: SynchronizationStep,
              },
          ]
        : [
              {
                  key: ISV_MAPPER_STEP_KEY.GENERAL,
                  title: t("isv-connections.data-mapping.steps.general"),
                  Component: GeneralStep,
                  stepProps: { initialValues, isEdit },
              },
              {
                  key: ISV_MAPPER_STEP_KEY.ENTITIES,
                  title: t("isv-connections.data-mapping.steps.entities"),
                  Component: EntitiesStep,
                  stepProps: { initialValues, isEdit },
              },
              {
                  key: ISV_MAPPER_STEP_KEY.UPLOAD,
                  title: t("isv-connections.data-mapping.steps.upload"),
                  Component: UploadStep,
              },
              {
                  key: ISV_MAPPER_STEP_KEY.PARSING,
                  title: t("isv-connections.data-mapping.steps.parsing"),
                  Component: ParsingStep,
              },
              {
                  key: ISV_MAPPER_STEP_KEY.FIELDS,
                  title: t("isv-connections.data-mapping.steps.fields"),
                  Component: FieldsStep,
                  stepProps: { setIsValidMapper },
              },
              {
                  key: ISV_MAPPER_STEP_KEY.SYNCHRONIZATION,
                  title: t(
                      "isv-connections.data-mapping.steps.synchronization",
                  ),
                  Component: SynchronizationStep,
              },
          ];

    return (
        <LoadableRenderer
            loadable={isvConnectionLoadable}
            hasValue={isvConnection => (
                <StepForm
                    alert={head(alerts)}
                    headerSize={2}
                    actionButtons={actionButtons}
                    title={title}
                    reactiveInitialValues={initialValues}
                    steps={steps}
                    onCancel={onCancel}
                    onTabChange={key =>
                        setActionButtons(
                            isEdit && key === ISV_MAPPER_STEP_KEY.FIELDS
                                ? uploadFileActionButton
                                : [],
                        )
                    }
                    allStepsProps={{
                        accountId,
                        isvConnection,
                        vendorName: mapVendorToVendorName(isvConnection.vendor),
                    }}
                    getStepButtons={getStepButtons}
                    allValues={allValues}
                    setAllValues={setAllValues}
                    isTabVariant={isEdit}
                    onSubmit={handleSave} // Tab change -> save
                    onTabChangeSave={handleSave}
                    defaultActiveTab={tab ?? ISV_MAPPER_STEP_KEY.GENERAL}
                    // initialStepIndex={1}
                />
            )}
        />
    );
};

export default ISVDataMappingForm;
