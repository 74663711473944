import { Gap, H5, Input } from "@/components/DesignSystem";
import { T, t } from "@/translations";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";
import { InputType } from "../../Packages/PackageTableDefinitionPanel/constants";
import { TABS_VALUE_TYPES } from "../FieldCustomizationTabs.component";

const withValidation = input => ({
    ...input,
    isValid: !isEmpty(input.input),
});

export const GroovyTab = ({ input, onChange }) => {
    const onGroovyChange = e => {
        onChange(
            withValidation({
                input: e?.target?.value,
                inputType: InputType.GROOVY,
                tab: TABS_VALUE_TYPES.GROOVY,
            }),
        );
    };

    return (
        <div data-test="tab-groovy">
            <H5>
                <T id="groovy-editor.title.groovy-code" />
            </H5>
            <Gap size="small" />
            <Input.TextArea
                rows="10"
                data-test="groovy-editor-input"
                placeholder={t("groovy-editor.placeholder.enter-groovy")}
                value={input}
                onChange={onGroovyChange}
            />
        </div>
    );
};

GroovyTab.propTypes = {
    input: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};
