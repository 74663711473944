import { MAPPER_DIRECTION } from "@/components/ISVMapping/steps/General.step";
import { defaultVendors } from "@/components/Mappers/MapperTableWithCustomization/MapperTableWithCustomization";
import {
    hasValue,
    loadableFromMaybeValue,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { useMemo } from "react";
import { mapperMappingService } from "../../../../services/mapperMapping.service";
import { addComputableMapperProperties } from "../../form/mapper.utils";
import { useValidatedMapperState } from "./useValidatedMapperState.hook";
import uuid from "uuid/v4";

export const useMapperStateWithInitialization = ({
    vendors = defaultVendors,
    direction = MAPPER_DIRECTION.UPLOAD,
    entityType,
    entityName,
    initMapper,
    businessKey,
    inputOptionsLoadable,
    outputOptionsLoadable,
    tableData,
}) => {
    const optionsLoadables = useComposeLoadablesMemoized([
        inputOptionsLoadable,
        outputOptionsLoadable,
    ]);

    const maybeMapperWithDefault = useMemo(() => {
        if (!hasValue(optionsLoadables)) return undefined;
        const [inputOptions, outputOptions] = optionsLoadables.valueMaybe();

        const initMapperWithDefault = initMapper
            ? // mapper from somewhere (api, cache, ...)
              addComputableMapperProperties(
                  initMapper,
                  outputOptions,
                  tableData,
              )
            : // Create default mapper
              mapperMappingService.mapOptionsToMetadata(
                  inputOptions,
                  outputOptions,
                  direction,
              );
        return initMapperWithDefault;
    }, [outputOptionsLoadable, initMapper, tableData]);

    const { mapper, setMapper, errors } = useValidatedMapperState({
        vendors,
        entityType,
        entityName,
        businessKey,
        initMapper: maybeMapperWithDefault,
        inputOptionsMaybe: inputOptionsLoadable.valueMaybe(),
        outputOptionsMaybe: outputOptionsLoadable.valueMaybe(),
        tableData,
    });

    const loadableMapper = useMemo(
        () => loadableFromMaybeValue(mapper),
        [mapper],
    );

    return { mapper, setMapper, errors, loadableMapper };
};

export const useMapperStateWithInitializationFreetextOutput = ({
    vendors,
    entityType,
    entityName,
    initMapper,
    inputOptionsLoadable,
}) => {
    const inputOptionsMaybe = inputOptionsLoadable.valueMaybe();
    const maybeMapperWithDefault = useMemo(() => {
        if (!inputOptionsMaybe) return undefined;

        console.log(
            "[useMapperStateWithInitializationFreetextOutput.maybeMapperWithDefault]",
            { inputOptionsMaybe },
        );

        const initMapperWithDefault = initMapper
            ? // mapper from somewhere (api, cache, ...)
              initMapper.map(row => ({
                  id: uuid(),
                  ...row,
              }))
            : // Create default mapper
              mapperMappingService.mapInputsToMapper(inputOptionsMaybe);
        return initMapperWithDefault;
    }, [inputOptionsMaybe]);

    const { mapper, setMapper, errors } = useValidatedMapperState({
        vendors,
        entityType,
        entityName,
        businessKey: undefined, // should be OK?
        initMapper: maybeMapperWithDefault,
        inputOptionsMaybe: inputOptionsLoadable.valueMaybe(),
        tableData: undefined, // TODO: FORMULA, COMPOSED need tableData
    });

    const loadableMapper = useMemo(
        () => loadableFromMaybeValue(mapper),
        [mapper],
    );

    return { mapper, setMapper, errors, loadableMapper };
};
