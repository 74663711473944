import { LoadableRenderer } from "@/modules/loadable";
import { t } from "@/translations";
import { ReactComponent as Pen } from "@pricefx/unity-components/src/icons/unicons/pen.svg";
import { isNil } from "lodash";
import { map, pipe, reject, values } from "lodash/fp";
import React, { useCallback, useMemo } from "react";
import { Button, Gap, H3, SwitchAsync } from "../DesignSystem";
import { ActionButton } from "../DesignSystem/Table/components/ActionButton/ActionButton";
import { fieldTypes } from "../DesignSystem/Table/constants";
import { useDic } from "../Dic/useDic.hook";
import { useCurrentHandler } from "../hooks/useCurrentHandler.hook";
import { useVisibility } from "../hooks/useVisibility.hook";
import { TableLocalFiltered } from "../TableLocalFiltered";
import { addActionMenuRender } from "../TableWithPreferences/utils";
import { EditDefaultsModal } from "./EditDefaultsModal";
import { IaaSDefaults } from "./IaaSDefaults";
import {
    useDeleteOverrideMutation,
    useIaaSDefaultsQuery,
    useIaaSOverridesQuery,
    useToggleOverrideMutation,
} from "./loadables";
import { overridesDef } from "./OverrideForm";
import { useFullWidthLayout } from "../hooks/useContentLayout.hook";

const createColumns = () => [
    {
        type: fieldTypes.TEXT,
        label: t("iaas.overview.overrides.columns.status"),
        name: "enabled",
        render: (enabled, record) => {
            return (
                <>
                    <SwitchAsync
                        initialValue={enabled}
                        useMutation={useToggleOverrideMutation}
                        from={({ checked }) => ({
                            id: record.id,
                            enabled: checked,
                        })}
                        textOn={t("general.active")}
                        textOff={t("general.inactive")}
                        disabled={false}
                    />
                </>
            );
        },
    },
    {
        type: fieldTypes.TEXT,
        label: t("iaas.overview.overrides.columns.targetType"),
        name: "targetType",
    },
    {
        type: fieldTypes.TEXT,
        label: t("iaas.overview.overrides.columns.target"),
        name: "targetLabel",
    },
];

const ExpandedOverrides = ({ record }) => {
    return (
        <pre>
            {pipe(
                values,
                reject(pipe(({ name }) => record?.[name], isNil)),
                map(({ name, label }) => `${label}: ${record?.[name]}\n`),
            )(overridesDef)}
        </pre>
    );
};

export const AdminIaaSPage = () => {
    useFullWidthLayout();
    const { locationRouterService, administrationAppLocations } = useDic();
    const defaultsQuery = useIaaSDefaultsQuery();
    const overridesQuery = useIaaSOverridesQuery();
    const editDefaultsVisibility = useVisibility(false);
    const onCreate = useCurrentHandler(() =>
        locationRouterService.navigate(
            administrationAppLocations.newIaaSOverrideLocation,
        ),
    );
    const onEdit = useCurrentHandler(record =>
        locationRouterService.navigate(
            administrationAppLocations.editIaaSOverrideLocation,
            { id: record.id },
        ),
    );
    const deleteMutation = useDeleteOverrideMutation({
        afterSave: overridesQuery.reload,
    });
    const actionMenu = useCallback(
        record => (
            <ActionButton
                record={record}
                items={[
                    {
                        title: t("general.tooltip.edit"),
                        onClick: onEdit,
                    },
                    {
                        title: t("general.delete"),
                        confirmMessage: t(
                            "general.do-you-really-want-to-delete-name",
                            { name: record.targetType },
                        ),
                        onConfirm: deleteMutation.mutate,
                        color: "red",
                    },
                ]}
            />
        ),
        [deleteMutation.mutate, onEdit],
    );
    const overridesColumns = useMemo(
        () => addActionMenuRender(createColumns({}), actionMenu),
        [actionMenu],
    );

    return (
        <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <H3>{t("iaas.overview.defaults.title")}</H3>
                <Button
                    type="secondary"
                    size="small"
                    label={t("general.edit")}
                    icon={Pen}
                    loading={!defaultsQuery.loadable.valueMaybe()} // There is some weird issue when modal is opened before defaults loaded
                    onClick={() => editDefaultsVisibility.show()}
                />
            </div>
            <IaaSDefaults defaultsQuery={defaultsQuery} />
            <Gap size="x-large" />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <H3>{t("iaas.overview.overrides.title")}</H3>
                <Button
                    type="primary"
                    label={t("iaas.overview.overrides.buttons.create-override")}
                    onClick={onCreate}
                />
            </div>
            <Gap size="small" />
            <LoadableRenderer
                loadable={overridesQuery.loadable}
                hasValue={dataSource => (
                    <TableLocalFiltered
                        rowKey="id"
                        columns={overridesColumns}
                        dataSource={dataSource}
                        pagination={false}
                        padding={false}
                        hasColumnAutofit
                        hasColumnResizing
                        pmExpandable={{
                            expandedRowHeight: 250,
                            rowExpandable: record => !!record,
                            expandedRowRender: record => (
                                <ExpandedOverrides record={record} />
                            ),
                        }}
                    />
                )}
            />
            <EditDefaultsModal
                visible={editDefaultsVisibility.visible}
                defaultsQuery={defaultsQuery}
                afterSave={() => {
                    editDefaultsVisibility.hide();
                    defaultsQuery.reload();
                }}
                onClose={editDefaultsVisibility.hide}
            />
        </>
    );
};
