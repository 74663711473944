import { useDic } from "@/components/Dic/useDic.hook";
import SSEType from "@/components/ServerSideEvents/types";
import useSSE from "@/components/ServerSideEvents/useSSE.hook";
import { AsyncTasksContext } from "@/modules/async-tasks/AsyncTasksContext";
import {
    pendingPromise,
    useMapLoadableMemoized,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { getData } from "@/services/utils";
import { filter, identity } from "lodash/fp";
import { useCallback, useContext, useMemo } from "react";

const update = (job, jobs) => {
    const index = jobs.findIndex(j => j.jobId === job.jobId);

    if (index > -1) {
        const newJobs = [...jobs];
        newJobs[index] = {
            // ...newJobs[index],
            ...job,
        };
        return newJobs;
    } else {
        const newJobFix = {
            ...job,
            createdAt: job.createdAt ?? new Date().toISOString(), // TODO: rm when CREATED task will have it
        };
        return [newJobFix, ...jobs];
    }
};

const log = (msg, ...args) =>
    console.log(
        "%c[useAsyncTasksLiveQuery.SSE] " + msg,
        "color:deeppink;",
        ...args,
    );

export const useAsyncTasksLiveQuery = ({ canFetch }) => {
    const { axiosService } = useDic();
    const query = useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : axiosService.get(`/api/async-jobs`).then(getData),
        [axiosService, canFetch],
    );

    useSSE({
        type: SSEType.TASK,
        eventHandler: task => {
            log(task.status + " task received: ", task);

            query.setCallState(callState => {
                const [state, contents] = callState;
                if (state === "hasValue")
                    return [state, update(task, contents)];
                if (state === "hasError") return ["hasValue", update(task, [])];
                if (state === "loading")
                    log("missed update, not loaded yet", task);

                return callState;
            });
        },
    });

    return query;
};

export const useSomeAsyncTasksLiveQuery = ({
    filterPredicate = identity,
    map = identity,
}) => {
    const { asyncTasksLiveQuery } = useContext(AsyncTasksContext);

    const resultLoadable = useMapLoadableMemoized(
        asyncTasksLiveQuery.loadable,
        useCallback(
            list => map(filter(filterPredicate, list)),
            [filterPredicate, map],
        ),
    );

    console.log("%c[useSomeAsyncTasksLiveQuery]", "color:darkmagenta;", {
        allTasksMaybe: asyncTasksLiveQuery.loadable.valueMaybe(),
        resultMaybe: resultLoadable.valueMaybe(),
    });

    return useMemo(
        () => ({
            reload: asyncTasksLiveQuery.reload,
            loadable: resultLoadable,
        }),
        [asyncTasksLiveQuery.reload, resultLoadable],
    );
};

export const useCancelTaskMutation = ({ jobId }) => {
    const { axiosService } = useDic();

    return useMutationLoadableWithNotification(
        async () =>
            axiosService.delete(`/api/async-jobs/${jobId}`).then(getData),
        [axiosService, jobId],
        "partition.job-task-tracking.list.status.cancelled",
    );
};
