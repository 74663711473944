import { Button, Col, Forms, Gap, H3, Row } from "@/components/DesignSystem";
import { BackButton } from "@/components/Packages/PackageTableDefinitionPanel/components/TableComponents/BackButton.component";
import {
    loadableFromMaybeValue,
    LoadableRenderer,
    useComposeLoadablesMemoized,
} from "@/modules/loadable";
import { logger } from "@/modules/logger";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React from "react";
import ConditionalAttributeErrorAlert from "../../Error/ConditionalAttributeErrorAlert";
import {
    CONVERT_EMPTY_VALUES,
    CONVERT_EMPTY_VALUES_OPTIONS,
} from "../../Mappers/MapperTableWithCustomization/MapperGridForm/MapperGridForm";
import {
    MapperTableWithCustomization,
    useInputOptionsWithTypes,
} from "../../Mappers/MapperTableWithCustomization/MapperTableWithCustomization";
import { useMapperStateWithInitialization } from "../../Mappers/MapperTableWithCustomization/hooks/useMapperStateWithInitialization.hook";
import { useOutputOptions } from "../../Mappers/MapperTableWithCustomization/loadableResources";
import { BusinessKeyLengthTable } from "./BusinessKeyLengthTable";
import { useBusinessKeys } from "./hooks/useBusinessKeys.hook";

const { useForm, SubmitButton, Fields } = Forms;

export const DataUploadFieldMapping = ({
    afterSubmit,
    entityName,
    entityType,
    partitionId,
    error,
    onCancel,
    readOnly,
    tableData,
    initMapper,
    apiErrors,
    parserConfig,
    initialConvertEmptyStringToNull = false,
    initialBusinessKey,
    onFileInfo,
    onBack,
}) => {
    const { inputOptionsWithTypes, tableExampleDataMap } =
        useInputOptionsWithTypes({ tableExampleData: tableData });
    const outputOptionsResource = useOutputOptions({
        sourceType: "partitions",
        sourceId: partitionId,
        entityType,
        entityName,
        objectTypeErrors: undefined, // Validate previously selected combination?
    });
    const businessKeys = useBusinessKeys({
        entityName,
        entityType,
        partitionId,
        initialBusinessKey,
    });
    const { mapper, setMapper, errors, loadableMapper } =
        useMapperStateWithInitialization({
            entityType,
            entityName,
            businessKey: businessKeys.formValues,
            inputOptionsLoadable: loadableFromMaybeValue(inputOptionsWithTypes),
            outputOptionsLoadable: outputOptionsResource.loadable,
            initMapper,
            tableData,
        });

    const onNext = ({ values: { convertEmptyStringToNull } }) => {
        if (errors.valid) {
            const values = {
                config: { businessKey: { ...businessKeys.formValues } },
                definition: mapper,
                entityType,
                entityName,
                usePricingParameterName: true,
                convertEmptyStringToNull:
                    convertEmptyStringToNull === CONVERT_EMPTY_VALUES.NULL,
            };
            logger.debug({
                logGroupKey: ["DATALOAD", "DataUploadFieldMapping", "onNext"],
                color: "orchid",
                data: { values },
            });
            return afterSubmit(values);
        }
    };
    // prevents first empty mapper table rendering lag
    const loadables = useComposeLoadablesMemoized([
        outputOptionsResource.loadable,
        loadableMapper,
    ]);

    const form = useForm({
        onSubmit: onNext,
    });
    const { formId, handleSubmit } = form;

    logger.debug({
        logGroupKey: ["DATALOAD", "DataUploadFieldMapping", "rndr"],
        color: "orchid",
        data: { mapper, errors },
    });

    return (
        <>
            <H3>{t("package-data-upload.header")}</H3>
            <ConditionalAttributeErrorAlert error={error} />
            <Gap size="small" />
            {businessKeys.showBusinessKeys && (
                <Row gutter={16}>
                    <Col sm={12}>
                        <BusinessKeyLengthTable {...businessKeys} />
                    </Col>
                </Row>
            )}
            <Gap size="small" />
            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                <LoadableRenderer
                    loadable={loadables}
                    loading={() => <LoadableRenderer.Spinner />}
                    hasValue={([outputOptions, mapper]) => (
                        <MapperTableWithCustomization
                            apiErrors={apiErrors}
                            entityName={entityName}
                            entityType={entityType}
                            errors={errors}
                            inputOptionsWithTypes={inputOptionsWithTypes}
                            mapper={mapper}
                            onChange={setMapper}
                            onColumnTypeChange={(name, type) => {
                                onFileInfo(fileInfo =>
                                    !fileInfo
                                        ? fileInfo
                                        : {
                                              ...fileInfo,
                                              columns: fileInfo.columns.map(c =>
                                                  c?.name !== name
                                                      ? c
                                                      : { ...c, type },
                                              ),
                                          },
                                );
                            }}
                            outputOptions={outputOptions}
                            parserConfig={parserConfig}
                            partitionId={partitionId}
                            readOnly={readOnly}
                            tableExampleData={tableData}
                            tableExampleDataMap={tableExampleDataMap}
                            withConverterWarning={false}
                            form={form}
                        />
                    )}
                />

                <Fields.Radio
                    name="convertEmptyStringToNull"
                    label={t("mapper-form.form.send-empty-value-as")}
                    initialValue={
                        initialConvertEmptyStringToNull
                            ? CONVERT_EMPTY_VALUES.NULL
                            : CONVERT_EMPTY_VALUES.EMPTY_STRING
                    }
                    options={CONVERT_EMPTY_VALUES_OPTIONS}
                />
                <Gap size="large" />
                <SubmitButton
                    htmlType="submit"
                    type="primary"
                    disabled={!errors.valid || !businessKeys.valid}
                    label={t("partition-send-data.button.continue")}
                    data-test="submit"
                />
                <BackButton onBack={onBack} disabled={!businessKeys.valid} />
                <Button
                    type="text"
                    onClick={onCancel}
                    label={t("partition-send-data.button.cancel")}
                    data-test="cancel"
                />
            </Forms.Form>
        </>
    );
};

DataUploadFieldMapping.propTypes = {
    afterSubmit: PropTypes.func.isRequired,
    initMapper: PropTypes.array,
    entityName: PropTypes.string,
    entityType: PropTypes.string.isRequired,
    partitionId: PropTypes.number.isRequired,
    error: PropTypes.object,
    onCancel: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    tableData: PropTypes.object.isRequired,
    parserConfig: PropTypes.object,
    initialBusinessKey: PropTypes.object,
    apiErrors: PropTypes.string, // ???
    initialConvertEmptyStringToNull: PropTypes.bool,
    onNext: PropTypes.func,
};
