import { fieldTypes } from "../DesignSystem/Table/constants";

export const payloadFieldsColumns = [
    {
        name: "name",
        label: "Name",
        type: fieldTypes.TEXT,
        fieldProps: {
            required: true,
            unique: true,
        },
    },
    {
        name: "value",
        label: "Value",
        type: fieldTypes.TEXT,
        fieldProps: {
            required: true,
        },
    },
];
