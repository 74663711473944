import React from "react";
import { Forms } from "../DesignSystem";
import { payloadFieldsColumns } from "./payloadFieldsColumns";
import { t } from "@/translations";

export const PayloadHeadersField = ({
    name,
    title = t("event-scheduler.group.payload.headers"),
    ...rest
}) => {
    return (
        <Forms.Fields.EntityEditorTable
            name={name}
            columns={payloadFieldsColumns}
            title={title}
            addButtonProps={{
                label: t("event-scheduler.form.headers.add-header"),
            }}
            addModalProps={{
                title: t("event-scheduler.form.headers.add-header"),
                submitButtonLabel: t("general.add"),
            }}
            editModalProps={{
                title: t("event-scheduler.form.headers.edit-header"),
                submitButtonLabel: t("general.save"),
            }}
            confirmRemove={{
                title: "Delete Header",
                message: "Do you want to delete this Header?",
            }}
            {...rest}
            // rowHeight={36}
            // width="default"
            // inputWidth="default"
        />
    );
};
