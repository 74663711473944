import { Form, Label, Select } from "@/components/DesignSystem";
import { LoadablePropType } from "@/modules/loadable";
import { t } from "@/translations";
import { identity } from "lodash/fp";
import { PropTypes } from "prop-types";
import React, { useRef } from "react";

const emptyArr = [];
export const getLoadableSelectProps = (loadable, mapperFn = identity) => {
    switch (loadable.state) {
        case "loading":
            return {
                options: emptyArr,
                loading: true,
                disabled: true,
                // TODO: investigate why unity select doesn't display loading indicator and remove:
                placeholder: "Loading...",
            };
        case "hasValue":
            return {
                options: mapperFn(loadable.contents ?? emptyArr),
            };
        case "hasError":
            return {
                options: emptyArr,
                disabled: true,
                placeholder: "Error",
            };
    }
};

const EntityNameSelector = ({
    entityName,
    entityNamesLoadable,
    onChange,
    error,
    style = { width: "500px" },
}) => {
    const loadableSelectProps = getLoadableSelectProps(entityNamesLoadable);
    const ref = useRef(document.body);

    return (
        <div ref={ref} data-test="entity-name">
            <Label
                label={t("table-metadata-form.label.entity-name")}
                required
            />
            <Form.Item validateStatus={error ? "error" : undefined}>
                <Select
                    showSearch
                    name="entityName"
                    style={style}
                    placeholder={t(
                        "table-metadata-form.placeholder.entity-name",
                    )}
                    onChange={onChange}
                    value={entityName}
                    getPopupContainer={() => ref.current}
                    {...loadableSelectProps}
                />
            </Form.Item>
        </div>
    );
};

EntityNameSelector.propTypes = {
    entityName: PropTypes.string,
    error: PropTypes.string,
    entityNamesLoadable: LoadablePropType(),
    onChange: PropTypes.func.isRequired,
};

export default EntityNameSelector;
