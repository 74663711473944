import React from "react";
import { Forms } from "../DesignSystem";
import { payloadFieldsColumns } from "./payloadFieldsColumns";
import { t } from "@/translations";

export const PayloadPropertiesField = ({
    name,
    title = t("event-scheduler.group.payload.properties"),
    ...rest
}) => {
    return (
        <Forms.Fields.EntityEditorTable
            name={name}
            columns={payloadFieldsColumns}
            title={title}
            addButtonProps={{
                label: t("event-scheduler.form.properties.add-property"),
            }}
            addModalProps={{
                title: t("event-scheduler.form.properties.add-property"),
                submitButtonLabel: t("general.add"),
            }}
            editModalProps={{
                title: t("event-scheduler.form.properties.edit-property"),
                submitButtonLabel: t("general.save"),
            }}
            confirmRemove={{
                title: "Delete Property",
                message: "Do you want to delete this Property?",
            }}
            {...rest}
            // rowHeight={36}
        />
    );
};
