import { useDic } from "@/components/Dic/useDic.hook";
import { useCurrentHandler } from "@/components/hooks/useCurrentHandler.hook";
import {
    crudSuccess,
    pendingPromise,
    useMutationLoadable,
    useMutationLoadableWithNotification,
    useQueryLoadable,
} from "@/modules/loadable";
import { useReloadUserPermissions } from "@/security/hooks/useReloadUserPermissions.hook";
import { t } from "@/translations";
import { getErrorMessage } from "@/utils/state/error.utils";
import get from "lodash/fp/get";

export const useUserResource = ({
    userId,
    canFetch,
    map = ({ data }) => data,
}) => {
    const { axiosService } = useDic();
    const currentMap = useCurrentHandler(map);
    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : axiosService
                      .get(`/api/admin/users/${userId}`)
                      .then(currentMap),
        [userId, canFetch, currentMap, axiosService],
    );
};

export const usePostUserResource = afterSave => {
    const { axiosService } = useDic();
    return useMutationLoadableWithNotification(
        async user => {
            const { data } = await axiosService.post(
                `/api/admin/users${user.id ? `/${user.id}` : ""}`,
                user,
            );
            afterSave(data);
            return { ...user, ...data, _created: !user.id }; // api returns 201 on created
        },
        [afterSave, axiosService],
        crudSuccess("User", "username", ({ _created }) =>
            _created ? "C" : "U",
        ),
    );
};

export const useUserAllowedGroupsResource = ({ userId, canFetch }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(
        async () =>
            !canFetch
                ? pendingPromise()
                : axiosService
                      .get(`/api/admin/users/${userId}/allowed-groups`)
                      .then(({ data }) => data),
        [userId, canFetch, axiosService],
    );
};

export const useAddUserToGroupsResource = ({ userId, afterSuccess }) => {
    const { axiosService } = useDic();
    const reloadUserPermissions = useReloadUserPermissions();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async groupNames => {
            const result = await axiosService.post(
                `/api/admin/users/${userId}/groups`,
                {
                    groupNames: groupNames,
                },
            );
            reloadUserPermissions();
            currentAfterSuccess?.(result);
            return result;
        },
        [userId, axiosService, reloadUserPermissions, currentAfterSuccess],
        "edit-user.user-groups-added",
    );
};

export const useRemoveUserFromGroupResource = ({ userId, afterSuccess }) => {
    const { axiosService } = useDic();
    const reloadUserPermissions = useReloadUserPermissions();
    const currentAfterSuccess = useCurrentHandler(afterSuccess);

    return useMutationLoadableWithNotification(
        async groupName => {
            const { data } = await axiosService.delete(
                `/api/admin/users/${userId}/groups/${groupName}`,
            );
            reloadUserPermissions();
            currentAfterSuccess?.(data);
            return data;
        },
        [userId, currentAfterSuccess, axiosService, reloadUserPermissions],
        "edit-user.user-removed",
    );
};

export const useResetPasswordResource = ({ afterSuccess } = {}) => {
    const { messageService, passwordsService } = useDic();
    return useMutationLoadable(
        async ({ username }) => {
            try {
                await passwordsService.resetUserPassword(username);
                messageService.info({
                    content: t("user-detail.password.successful"),
                });
                afterSuccess?.();
            } catch (e) {
                messageService.error({
                    content: getErrorMessage(e.response.data),
                });
                throw e;
            }
        },
        [passwordsService, messageService, afterSuccess],
    );
};

export const useDeleteUserResource = ({ afterSuccess } = {}) => {
    const { axiosService } = useDic();
    return useMutationLoadableWithNotification(
        async ({ id, username }) => {
            const { data } = await axiosService.delete(
                `/api/admin/users/${id}`,
            );
            afterSuccess?.(data);
            return { username, ...data };
        },
        [axiosService, afterSuccess],
        crudSuccess("User", "username", "D"),
    );
};

export const useRemoveAllPermissionsMutation = ({ afterSuccess } = {}) => {
    const { axiosService } = useDic();
    return useMutationLoadableWithNotification(
        async ({ id, username }) => {
            const { data } = await axiosService.delete(
                `/api/admin/users/${id}/permissions`,
            );
            afterSuccess?.(data);
            return { username, ...data };
        },
        [axiosService, afterSuccess],
        crudSuccess("User permissions of", "username", "D"),
    );
};

export const useUserAtlassianGroupsResource = ({
    atlassianAccountId,
    canFetch,
}) => {
    const { axiosService } = useDic();
    return useQueryLoadable(async () => {
        if (!canFetch) return pendingPromise();
        if (!atlassianAccountId)
            throw new Error(
                "Cannot proceed, user does not have atlassianAccountId",
            );

        return axiosService
            .get(`/api/admin/atlassian/users/${atlassianAccountId}/groups`)
            .then(({ data }) => data)
            .catch(res => {
                throw get("response.data", res);
            });
    }, [atlassianAccountId, canFetch, axiosService]);
};

export const useDeleteUserFromAtlassianResource = ({ afterSuccess } = {}) => {
    const { axiosService } = useDic();
    return useMutationLoadableWithNotification(
        async ({ username }) => {
            const { data } = await axiosService.delete(
                `/api/admin/atlassian/users/${username}`,
            );
            afterSuccess?.(data);
            return { username, ...data };
        },
        [axiosService, afterSuccess],
        ({ username }) => `User ${username} deleted from atlassian`,
    );
};

export const useUserSsoUsernameResource = ({ userId, canFetch }) => {
    const { axiosService } = useDic();
    return useQueryLoadable(async () => {
        if (!canFetch) return pendingPromise();
        if (!userId) throw new Error("Cannot proceed, no userId provided");

        return axiosService
            .get(`/api/admin/users/${userId}/sso`)
            .then(({ data }) => data)
            .catch(res => {
                throw get("response.data", res);
            });
    }, [userId, canFetch, axiosService]);
};

export const usePostUserSsoUsernameResource = ({ afterSave }) => {
    const { axiosService } = useDic();
    return useMutationLoadableWithNotification(
        async ({ userId, ssoLogin }) => {
            const res = await axiosService.post(
                `/api/admin/users/${userId}/sso`,
                {
                    ssoLogin,
                },
            );
            afterSave();
            return res;
        },
        [afterSave, axiosService],
        crudSuccess(t("user-detail.modal.sso-username.title")),
    );
};
