import { FieldCustomizationModal } from "@/components/CalculatedFieldTable/FieldCustomization.modal";
import { Button, ButtonGroup, Forms, Gap } from "@/components/DesignSystem";
import { serialize } from "@/components/DesignSystem/Forms/fieldsArray";
import { useMapperGridFormState } from "@/components/Mappers/MapperTableWithCustomization/MapperGridForm/hooks/useMapperGridFormState.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { t } from "@/translations";
import isNumber from "lodash/fp/isNumber";
import isString from "lodash/fp/isString";
import pickBy from "lodash/fp/pickBy";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { TABS_VALUE_TYPES } from "../../../CalculatedFieldTable/FieldCustomizationTabs.component";
import ConditionalErrorAlert from "../../../Error/ConditionalErrorAlert";
import {
    InputType,
    ParsedType,
    ValueType,
} from "../../../Packages/PackageTableDefinitionPanel/constants";
import { createFlatOptionsByValue } from "../hooks/useConnectionsMapper.hook";
import { MapperRow } from "./MapperRow";

export const LAYOUT = [{}, {}, { width: 32 }];

const stringOrNumber = value => isString(value) || isNumber(value);
export const CONVERT_EMPTY_VALUES = {
    EMPTY_STRING: "EMPTY_STRING",
    NULL: "NULL",
};

export const CONVERT_EMPTY_VALUES_OPTIONS = [
    {
        value: CONVERT_EMPTY_VALUES.EMPTY_STRING,
        label: t("mapper.convert-empty.empty"),
    },
    {
        value: CONVERT_EMPTY_VALUES.NULL,
        label: t("mapper.convert-empty.null"),
    },
];

export const hideTabs = [TABS_VALUE_TYPES.FORMULA, TABS_VALUE_TYPES.COMPOSED];

export const createMapper = ({ values, rowIds, valuesByRowId }) =>
    rowIds.map(id => ({
        id,
        input: values[serialize(id, "input")],
        inputType: valuesByRowId[id]?.inputType || InputType.BODY,
        output: values[serialize(id, "output")],
        ...pickBy(stringOrNumber, valuesByRowId[id]),
    }));

export const MapperGridForm = ({
    inputOptions,
    outputOptions,
    readOnly,
    withConverterWarning,
    parserConfig,
    mapper,
    submitButtonText = t("general.save"),
    onSubmit,
    testMapperResource,
    testMapperDisabled,
}) => {
    const {
        rowIds,
        addRow,
        deleteRow,
        initialValuesByRowId,
        valuesByRowId, // [rowId]: { inputTypes, converters }
        setInputType,
        setConverter,
    } = useMapperGridFormState(mapper);
    const onFormSubmit = useCallback(
        ({ values }) => {
            const mapper = createMapper({ values, rowIds, valuesByRowId });
            const convertEmptyStringToNull =
                values.convertEmptyValues === CONVERT_EMPTY_VALUES.NULL;
            onSubmit(mapper, convertEmptyStringToNull);
        },
        [rowIds, onSubmit, valuesByRowId],
    );
    const { formId, handleSubmit, setValues, setAllToTouched, getBag } =
        Forms.useForm({
            onSubmit: onFormSubmit,
        });
    useEffect(() => {
        window.setTimeout(() => setAllToTouched(), 0); // Show validations - needs to be set after all fields mounted :-/
    }, [setAllToTouched]);
    const [customizeField, setCustomizeField] = useState();
    const fieldCustomizationModal = useVisibility();
    const showFieldCustomization = row => {
        setCustomizeField(row);
        fieldCustomizationModal.show();
    };

    const onCustomizeField = ({
        input,
        inputType,
        converterExpression,
        converterExpressionReadOnly,
    }) => {
        if (converterExpression !== customizeField.converterExpression) {
            setConverter(customizeField.id, {
                value: converterExpression,
                readOnly: converterExpressionReadOnly,
            });
        }
        setValues({
            [serialize(customizeField.id, "input")]: input,
        });
        setInputType(customizeField.id, inputType);

        fieldCustomizationModal.hide();
        setCustomizeField(undefined);
    };

    const flatOutputOptionsByValue = useMemo(
        () => createFlatOptionsByValue(outputOptions),
        [outputOptions],
    );
    const generalErrors = useMemo(
        () =>
            !rowIds?.length
                ? t("data-upload.mapper-table.validation.mapper.empty")
                : [],
        [rowIds],
    );

    const handleTestMapper = useCallback(async () => {
        const { values } = await getBag();
        const mapper = createMapper({ values, rowIds, valuesByRowId });
        testMapperResource.mutate(mapper);
    }, [testMapperResource, rowIds, valuesByRowId, getBag]);

    return (
        <div data-test="multilevel-mapper-table">
            {[].concat(generalErrors ?? []).map((error, index) => (
                <React.Fragment key={error?.message ?? JSON.stringify(error)}>
                    <ConditionalErrorAlert key={index} error={error} />
                    <Gap size="small" />
                </React.Fragment>
            ))}
            <Gap />
            <Forms.Form formId={formId} onSubmit={handleSubmit}>
                <Forms.FieldGrid layout={LAYOUT}>
                    {rowIds.map(rowId => (
                        <MapperRow
                            key={rowId}
                            formId={formId}
                            rowId={rowId}
                            initialInput={initialValuesByRowId[rowId]?.input}
                            initialOutput={initialValuesByRowId[rowId]?.output}
                            inputType={valuesByRowId[rowId]?.inputType}
                            converterExpression={
                                valuesByRowId[rowId]?.converterExpression
                            }
                            converterExpressionReadOnly={
                                valuesByRowId[rowId]
                                    ?.converterExpressionReadOnly
                            }
                            setConverter={setConverter}
                            inputOptions={inputOptions}
                            outputOptions={outputOptions}
                            flatOutputOptionsByValue={flatOutputOptionsByValue}
                            deleteRow={deleteRow}
                            readOnly={readOnly}
                            onOpenEditor={showFieldCustomization}
                            withConverterWarning={withConverterWarning}
                            parserConfig={parserConfig}
                        />
                    ))}
                </Forms.FieldGrid>
                {!readOnly && (
                    <Button
                        onClick={addRow}
                        style={{ width: "100%" }}
                        label={"+ " + t("data-upload.mapper.add.field")}
                    />
                )}
                <Forms.Fields.Radio
                    name="convertEmptyValues"
                    label={t("mapper-form.form.send-empty-value-as")}
                    initialValue={CONVERT_EMPTY_VALUES.EMPTY_STRING}
                    options={CONVERT_EMPTY_VALUES_OPTIONS}
                    validator={Forms.pmValidators.isRequired}
                />
                <ButtonGroup>
                    <Forms.SubmitButton
                        formId={formId}
                        disabled={!!generalErrors.length}
                    >
                        <Button
                            type="primary"
                            htmlType="submit"
                            label={submitButtonText}
                        />
                    </Forms.SubmitButton>
                    {testMapperResource && (
                        <Button
                            label="Test mapper"
                            onClick={handleTestMapper}
                            disabled={testMapperDisabled}
                        />
                    )}
                </ButtonGroup>
            </Forms.Form>
            <FieldCustomizationModal
                visible={fieldCustomizationModal.visible}
                onSave={onCustomizeField}
                onCancel={fieldCustomizationModal.hide}
                hideTabs={hideTabs}
                inputOptions={inputOptions}
                initialValues={customizeField}
                withConverterExpression
            />
        </div>
    );
};

MapperGridForm.propTypes = {
    inputOptions: PropTypes.array.isRequired,
    outputOptions: PropTypes.array.isRequired,
    readOnly: PropTypes.bool,
    withConverterWarning: PropTypes.bool,
    parserConfig: PropTypes.object,
    mapper: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string.isRequired,
                PropTypes.number.isRequired,
            ]).isRequired,
            input: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
            // inputType: PropTypes.oneOf(inputTypeOptions),
            // {value, valueType} used for calculated fields - represents UI value not accepted by API (except cache?)
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
            valueType: PropTypes.oneOf(Object.values(ValueType)),
            output: PropTypes.string,
            outputType: PropTypes.oneOf([...Object.values(ParsedType), ""]),
        }).isRequired,
    ).isRequired,
    submitButtonText: PropTypes.node,
    onSubmit: PropTypes.func.isRequired,
};
