import useSSE from "@/components/ServerSideEvents/useSSE.hook";
import { useParentState } from "@/components/hooks/useParentState.hook";

const useLoadableWithSSE = ({ loadable, type, predicate, parser }) => {
    const valueMaybe = loadable.valueMaybe();
    const [value, setValue] = useParentState(valueMaybe);

    useSSE({
        type: type,
        eventHandler: updatedState => {
            if (predicate(updatedState)) {
                setValue(updatedState);
            }
        },
        parser,
    });

    return value;
};

export default useLoadableWithSSE;
