import { LoadableRenderer } from "@/modules/loadable";
import React from "react";
import { useAdministrationAppParams } from "../../apps/administrationApp/hooks/useAdministrationAppParams";
import { useDic } from "../Dic/useDic.hook";
import { useCurrentHandler } from "../hooks/useCurrentHandler.hook";
import { useIaaSOverrideQuery, useUpsertOverrideMutation } from "./loadables";
import { OverrideForm } from "./OverrideForm";

export const EditIaaSPage = () => {
    const { id } = useAdministrationAppParams();
    const { locationRouterService, administrationAppLocations } = useDic();
    const query = useIaaSOverrideQuery({ id });
    const onCancel = useCurrentHandler(() =>
        locationRouterService.navigate(administrationAppLocations.IaaSLocation),
    );
    const mutation = useUpsertOverrideMutation({ afterSave: onCancel });

    return (
        <LoadableRenderer
            loadable={query.loadable}
            hasValue={initialValues => (
                <OverrideForm
                    initialValues={initialValues}
                    onSubmit={mutation.mutate}
                    onCancel={onCancel}
                />
            )}
        />
    );
};
