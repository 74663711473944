import { Tabs } from "@/components/DesignSystem";
import { HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT } from "@/components/DesignSystem/Table/constants";
import { useDic } from "@/components/Dic/useDic.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import {
    isAuthorizedByPermissionOr,
    SecurityContext,
} from "@/security/authorization";
import {
    ACCOUNT_DEPLOYMENT_LOG_PERMISSIONS,
    ACCOUNT_EVENTS_JOBS_PERMISSIONS,
    ACCOUNT_OVERVIEW_PERMISSIONS,
} from "@/security/permission.utils";
import { t } from "@/translations";
import PropTypes from "prop-types";
import React, { useContext, useMemo, useState } from "react";
import { EntityType } from "../../constants/CommonConstats";
import { PARTITION_DASHBOARD } from "../../constants/sessionState.constants";
import ElasticSearchPanel from "../Integrations/Dashboard/Tabs/ElasticSearchPanel";
import PartitionEventAdminList from "../PartitionEventAdmin/PartitionEventAdminList";
import PartitionJobTrackingList from "../PartitionJobTracking/PartitionJobTrackingList";
import { PartitionDeploymentLog } from "./PartitionDeploymentLog.component";
import { UploadQueue } from "@/components/UploadQueue/UploadQueue";

const BODY_HEADER_HEIGHT = 80;

const PartitionDashboard = ({ partitionId, accountId }) => {
    const securityContext = useContext(SecurityContext);
    useFullWidthTableLayout();

    const [activeKey, setActiveKey] = useState("log-history");

    const canReadActivityLog = useMemo(
        () =>
            isAuthorizedByPermissionOr(
                ACCOUNT_OVERVIEW_PERMISSIONS,
                securityContext.permissions,
            ),
        [securityContext.permissions],
    );

    const canReadDeploymentLog = useMemo(
        () =>
            isAuthorizedByPermissionOr(
                ACCOUNT_DEPLOYMENT_LOG_PERMISSIONS,
                securityContext.permissions,
            ),
        [securityContext.permissions],
    );

    const canReadAdminEventsAndTrackingRecords = useMemo(
        () =>
            isAuthorizedByPermissionOr(
                ACCOUNT_EVENTS_JOBS_PERMISSIONS,
                securityContext.permissions,
            ),
        [securityContext.permissions],
    );

    const tabs = [];

    const { partitionService } = useDic();

    const fetchData = params =>
        partitionService.getPartitionLogs(accountId, partitionId, params);

    const exportLogs = params =>
        partitionService.getPartitionLogsExport(accountId, partitionId, params);

    if (canReadActivityLog) {
        tabs.push(
            <Tabs.TabPane
                tab={
                    <div id="tab.log-history">
                        {t("partition.log-history.tab")}
                    </div>
                }
                key="log-history"
                id="tab.log-history"
            >
                <ElasticSearchPanel
                    fetchData={fetchData}
                    exportLogMessages={exportLogs}
                    accountId={accountId}
                    entityId={partitionId}
                    entityType={EntityType.PARTITION}
                    sessionStateName={PARTITION_DASHBOARD}
                    restHeight={
                        HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT +
                        BODY_HEADER_HEIGHT
                    }
                />
            </Tabs.TabPane>,
        );
    }

    if (canReadAdminEventsAndTrackingRecords) {
        tabs.push(
            <Tabs.TabPane
                tab={
                    <div id="tab.job-task-tracking">
                        {t("partition.job-task-tracking.tab")}
                    </div>
                }
                key="job-task-tracking"
                id="tab.job-task-tracking"
            >
                <PartitionJobTrackingList
                    accountId={accountId}
                    partitionId={partitionId}
                    restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
                />
            </Tabs.TabPane>,
        );
        tabs.push(
            <Tabs.TabPane
                tab={
                    <div id="tab.partition.event-admin">
                        {t("partition.event-admin.tab")}
                    </div>
                }
                key="partition.event-admin"
                id="tab.partition.event-admin"
            >
                <PartitionEventAdminList
                    accountId={accountId}
                    partitionId={partitionId}
                    restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
                />
            </Tabs.TabPane>,
        );
    }
    if (canReadDeploymentLog) {
        tabs.push(
            <Tabs.TabPane
                tab={
                    <div id="tab.accelerators">
                        {t("partition.accelerators.tab")}
                    </div>
                }
                key="accelerators"
                id="tab.accelerators"
            >
                <PartitionDeploymentLog
                    accountId={accountId}
                    partitionId={partitionId}
                    restHeight={HEADER_WITH_BREADCRUMB_WITH_TAB_HEIGHT}
                />
            </Tabs.TabPane>,
        );
    }
    if (canReadAdminEventsAndTrackingRecords) {
        tabs.push(
            <Tabs.TabPane
                tab={
                    <div id="tab.upload-queue">
                        {t("partition.upload-queue.tab")}
                    </div>
                }
                key="upload-queue"
                id="tab.upload-queue"
            >
                <UploadQueue partitionId={partitionId} />
            </Tabs.TabPane>,
        );
    }

    return (
        <Tabs
            defaultActiveKey={activeKey}
            activeKey={activeKey}
            onChange={setActiveKey}
            destroyInactiveTabPane
        >
            {tabs}
        </Tabs>
    );
};

PartitionDashboard.propTypes = {
    partitionId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
};

export default PartitionDashboard;
