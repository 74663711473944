import { H4, H5 } from "@/components/DesignSystem";
import { CollapseGroup } from "@/components/DesignSystem/Collapse/CollapseGroup";
import { PayloadBodyFields } from "@/components/PayloadFields/PayloadBodyFields";
import { PayloadHeadersField } from "@/components/PayloadFields/PayloadHeadersField";
import { PayloadPropertiesField } from "@/components/PayloadFields/PayloadPropertiesField";
import { t } from "@/translations";
import React from "react";

export const PayloadFields = ({
    heading = t("event-wf.form.payload"),
    prefixKey = "",
    getName = sub => [prefixKey, sub].filter(Boolean).join("."),
    bodyName = getName("payload"),
    checkboxName = getName("useOriginalPayload"),
    headersName = getName("headers"),
    propertiesName = getName("properties"),
    isPartition,
    isJSONPayload = isPartition,
    justPayload = isPartition,
}) => {
    const widthProps = {
        width: "max",
        inputWidth: "default",
    };
    // There is issue with first render without values, TODO: improve form init

    // const checkboxValue = Forms.useFieldValue({ formId: Forms.useFormId(), name: checkboxName, });
    // const bodyValue = Forms.useFieldValue({ formId: Forms.useFormId(), name: bodyName, });
    // const headersValue = Forms.useFieldValue({ formId: Forms.useFormId(), name: headersName, });
    // const propertiesValue = Forms.useFieldValue({ formId: Forms.useFormId(), name: propertiesName, });
    // const bodyDefaultExpanded = !checkboxValue && !bodyValue;
    // const headersDefaultExpanded = !headersValue?.length;
    // const propertiesDefaultExpanded = !propertiesValue?.length;
    const bodyDefaultExpanded = undefined;
    const headersDefaultExpanded = undefined;
    const propertiesDefaultExpanded = undefined;

    return (
        <>
            <H4>{heading}</H4>
            <CollapseGroup
                header={t("payload-fields.body")}
                indentedContent
                HeadingComponent={H5}
                defaultCollapsed={bodyDefaultExpanded}
            >
                <PayloadBodyFields
                    name={bodyName}
                    checkboxName={checkboxName}
                    checkboxTooltip={t(
                        "payload-fields.use-original-payload.tooltip-with-combination",
                    )}
                    isJSONPayload={isJSONPayload}
                    {...widthProps}
                />
            </CollapseGroup>
            {!justPayload && (
                <>
                    <CollapseGroup
                        header={t("payload-fields.headers")}
                        indentedContent
                        HeadingComponent={H5}
                        defaultCollapsed={headersDefaultExpanded}
                    >
                        <PayloadHeadersField
                            name={headersName}
                            title={null}
                            {...widthProps}
                        />
                    </CollapseGroup>
                    <CollapseGroup
                        header={t("payload-fields.properties")}
                        indentedContent
                        HeadingComponent={H5}
                        defaultCollapsed={propertiesDefaultExpanded}
                    >
                        <PayloadPropertiesField
                            name={propertiesName}
                            title={null}
                            {...widthProps}
                        />
                    </CollapseGroup>
                </>
            )}
        </>
    );
};
