import { useDic } from "@/components/Dic/useDic.hook";
import { useSetBackButtonAction } from "@/components/PageLayout/useSetBackButtonAction.hook";
import { useSetPageTitle } from "@/components/PageLayout/useSetPageTitle.hook";
import { LoadableRenderer } from "@/modules/loadable";
import { useAccountAppParams } from "@/modules/router/hooks/useAccountAppParams.hook";
import { t } from "@/translations";
import React, { useCallback } from "react";
import { MassListenerForm } from "./MassListenerForm";
import {
    useMassListenerQuery,
    useUpdateMassListenerMutation,
} from "./loadables";

export const EditMassListenerPage = () => {
    const { accountId, listenerId } = useAccountAppParams();
    const title = t(
        "location.accountApp.eventOrchestration.massListeners.edit",
    );

    useSetPageTitle(title);
    const {
        locationRouterService,
        accountAppLocations: { massListenersLocation },
    } = useDic();
    const onCancel = useCallback(
        () => locationRouterService.navigate(massListenersLocation),
        [massListenersLocation, locationRouterService],
    );
    useSetBackButtonAction(onCancel);
    const massListenerQuery = useMassListenerQuery({ accountId, listenerId });
    const editMutation = useUpdateMassListenerMutation({
        accountId,
        listenerId,
        afterSuccess: onCancel,
    });

    return (
        <LoadableRenderer
            loadable={massListenerQuery.loadable}
            hasValue={initialValues => (
                <MassListenerForm
                    accountId={accountId}
                    initialValues={initialValues}
                    onSubmit={editMutation.mutate}
                    onCancel={onCancel}
                    listenerId={listenerId}
                    // readOnly={false}
                />
            )}
        />
    );
};
