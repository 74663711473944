import React from "react";

import { useAsyncCall } from "@/components/hooks/useAsyncCall.hook";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { T } from "@/translations";
import { instancesServices } from "../../../../services/instance.service";

export function useEditInstanceBreadcrumbButton({
    instanceId,
    label,
    onClick,
    permissions = [],
    track,
}) {
    const { response } = useAsyncCall(
        () => instancesServices.fetchInstance(instanceId),
        [instanceId],
    );

    useBreadcrumbButton(
        {
            label,
            onClick,
            permissions,
            disabled: isButtonDisabled(response),
            tooltip: getButtonTooltip(response),
            track,
        },
        [instanceId, response],
    );
}

function isButtonDisabled(response) {
    if (!response) {
        return true;
    }

    return (
        !response.data.configurableSupported ||
        response.data.serverType !== "PROVISIONED"
    );
}

function getButtonTooltip(response) {
    if (!response) {
        return <T id="general.tooltip.checking-im-version" />;
    }

    if (response.data.configurableSupported) {
        return null;
    }

    return response.data.configurable ? (
        <T id="general.tooltip.im-version-not-supported" />
    ) : (
        <T id="general.tooltip.im-not-configurable" />
    );
}
