import { FieldCustomizationModal } from "@/components/CalculatedFieldTable/FieldCustomization.modal";
import { Button, Forms, Switch } from "@/components/DesignSystem";
import { Gap } from "@/components/DesignSystem/Gap";
import { MAPPER_DIRECTION } from "@/components/ISVMapping/steps/General.step";
import { EntityNameHeader } from "@/components/Mappers/MapperTableWithCustomization/components/EntityNameHeader.component";
import { getAllowedChangableColumnTypes } from "@/components/Mappers/MapperTableWithCustomization/components/MapperCascaderWithType.component";
import { MapperRow } from "@/components/Mappers/MapperTableWithCustomization/components/MapperRow.component";
import { VENDOR } from "@/components/Mappers/MapperTableWithCustomization/constants";
import { conformToOutputType } from "@/components/Mappers/form/mapper.utils";
import { useDetailDrawerState } from "@/components/hooks/useDetailDrawerState.hook";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { useVisibility } from "@/components/hooks/useVisibility.hook";
import { logger } from "@/modules/logger";
import { t } from "@/translations";
import { initial, size } from "lodash";
import { mapValues, pipe, reduce, values } from "lodash/fp";
import PropTypes from "prop-types";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import uuid from "uuid";
import ConditionalErrorAlert from "../../Error/ConditionalErrorAlert";
import { Loading } from "../../Loading/Loading";
import {
    InputType,
    ParsedType,
} from "../../Packages/PackageTableDefinitionPanel/constants";
import "./MapperTableWithCustomization.styles.less";

const inputTypeOptions = Object.values(InputType);

export const ROW_ENTITIES = {
    id: "id",
    input: "input",
    inputType: "inputType",
    output: "output",
    outputType: "outputType",
    converter: "converter", // only converter name => should be deprecated on BE soon
    converterExpression: "converterExpression", // converterName with params
    formulaMapping: "formulaMapping",
};
const transformValue = ({
    name,
    value,
    input,
    inputType,
    outputType,
    parserConfig,
    disableAutofillConverters,
}) => {
    if (!parserConfig && !disableAutofillConverters) {
        throw new Error("Missing parser config");
    }
    if (name === ROW_ENTITIES.input && inputType === "composed") {
        return value;
    } else if (
        [ROW_ENTITIES.input, ROW_ENTITIES.output].includes(name) &&
        Array.isArray(value)
    ) {
        // multiselect
        if (size(value) > 1) {
            return value;
        } else {
            return value.join("");
        }
    } else if (
        [ROW_ENTITIES.converter, ROW_ENTITIES.converterExpression].includes(
            name,
        )
    ) {
        const converterExpression =
            value || disableAutofillConverters
                ? value
                : conformToOutputType(
                      { input, inputType, outputType },
                      parserConfig,
                  );
        return converterExpression;
    } else {
        return value;
    }
};

const getRow = ({ id, values, parserConfig, disableAutofillConverters }) =>
    Object.keys(ROW_ENTITIES).reduce(
        (result, entryName) => ({
            ...result,
            [entryName]: transformValue({
                name: entryName,
                id: values[`${id}_${ROW_ENTITIES.id}`],
                value: values[`${id}_${entryName}`],
                input: values[`${id}_${ROW_ENTITIES.input}`],
                inputType:
                    values[`${id}_${ROW_ENTITIES.inputType}`] || InputType.BODY,
                outputType: values[`${id}_${ROW_ENTITIES.outputType}`],
                parserConfig,
                disableAutofillConverters,
            }),
        }),
        {},
    );

const getValuesIndexedByKey = (
    values,
    usedIds,
    parserConfig,
    disableAutofillConverters,
) =>
    usedIds.reduce(
        (result, id) => ({
            ...result,
            [id]: getRow({
                id,
                values,
                parserConfig,
                disableAutofillConverters,
            }),
        }),
        {},
    );

const getFormValues = pipe(getValuesIndexedByKey, values);

const inputsToTransform = [ROW_ENTITIES.input, ROW_ENTITIES.output];

const formatInitialValue = (name, value) => {
    return inputsToTransform.includes(name)
        ? Array.isArray(value)
            ? value
            : [value]
        : value;
};
const mapInitialValuesToForm = mapperRows =>
    mapperRows.reduce(
        (result, row) => ({
            ...result,
            ...Object.entries(row).reduce((result, [name, value]) => {
                return {
                    ...result,
                    [`${row.id}_${name}`]: formatInitialValue(name, value),
                };
            }, {}),
        }),
        {},
    );

export const useInputOptionsWithTypes = ({ tableExampleData }) => {
    const tableExampleDataMap = useMemo(() => {
        const map = new Map();

        tableExampleData?.columns.forEach(row => {
            map.set(row.name, row);
        });

        return map;
    }, [tableExampleData]);

    const tableExampleDataMapRef = useRef(tableExampleDataMap);

    useEffect(() => {
        if (
            tableExampleDataMap.size !== 0 &&
            tableExampleDataMapRef.current === undefined
        ) {
            tableExampleDataMapRef.current = tableExampleDataMap;
        }
    }, [tableExampleDataMap]);

    const inputOptionsWithTypes = useMemo(
        () =>
            tableExampleData?.columns.map(column => ({
                label: column.name,
                value: column.name,
                type: column.type,
                allowedToChange: getAllowedChangableColumnTypes(
                    tableExampleDataMapRef.current.get(column.name)?.type,
                ),
            })),
        [tableExampleData],
    );

    return { inputOptionsWithTypes, tableExampleDataMap };
};

const LAYOUT = [
    { flex: "0 0 35%" },
    { flex: "0 0 16%" },
    { flex: "0 0 35%" },
    { width: 32 },
];
export const defaultVendors = [VENDOR.PRICEFX, VENDOR.PRICEFX];

const valueToInitField = value => ({ initialValue: value, value });

const emptyArr = [];

export const MapperTableWithCustomization = ({
    apiErrors,
    direction = MAPPER_DIRECTION.UPLOAD,
    disableAutofillConverters,
    entityName,
    entityType,
    errors,
    inputOptionsWithTypes,
    isFreetextOutput,
    mapper: mapperRows = emptyArr,
    onChange,
    onColumnTypeChange,
    outputOptions: outputOptionsWithTypes = emptyArr,
    parserConfig,
    partitionId,
    previewDisabled = direction === MAPPER_DIRECTION.DOWNLOAD,
    readOnly,
    tableExampleData,
    tableExampleDataMap,
    vendors = defaultVendors,
    withConverterWarning,
    disableInitEff,
    form: { getBag, setValues, initFields_UNSTABLE, clearFields, removeFields },
}) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const modalControl = useDetailDrawerState();
    const [usedIds, setUsedIds] = useState(mapperRows.map(({ id }) => id));
    const handleChange = useCallback(
        changedIds => {
            getBag().then(({ values }) => {
                const indicies = changedIds ?? usedIds;
                const formValues = getFormValues(
                    values,
                    indicies,
                    parserConfig,
                    disableAutofillConverters,
                );
                setValues(mapInitialValuesToForm(formValues));
                console.log("handleChange", {
                    values,
                    indicies,
                    parserConfig,
                    formValues,
                });
                onChange(formValues);
            });
        },
        [
            getBag,
            onChange,
            parserConfig,
            setValues,
            usedIds,
            disableAutofillConverters,
        ],
    );

    useEffect(() => {
        setTimeout(() => {
            if (!disableInitEff) handleChange(usedIds);
            setIsLoaded(true);
        }, 200);
    }, []);

    const initialValues = useMemo(() => {
        const initialValues = mapInitialValuesToForm(
            getFormValues(
                mapInitialValuesToForm(mapperRows),
                usedIds,
                parserConfig,
                disableAutofillConverters,
            ),
        );
        return initialValues;
    }, []);
    useEffect(() => {
        pipe(mapValues(valueToInitField), initFields_UNSTABLE)(initialValues);
        return () => {
            clearFields();
            removeFields(Object.keys(initialValues));
        };
    }, []);

    const converterProps = useMemo(
        () => ({
            withConverterWarning:
                !disableAutofillConverters && withConverterWarning,
            parserConfig,
        }),
        [disableAutofillConverters, parserConfig, withConverterWarning],
    );
    const dirtyComparator = useMemo(
        () => (isLoaded ? (prev, next) => prev !== next : () => false),
        [isLoaded],
    );

    const detailInfo = useVisibility(false);

    const onConverterClick = useCallback(
        async id => {
            const { values } = await getBag();
            modalControl.show(
                getValuesIndexedByKey(
                    values,
                    usedIds,
                    parserConfig,
                    disableAutofillConverters,
                )[id], // Why do we need to fill converter here?
            );
        },
        [
            getBag,
            modalControl,
            parserConfig,
            usedIds,
            disableAutofillConverters,
        ],
    );

    const onDelete = useCallback(
        id => {
            setUsedIds(ids => {
                const changedIds = ids.filter(entryId => entryId !== id);
                handleChange(changedIds);
                return changedIds;
            });
        },
        [handleChange],
    );

    return (
        <Loading isLoading={!mapperRows}>
            <div
                className="pmMapperTableWithCustomization"
                data-test="mapper-table-with-customization"
            >
                {[]
                    .concat(errors?.general ?? [])
                    .concat(apiErrors?.messages || (apiErrors ?? []))
                    .map((error, index) => (
                        <React.Fragment
                            key={error?.message ?? JSON.stringify(error)}
                        >
                            <ConditionalErrorAlert key={index} error={error} />
                            <Gap size="small" />
                        </React.Fragment>
                    ))}

                <Switch
                    id="detail-switch"
                    size="small"
                    value={detailInfo.visible}
                    onChange={detailInfo.toggle}
                    textOn={t("data-upload.mapper.detail-view.label")}
                    textOff={t("data-upload.mapper.detail-view.label")}
                />

                <Gap size="small" />

                <EntityNameHeader
                    vendors={vendors}
                    layout={LAYOUT}
                    entityName={entityName}
                    entityType={entityType}
                    partitionId={partitionId}
                />
                <Forms.FieldGrid layout={LAYOUT}>
                    {usedIds.map((id, i) => (
                        <MapperRow
                            key={id}
                            id={id}
                            vendors={vendors}
                            order={i}
                            errors={errors?.inputs ?? emptyArr}
                            readOnly={readOnly}
                            tableExampleData={tableExampleData}
                            tableExampleDataMap={tableExampleDataMap}
                            inputOptionsWithTypes={inputOptionsWithTypes}
                            onConverterClick={onConverterClick}
                            onChange={handleChange}
                            onDelete={onDelete}
                            isDetailVisible={detailInfo.visible}
                            isDeleteDisabled={size(usedIds) === 1}
                            outputOptionsWithTypes={outputOptionsWithTypes}
                            converterProps={converterProps}
                            onTypeChange={setValues}
                            onColumnTypeChange={onColumnTypeChange}
                            direction={direction}
                            dirtyComparator={dirtyComparator}
                            isFreetextOutput={isFreetextOutput}
                        />
                    ))}
                </Forms.FieldGrid>
                <Gap />
                {!readOnly && (
                    <Button
                        type="tertiary"
                        onClick={() => {
                            const id = uuid();
                            const rowValues = {
                                [`${id}_id`]: id,
                                [`${id}_input`]: "",
                                [`${id}_inputType`]: InputType.BODY,
                                [`${id}_output`]: "",
                                [`${id}_outputType`]: null,
                                [`${id}_converter`]: null,
                                [`${id}_converterExpression`]: null,
                                [`${id}_formulaMapping`]: null,
                            };
                            initFields_UNSTABLE(
                                mapValues(valueToInitField, rowValues),
                            );
                            setUsedIds(ids => [...ids, id]);
                            handleChange();
                        }}
                        label={"+ " + t("data-upload.mapper.add.field")}
                    />
                )}
                <Gap />
                <FieldCustomizationModal
                    visible={!!modalControl.record}
                    onSave={changedRow => {
                        setValues(mapInitialValuesToForm([changedRow]));
                        handleChange();
                        modalControl.hide();
                    }}
                    onCancel={modalControl.hide}
                    tableExampleData={tableExampleData}
                    treeInputOptions={inputOptionsWithTypes}
                    initialValues={modalControl.record}
                    inputOptions={
                        direction === MAPPER_DIRECTION.DOWNLOAD
                            ? inputOptionsWithTypes
                            : undefined
                    }
                    withConverterExpression
                    parserConfig={parserConfig}
                    direction={direction}
                    previewDisabled={previewDisabled}
                />
            </div>
        </Loading>
    );
};

MapperTableWithCustomization.propTypes = {
    vendors: PropTypes.arrayOf(PropTypes.string),
    mapper: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string.isRequired,
                PropTypes.number.isRequired,
            ]).isRequired,
            input: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
            inputType: PropTypes.oneOf(inputTypeOptions),
            output: PropTypes.string,
            outputType: PropTypes.oneOf([...Object.values(ParsedType), ""]),
            formulaMapping: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string.isRequired,
                    sourceField: PropTypes.string.isRequired,
                    example: PropTypes.number.isRequired,
                }).isRequired,
            ),
        }).isRequired,
    ).isRequired,
    outputOptions: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired, // output field name
            type: PropTypes.oneOf(Object.values(ParsedType)).isRequired,
            label: PropTypes.string.isRequired,
        }).isRequired,
    ).isRequired,
    tableExampleData: PropTypes.shape({
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.oneOf(Object.values(ParsedType)).isRequired,
                name: PropTypes.string.isRequired,
                parsedValues: PropTypes.array,
            }),
        ),
    }),
    errors: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    entityName: PropTypes.string.isRequired,
    entityType: PropTypes.string.isRequired,
    partitionId: PropTypes.number,
    parserConfig: PropTypes.object,
    withConverterWarning: PropTypes.bool,
    apiErrors: PropTypes.string, // ???
};
