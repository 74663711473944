import { MAPPER_DIRECTION } from "@/components/ISVMapping/steps/General.step";
import find from "lodash/find";
import map from "lodash/map";
import uuid from "uuid/v4";
import { includesCaseInsensitive } from "../components/Packages/PackageTableDefinitionPanel/components/PackageMandatoryFields/PackageMandatoryFields.utils";
import { metadataService as defaultMetadataService } from "./metadata.service";

export const mapperMappingService = {
    getAttributesMetadata: async function (
        sourceType,
        sourceId,
        entityType,
        entityName,
        metadataService = defaultMetadataService,
        queryParams,
    ) {
        const { data } = await metadataService.fetchAttributesMetadata(
            sourceType,
            sourceId,
            entityType,
            entityName,
            queryParams,
        );
        return data;
    },

    mapInputsToMapper(inputOptions) {
        return map(inputOptions, input => {
            return {
                id: uuid(),
                input: input.value,
                inputLabel: input.label,
                inputType: "body",
                output: input.value,
                outputType: input.type,
                outputLabel: input.label,
            };
        });
    },

    mapOptionsToMetadata(
        inputOptions,
        outputOptions,
        direction = MAPPER_DIRECTION.UPLOAD,
    ) {
        return map(
            direction === MAPPER_DIRECTION.UPLOAD
                ? inputOptions
                : outputOptions,
            fileOption => {
                const foundOption = find(
                    direction === MAPPER_DIRECTION.UPLOAD
                        ? outputOptions
                        : inputOptions,
                    option =>
                        includesCaseInsensitive(option.label, fileOption.label),
                );

                return direction === MAPPER_DIRECTION.UPLOAD
                    ? foundOption
                        ? {
                              id: uuid(),
                              input: fileOption.value,
                              inputLabel: fileOption.label,
                              inputType: "body",
                              //inputType: fileOption?.type ?? "body",
                              output: foundOption.value,
                              outputType: foundOption.type,
                              outputLabel: foundOption.label,
                          }
                        : {
                              id: uuid(),
                              input: fileOption.label,
                              inputType: "body",
                          }
                    : foundOption
                    ? {
                          id: uuid(),
                          input: foundOption.value,
                          inputLabel: foundOption.label,
                          inputType: "body",
                          //inputType: foundOption?.type ?? "body",
                          output: fileOption.value,
                          outputType: fileOption.type,
                          outputLabel: fileOption.label,
                      }
                    : {
                          id: uuid(),
                          output: fileOption.label,
                          inputType: "body",
                      };
            },
        );
    },
};
