import { Forms } from "@/components/DesignSystem";
import { t } from "@/translations";
import { fromPairs, map, pipe } from "lodash/fp";
import identity from "lodash/identity";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import AdminUsersSelect from "./AdminUsersSelect.component";

const { Fields, Field, success, error } = Forms;

const emptyArray = [];
const useUsersValidator = ({ fields, formId }) => {
    const [groups, roles, approvalsCount] = [
        Forms.useFieldValue({ formId, name: fields.GROUPS }) ?? emptyArray,
        Forms.useFieldValue({ formId, name: fields.ROLES }) ?? emptyArray,
        Forms.useFieldValue({ formId, name: fields.APPROVAL_NUMBER }) ?? 0,
    ];

    return useCallback(
        users => {
            if (groups.length || roles.length) return success();
            if ((users?.length ?? 0) < approvalsCount)
                return error(
                    `When no groups or roles are selected, users count must be greater or equal to number of users to approve (${approvalsCount})`,
                );
            return success();
        },
        [approvalsCount, groups, roles],
    );
};

// Solution to UX issue - when one of required is selected and unselected, we want to show validation for each field, from which user can choose
export const useSetTouchedFields = ({ fieldNames, setTouched }) =>
    useCallback(
        () =>
            pipe(
                map(name => [name, true]),
                fromPairs,
                setTouched,
            )(fieldNames),
        [fieldNames, setTouched],
    );

export const CreateWorkflowApprovalFormComponent = ({
    formId,
    id,
    accountId,
    users = [],
    groups = [],
    roles = [],
    isGlobalWorkflow,
    initialValues,
    setTouched,
}) => {
    const fields = {
        USERS: id + "-persons",
        GROUPS: id + (isGlobalWorkflow ? "-adminGroups" : "-groups"),
        ROLES: id + "-roles",
        APPROVAL_NUMBER: id + "-approvedRequires",
    };
    const crossFieldValidators = Forms.pmValidators.useAtLeastOneRequired({
        formId,
        fieldNames: [fields.USERS, fields.GROUPS, fields.ROLES],
    });
    const setTouchedFields = useSetTouchedFields({
        fieldNames: [fields.USERS, fields.GROUPS, fields.ROLES],
        setTouched,
    });
    const usersValidator = useUsersValidator({ fields, formId });

    return (
        <>
            {isGlobalWorkflow ? (
                <Field
                    as={AdminUsersSelect}
                    from={identity}
                    inputWidth="max"
                    label={t("workflow-create.label.persons")}
                    data-test="create-workflow-user-dropdown"
                    placeholder={t(
                        "workflow-create.placeholder.persons-search",
                    )}
                    allowClear
                    showSearch
                    mode="multiple"
                    options={users}
                    name={fields.USERS}
                    initialValue={initialValues?.[fields.USERS]}
                    disabled={accountId === undefined}
                    dataTest="create-workflow-user"
                    validator={Forms.validators.failOnFirst([
                        crossFieldValidators[fields.USERS],
                        usersValidator,
                    ])}
                    onChange={setTouchedFields}
                />
            ) : (
                <Fields.Select
                    inputWidth="max"
                    label={t("workflow-create.label.persons")}
                    placeholder={t("workflow-create.placeholder.persons")}
                    allowClear
                    showSearch
                    mode="multiple"
                    options={users}
                    name={fields.USERS}
                    initialValue={initialValues?.[fields.USERS]}
                    disabled={accountId === undefined}
                    dataTest="create-workflow-user"
                    validator={Forms.validators.failOnFirst([
                        crossFieldValidators[fields.USERS],
                        usersValidator,
                    ])}
                    onBlur={setTouchedFields}
                />
            )}

            <Fields.Select
                // A bit hacky, but allows us to change name programmatically
                key={fields.GROUPS}
                inputWidth="max"
                label={t("workflow-create.label.groups")}
                placeholder={t("workflow-create.placeholder.groups")}
                allowClear
                showSearch
                mode="multiple"
                options={groups}
                name={fields.GROUPS}
                initialValue={initialValues?.[fields.GROUPS]}
                disabled={accountId === undefined}
                dataTest="create-workflow-group"
                validator={crossFieldValidators[fields.GROUPS]}
                onBlur={setTouchedFields}
            />

            <Fields.Select
                inputWidth="max"
                label={t("workflow-create.label.roles")}
                data-test="create-workflow-roles"
                placeholder={t("workflow-create.placeholder.roles")}
                allowClear
                showSearch
                mode="multiple"
                options={roles}
                name={fields.ROLES}
                initialValue={initialValues?.[fields.ROLES]}
                validator={crossFieldValidators[fields.ROLES]}
                onBlur={setTouchedFields}
            />

            <Fields.InputNumber
                inputWidth="max"
                label={t("workflow-create.label.approved-number")}
                data-test="number-of-users-input"
                min={1}
                name={fields.APPROVAL_NUMBER}
                initialValue={initialValues?.[fields.APPROVAL_NUMBER] || 1}
            />
        </>
    );
};

CreateWorkflowApprovalFormComponent.propTypes = {
    formId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    users: PropTypes.array.isRequired,
    groups: PropTypes.array.isRequired,
    roles: PropTypes.array.isRequired,
};
