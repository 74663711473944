import { columnsBuilder } from "@/components/ColumnsBuilder";
import { ActionButton } from "@/components/DesignSystem/Table/components/ActionButton/ActionButton";
import { useFetchPage } from "@/components/PagableTable/useFetchPage.hook";
import { useHideBackButton } from "@/components/PageLayout/useHideBackButton.hook";
import { TableWithPreferencesManagement } from "@/components/TableWithPreferences/TableWithPreferencesManagement.container";
import { useBreadcrumbButton } from "@/components/hooks/useBreadcrumbButton.hook";
import { useFullWidthTableLayout } from "@/components/hooks/useContentLayout.hook";
import { constantsService } from "@/services/constants.service";
import { t } from "@/translations";
import { message } from "antd";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

export function AbstractConstantComponent({
    type,
    deleteProperty,
    modalComponent,
    createNewButtonConfig,
    sortReplacementFunc,
}) {
    useFullWidthTableLayout();
    useHideBackButton();

    const [tableProps, { reload }] = useFetchPage(
        (page, size, sort, filter) =>
            constantsService.fetchEntities(
                page,
                size,
                sortReplacementFunc ? sortReplacementFunc(sort) : sort,
                filter,
                type,
            ),
        [type],
    );

    const [visibleCreateModal, setVisibleCreateModal] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState();
    const [columns, setColumns] = useState([]);
    const [actionMenu, setActionMenu] = useState([]);
    const ModalComponent = modalComponent;

    const onCreateModalCancel = () => setVisibleCreateModal(false);
    const onEditModalCancel = () => setSelectedEntity(undefined);

    const saveEntity = useCallback(
        entity => {
            constantsService
                .upsertEntity(type, entity)
                .then(() => {
                    message.info(t(type + ".saved"));
                    reload();
                })
                .catch(() => message.error(t(type + ".save-failed")));

            onCreateModalCancel();
            onEditModalCancel();
        },
        [reload, type],
    );

    const onEditModalOpen = entity => setSelectedEntity(entity);

    const deleteRecord = record => {
        constantsService
            .deleteEntity(type, record.id)
            .then(() => {
                message.success(
                    t("general.message.deleted", {
                        type: t(type),
                        name: record[deleteProperty],
                    }),
                );
                reload();
            })
            .catch(() => message.error(t(type + ".delete.failed")));
    };

    const fetchConstantInfo = () => {
        constantsService.fetchConstantsInfo().then(response => {
            const constantInfo = response.data.find(item => item.type === type);
            const columns = columnsBuilder(constantInfo);
            setColumns(columns);
            setActionMenu(() =>
                actionMenuBuilder({
                    constantInfo,
                    onEditModalOpen,
                    deleteProperty,
                    deleteRecord,
                }),
            );
        });
    };

    useBreadcrumbButton(
        {
            label: type + ".create.modal.button",
            onClick: () => setVisibleCreateModal(true),
            ...(createNewButtonConfig &&
                createNewButtonConfig(tableProps?.page?.content)),
        },
        [visibleCreateModal, tableProps],
    );

    useEffect(() => {
        fetchConstantInfo();
    }, []);

    return (
        columns.length > 1 && (
            <>
                <TableWithPreferencesManagement
                    columns={columns}
                    fixed
                    rowKey="id"
                    datasetSlicing="server"
                    preferencesType={`AbstractConstantsListTable-${type}`}
                    actionMenu={actionMenu}
                    exportUrl={`api/constants/${type}/table/export`}
                    {...tableProps}
                />
                <ModalComponent
                    onOk={saveEntity}
                    onCancel={onCreateModalCancel}
                    visible={visibleCreateModal}
                    data={tableProps?.page?.content}
                    confirmText={t("general.create")}
                />
                <ModalComponent
                    onOk={saveEntity}
                    onCancel={onEditModalCancel}
                    visible={!!selectedEntity}
                    data={tableProps?.page?.content}
                    initialValues={selectedEntity}
                    title={t(type + ".edit.modal.title")}
                />
            </>
        )
    );
}

const actionMenuBuilder = ({
    constantInfo,
    onEditModalOpen,
    deleteProperty,
    deleteRecord,
}) => {
    const { type } = constantInfo;
    return record => (
        <ActionButton
            record={record}
            items={[
                {
                    title: t("general.tooltip.edit"),
                    onClick: onEditModalOpen,
                },
                {
                    title: t(type + ".delete"),
                    confirmTitle: t(type + ".delete.title"),
                    confirmMessage: t(type + ".delete.message", {
                        objectName: record[deleteProperty],
                    }),
                    onConfirm: deleteRecord,
                    color: "red",
                },
            ]}
        />
    );
};

AbstractConstantComponent.propTypes = {
    type: PropTypes.string.isRequired,
    deleteProperty: PropTypes.string.isRequired,
    modalComponent: PropTypes.elementType.isRequired,
    createNewButtonConfig: PropTypes.func,
    sortReplacementFunc: PropTypes.func,
};
