import { Collapse, H4 } from "@/components/DesignSystem";
import { ErrorBoundary } from "@/components/Error";
import React, { useEffect, useMemo, useState } from "react";
import "./CollapseGroup.styles.less";
// import { Collapse } from "./Collapse"; // Not working

// import { Collapse } from "@pricefx/unity-components";
// import { Collapse } from "@/components/DesignSystem/Collapse";

export const CollapseGroup = ({
    header,
    children,
    defaultCollapsed,
    collapsed: collapsedReactive = defaultCollapsed,
    indentedContent,
    HeadingComponent = H4,
}) => {
    const defaultActiveKey = useMemo(
        () => (defaultCollapsed || collapsedReactive ? [] : ["1"]),
        [collapsedReactive, defaultCollapsed],
    );
    const [activeKey, setActiveKey] = useState(defaultActiveKey);
    useEffect(() => {
        setActiveKey(collapsedReactive ? [] : ["1"]);
    }, [collapsedReactive]);

    return (
        <ErrorBoundary>
            <Collapse
                ghost
                className="pm-collapse-group"
                activeKey={activeKey}
                onChange={key => {
                    setActiveKey(key);
                }}
            >
                <Collapse.Panel
                    key="1"
                    header={
                        <HeadingComponent style={{ lineHeight: "20px" }}>
                            {header}
                        </HeadingComponent>
                    }
                >
                    <div style={indentedContent ? { paddingLeft: "24px" } : {}}>
                        {children}
                    </div>
                </Collapse.Panel>
            </Collapse>
        </ErrorBoundary>
    );
};
