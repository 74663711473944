import { Card } from "@/components/DesignSystem";
import { t } from "@/translations";
import { Skeleton as AntdSkeleton } from "antd";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./Widget01.style.less";

const Widget01 = ({
    loading = false,
    className,
    items: propItems = [],
    mainText,
    color,
    variant = "normal",
    link,
}) => {
    const items = propItems.filter(item => !item.hidden);

    return (
        <Card>
            <div
                className={cx(
                    "Widget01",
                    `Widget01--${color}`,
                    `Widget01--${variant}`,
                    className,
                )}
            >
                {loading ? (
                    <AntdSkeleton
                        active={true}
                        title={false}
                        paragraph={true}
                        round={false}
                        rows={2}
                    />
                ) : (
                    <div className="Widget01_textWrapper">
                        <div className="Widget01_mainText">{mainText}</div>
                        {items.map(item => {
                            if (typeof item.render === "function")
                                return item.render();
                            return (
                                <div className="Widget01_item" key={item.title}>
                                    {item.title && (
                                        <div className="Widget01_title">
                                            {item.title}:
                                        </div>
                                    )}
                                    <div className="Widget01_value">
                                        {item.value}
                                    </div>
                                </div>
                            );
                        })}
                        {link && (
                            <a className="Widget01_footer" href={link}>
                                {t("widget-01.view-more")}
                                <i className="fa fa-angle-right"></i>
                            </a>
                        )}
                    </div>
                )}
            </div>
        </Card>
    );
};

Widget01.propTypes = {
    firstColumnWidth: PropTypes.number,
    secondColumnWidth: PropTypes.number,
    header: PropTypes.any,
    mainText: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
    link: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    hint: PropTypes.string,
    height: PropTypes.number,
};

Widget01.defaultProps = {
    header: "",
    icon: "fa fa-cogs",
    color: "primary",
};

export default Widget01;
