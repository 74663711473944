import { HasValueLoadable } from "@/modules/loadable/useQueryLoadable.hook";
import { t } from "@/translations";
import { every, get, map, pipe, reject, toPairs } from "lodash/fp";
import React, { useEffect, useMemo } from "react";
import { Forms } from "../DesignSystem";
import { useMemoByDeepEquality } from "../hooks/useMemoByDeepEquality.hook";
import { getLoadableSelectProps } from "../Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import { hasError } from "@/modules/loadable";

const emptyArr = [];
export const SelectWithDependencies = ({
    deps = {},
    optionalDepKeys = emptyArr,
    validators = emptyArr,
    useOptionsQuery = () => ({ loadable: HasValueLoadable(emptyArr) }),
    required,
    setValues,
    autoSelectOnlyItem = !!setValues,
    clearOnDepsChange = false,
    clearOnError = false,
    ...props
}) => {
    const depsMemo = useMemoByDeepEquality(deps);
    const canFetch = useMemo(
        () =>
            pipe(
                toPairs,
                reject(([key]) => optionalDepKeys.includes(key)),
                map(get(1)),
                every(Boolean),
            )(deps),
        [deps, optionalDepKeys],
    );
    const optionsQuery = useOptionsQuery({ ...deps, canFetch });
    const maybeOptions = optionsQuery.loadable.valueMaybe();
    const missingDepsProps = canFetch
        ? {}
        : {
              disabled: true,
              placeholder: t("general.please-select"),
              loading: false,
          };
    const loadableSelectProps = getLoadableSelectProps(optionsQuery.loadable);
    const { options } = loadableSelectProps;
    useEffect(() => {
        if (clearOnDepsChange) setValues({ [props.name]: undefined });
    }, [clearOnDepsChange, depsMemo, props.name, setValues]);
    useEffect(() => {
        if (clearOnError && hasError(optionsQuery))
            setValues({ [props.name]: undefined });
    }, [clearOnError, optionsQuery, props.name, setValues]);
    useEffect(() => {
        if (options?.length === 1 && autoSelectOnlyItem) {
            setValues({ [props.name]: options[0].value });
        }
    }, [autoSelectOnlyItem, options, props.name, setValues]);

    return (
        <Forms.Fields.Select
            required={required}
            validator={Forms.validators.failOnFirst(
                [
                    ...validators,
                    required && Forms.pmValidators.isRequired,
                    Forms.pmValidators.createAllowedValuesValidator(
                        maybeOptions?.map(get("value")) ?? [],
                        "Value not found in options",
                    ),
                ].filter(Boolean),
            )}
            {...loadableSelectProps}
            {...missingDepsProps}
            {...props}
        />
    );
};
