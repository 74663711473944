import { t } from "@/translations";
import { flatten, unflatten } from "flat";
import { omit, pipe, prop } from "lodash/fp";
import React, { useCallback, useMemo } from "react";
import { Button, ButtonGroup, Forms, Gap, H4, Text } from "../DesignSystem";
import { SELECT_GET_VALUE } from "../DesignSystem/Forms/fields";
import { ReadOnlyProvider } from "../DesignSystem/Forms/ReadOnlyContext";
import { getLoadableSelectProps } from "../Packages/PackageTableDefinitionPanel/components/ObjectTypeSelector/EntityNameSelector";
import {
    useELActionEntitiesOptionsQuery,
    useELActionTypesOptionsQuery,
    useELEntitiesOptionsQuery,
    useELEventOptionsQuery,
    useELTypeOptionsQuery,
    useMassListenerApprovalRunning,
    useMassListenerNameExistsValidation,
} from "./loadables";
import { SelectWithDependencies } from "./SelectWithDependencies";

const to = initialValues => {
    if (!initialValues) return initialValues;
    else return flatten(initialValues);
};
const from = values => {
    const nested = unflatten(values, { safe: true });
    const fallbackFix = nested.fallbackOptions?.enabled
        ? nested
        : omit(["fallbackOptions"], nested);
    const final = {
        ...fallbackFix,
        trigger: {
            eventFilter: {
                filters: [],
            },
            ...fallbackFix.trigger,
        },
    };
    return final;
};

// https://gitlab.pricefx.eu/platform/platform-manager/-/merge_requests/4848/diffs#38da38e4f0ce43e37ef4b23be0f84e66fa1a63cc_0_413
const req = {
    enabled: false,
    name: "name2",
    description: "description2",
    trigger: {
        sourceEventType: "CUSTOM",
        eventFilter: {
            filters: [
                {
                    jsonPath: "field2",
                    value: "value2",
                },
            ],
        },
    },
    target: {
        targetType: "LOGIC",
        targetId: "logic2",
    },
    batchingEnabled: true,
    source: {
        sourceType: "PARTITION",
        sourceId: 1,
        label: "test.mock-partition",
    },
};

export const MassListenerForm = ({
    accountId,
    initialValues: _initialValues,
    onSubmit,
    onCancel,
    readOnly: _readOnly,
    listenerId,
}) => {
    const initialValues = useMemo(
        () => _initialValues && to(_initialValues),
        [_initialValues],
    );
    const isNew = !initialValues?.id;
    const { Form, formId, setValues } = Forms.useForm({
        initialValues,
        onSubmit: pipe(prop("values"), from, onSubmit),
    });

    const skipValidation = useCallback(
        ({ name }) => !isNew && name === initialValues?.name,
        [initialValues?.name, isNew],
    );
    const nameExistsValidation = useMassListenerNameExistsValidation({
        accountId,
        skipValidation,
    });

    const nameValue = Forms.useFieldValue({ formId, name: "name" });
    const typeValue = Forms.useFieldValue({
        formId,
        name: "source.sourceType",
    });
    const { isRunning } = useMassListenerApprovalRunning(
        isNew ? { name: nameValue } : { id: initialValues?.id },
    );
    const readOnly = _readOnly || (isRunning && !isNew);

    return (
        <ReadOnlyProvider readOnly={readOnly}>
            <Form disableBanner>
                {!isNew && (
                    <Forms.Fields.Input hidden type="hidden" name="id" />
                )}
                <Forms.Fields.Switch
                    name="enabled"
                    label={""}
                    layout="horizontal"
                    size="small"
                    textOn={t("general.active")}
                    textOff={t("general.inactive")}
                    initialValue={true}
                />

                <Forms.Fields.Input
                    name="name"
                    label={t("mass-listener.form.name.label")}
                    required
                    // disabled={!isNew}
                    validator={Forms.validators.failOnFirst([
                        Forms.pmValidators.isRequired,
                        Forms.pmValidators.createMinLengthValidation(1),
                        //   Forms.pmValidators.createPatternValidator( NAME_VALIDATION_PATTERN, "Name has a wrong format. Valid format is a-z A-Z, 0-9 . _ - without spaces", ),
                        nameExistsValidation,
                    ])}
                />
                <Forms.Fields.TextArea
                    name="description"
                    label={t("mass-listener.form.description.label")}
                    rows="3"
                    initialValue=""
                />
                <H4>{t("mass-listener.form.event.heading")}</H4>
                <Text>{t("mass-listener.form.event.perex")}</Text>
                <SelectWithDependencies
                    required
                    name="source.sourceType"
                    label={t("mass-listener.form.event-source-type.label")}
                    setValues={setValues}
                    useOptionsQuery={useELTypeOptionsQuery}
                    deps={{ accountId }}
                />
                <SelectWithDependencies
                    required
                    name="source.sourceId"
                    label={t("mass-listener.form.event-source.label")}
                    setValues={setValues}
                    useOptionsQuery={useELEntitiesOptionsQuery}
                    deps={{ accountId, type: typeValue }}
                />
                <SelectWithDependencies
                    required
                    name="trigger.sourceEventType"
                    label={t("mass-listener.form.event-type.label")}
                    setValues={setValues}
                    useOptionsQuery={useELEventOptionsQuery}
                    deps={{ accountId, type: typeValue }}
                />
                <Forms.Fields.Checkbox
                    name="batchingEnabled"
                    label={t("mass-listener.form.batching-enabled.label")}
                    tooltip={t("mass-listener.form.batching-enabled.tooltip")}
                    initialValue={false}
                />

                <Gap />

                <H4>{t("mass-listener.form.action.heading")}</H4>
                <Text>{t("mass-listener.form.action.perex")}</Text>

                <Forms.Fields.ReadOnlyValue
                    name={"source.sourceType"}
                    label={t("mass-listener.form.target-type.label")}
                    {...getLoadableSelectProps(
                        useELTypeOptionsQuery({ accountId }).loadable,
                    )}
                    getValue={SELECT_GET_VALUE}
                    placeholderValue="-"
                />
                <Forms.Fields.ReadOnlyValue
                    name={"source.sourceId"}
                    label={t("mass-listener.form.target.label")}
                    {...getLoadableSelectProps(
                        useELEntitiesOptionsQuery({
                            accountId,
                            type: typeValue,
                        }).loadable,
                    )}
                    getValue={SELECT_GET_VALUE}
                    placeholderValue="-"
                />
                <SelectWithDependencies
                    required
                    name="target.targetType"
                    label={t("mass-listener.form.action-type.label")}
                    setValues={setValues}
                    useOptionsQuery={useELActionTypesOptionsQuery}
                    deps={{ accountId, type: typeValue }}
                />
                <SelectWithDependencies
                    required
                    name="target.targetId"
                    label={t("mass-listener.form.action-id.label")}
                    setValues={setValues}
                    useOptionsQuery={useELActionEntitiesOptionsQuery}
                    deps={{
                        accountId,
                        type: typeValue,
                        entityId: Forms.useFieldValue({
                            formId,
                            name: "source.sourceId",
                        }),
                        actionType: Forms.useFieldValue({
                            formId,
                            name: "target.targetType",
                        }),
                        listenerId,
                    }}
                    optionalDepKeys={["listenerId"]}
                    clearOnError
                />
                <Gap />
                <ButtonGroup>
                    <Button
                        visible={!readOnly}
                        data-test={isNew ? "create-button" : "save-button"}
                        htmlType="submit"
                        type="primary"
                        formId={formId}
                        label={isNew ? t("general.create") : t("general.save")}
                    />
                    <Button
                        data-test="cancel-button"
                        htmlType="button"
                        type="text"
                        onClick={onCancel}
                        label={
                            readOnly ? t("general.back") : t("general.cancel")
                        }
                    />
                </ButtonGroup>
            </Form>
        </ReadOnlyProvider>
    );
};
